import React, {
  type Dispatch,
  type SetStateAction,
  type ReactNode,
  type ComponentPropsWithoutRef,
  useEffect
} from 'react'
import { type CSS } from '@stitches/react'
import { styled, type ThemeProps } from './../../../theme/index'

export interface Props extends ThemeProps, ComponentPropsWithoutRef<any> {
  isShown: boolean
  card?: ReactNode
  handler: Dispatch<SetStateAction<boolean>>
  closeCss?: CSS
}

const Container = styled('div', {
  width: '100vw',
  height: '100vh',
  maxWidth: '100%',
  maxHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
  userEvents: 'none',
  position: 'fixed',
  zIndex: 20,
  left: 0,
  top: 0
})

const Shadow = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100vh',
  zIndex: 1,
  backgroundColor: 'rgba(0,0,0,0.5)'
})

const Card = styled('div', {
  zIndex: 3,
  width: '658px',
  minWidth: '280px',
  borderRadius: '4px',
  position: 'relative',
  boxShadow: '0 3px 9px rgba(0, 0, 0, 0.5)',
  overflow: 'hidden'
})

const Content = styled('div', {
  overflow: 'auto',
  maxHeight: '80vh'
})

const Close = styled('span', {
  lineHeight: 1,
  fontSize: '23px',
  fontWeight: '$bold',
  position: 'absolute',
  right: '14px',
  top: '10px',
  color: '$black',
  opacity: 0.2,
  textShadow: '0 1px 0 $clear',
  cursor: 'pointer',
  transition: '0.2s ease opacity',
  '&:hover': {
    opacity: 0.5
  }
})

const PopUp: React.FC<Props> = ({
  closeCss,
  isShown = true,
  handler,
  card,
  children,
  ...props
}) => {
  const closeHandler = (): void => {
    handler(!isShown)
  }

  useEffect(() => {
    const isStory = document.querySelector('.innerZoomElementWrapper') as HTMLDivElement
    if (isStory !== null) {
      isStory.style.overflow = isShown ? 'hidden' : 'auto'
    } else {
      document.body.style.overflow = isShown ? 'hidden' : 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isShown])

  if (!isShown) return null

  return (
    <Container {...props}>
      <Shadow onClick={closeHandler} />
      <Card>
        <Close css={closeCss} aria-hidden="true" onClick={closeHandler}>
          ×
        </Close>
        <Content>{card !== undefined ? card : children}</Content>
      </Card>
    </Container>
  )
}

export default PopUp
