// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'

const Icon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="2"
        d="M9.517 13.917a6.6 6.6 0 0 1-7.874-9.163l4.802 4.802 3.111-3.111-4.802-4.802a6.6 6.6 0 0 1 8.862 8.674l7.828 7.827a2.2 2.2 0 0 1 0 3.112l-.738.738a2.2 2.2 0 0 1-3.112 0l-8.077-8.077Z"
      />
    </svg>
  )
}

export default Icon
