// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useRef, createRef, useState} from 'react';
import { styled, type ThemeProps } from './../../../theme/index'
import { type Item as ItemProps } from './../../../theme/dashboardLinks'
import {Link as SimpleLink} from "react-router-dom";
import Link from './../../atoms/Link/Link'
import PopupReferral from './../../molecules/PopupReferral/PopupReferral'
import { usePopup } from './../../../state/PopupProvider'

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  items: ItemProps[]
  show?: boolean
}

const Container = styled('div', {
  borderBottom: '1px solid $gray4',
  backgroundColor: '$clear4',
  padding: '14px 12px',
  textAlign: 'right',
  width: '100%',
  display: 'none',
  '@bp3': {
    display: 'block'
  }
})

const Wrapper = styled('ul', {
  display: 'inline-flex',
  margin: 0
})

const Item = styled('li', {
  display: 'inline-flex',
  position: 'relative',
  listStyle: 'none',
  padding: '11px 13.5px 9px',
  margin: 0,
  '&.active': {
    background: '$gray2',
    ul: {
      display: 'block'
    }
  }
})

const Caret = styled('span', {
  display: 'inline-block',
  width: 0,
  height: 0,
  marginLeft: '4px',
  marginTop: '3px',
  verticalAlign: 'middle',
  borderTop: '6px dashed',
  borderRight: '6px solid transparent',
  borderLeft: '6px solid transparent',
  color: '#595F7B'
})

const SubMenuWrapper = styled('ul', {
  position: 'absolute',
  background: '$gray2',
  display: 'none',
  width: '100%',
  top: '100%',
  zIndex: 1,
  left: 0,
  margin: 0,
  padding: 0
})

const SubMenuItem = styled('li', {
  listStyle: 'none',
  margin: 0,
  textAlign: 'left',
  a: {
    padding: '10px 15px',
    position: 'relative',
    listStyle: 'none',
    margin: 0,
    display: 'block',
    width: '100%',
    '&:hover': {
      background: '$clear3'
    }
  }
})

const DashboardNavigation: React.FC<Props> = ({ items=[], ...props }) => {
  const [menuItems] = useState([
    {
      "label": "NOTIFICATIONS",
      "src": "https://app.getbeamer.com/brandcycle",
      "target": "_blank"
    },
    {
      "label": "REFER A FRIEND",
      "src": "#"
    },
    {
      "label": "SUPPORT",
      "src": "mailto:support@brandcycle.com"
    },
    {
      "label": "WELCOME ",
      "items": [
        {
          "label": "My Account",
          "src": "/my_account"
        },
        {
          "label": "Users",
          "src": "/subusers"
        },
        {
          "label": "Logout",
          "src": "/logout"
        }
      ]
    }
  ]);

  const itemsRef = useRef(menuItems.map(() => createRef<HTMLLIElement>()));

  const { dispatch: dispatchPopUp } = usePopup();

  const handleOpen = (index): void => {
    const current = itemsRef.current[index].current
    current?.classList.toggle('active')
  }

  return (
    <>
      <PopupReferral />
      <Container {...props}>
        <Wrapper>
          {items.map((item, index) => {
            const isParent: boolean = item.items !== undefined

            return (
              <Item
                ref={isParent ? itemsRef.current[index] : undefined}
                key={`dashboard-navigation-link-${index}`}
                onClick={
                  isParent
                    ? (): void => {
                        handleOpen(index)
                      }
                    : (): void => {
                        if (item.label === 'REFER A FRIEND') {
                          dispatchPopUp({
                            type: 'TOGGLE_POPUP'
                          })
                        }
                      }
                }
              >
                { isParent ?
                  <Link href={!isParent ? item.src : undefined} type="dashboard">
                    { item.label }
                  </Link>
                  :
                  <SimpleLink to={item.src} style={{textDecoration:"none"}} target={item.label==="NOTIFICATIONS" ? "_blank" : ""} >
                    <Link type="dashboard">
                      { item.label }
                    </Link>
                  </SimpleLink>
                }

                {isParent && <Caret />}

                <SubMenuWrapper>
                  {item?.items != null
                    ? item?.items.map((item, index) => {
                        return (
                          <SubMenuItem key={`dashboard-navigation-submenu-link-${index}`}>
                            <SimpleLink to={isParent ? item.src : undefined} style={{textDecoration:"none"}}>
                              <Link href={isParent ? item.src : undefined} type="dashboard">
                                 {item.label}
                              </Link>
                            </SimpleLink>
                          </SubMenuItem>
                        )
                      })
                    : ''}
                </SubMenuWrapper>
              </Item>
            )
          })}
        </Wrapper>
      </Container>
    </>
  )
}

export default DashboardNavigation
