import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {}

const Container = styled('div', {
  display: 'flex',
  borderBottom: '1px solid $lightgray1',
  backgroundColor: '$gray1',
  fontSize: '$size18',
  lineHeight: 1.125,
  color: '$darkgrey',
  fontWeight: '$regular',
  fontFamily: '$heading',
  padding: '15px 20px',
  height: 'auto',
  textTransform: 'uppercase'
})

const TitleSection: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Container {...props}>
      <span>{children}</span>
    </Container>
  )
}
export default TitleSection
