// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState } from 'react'
import {styled} from "./../../../theme/index";
import Input from '../../atoms/Input/Input';
import { Button } from '../../atoms/Button/Button';
import Checkbox from "../../atoms/Checkbox/Checkbox";
import CopyDisclaimer from "../../atoms/CopyDisclaimer/CopyDisclaimer";
import useCopy from "../../../hook/useCopy/useCopy";

interface State {
  url: string
  id: string
  isShort: boolean
  enabledDeepLink?: boolean
}

export interface Props extends React.ComponentPropsWithoutRef<any> {
  handler?: (e: State) => void
  ready?: boolean
  noAction?: boolean
  urlEmpty?: boolean
  urlNotMatch?: boolean
  type?: 'linkGenerator' | 'deepLink'
  isBuildALink: boolean
}

const InputsSection = styled('div', {
  display: 'block',
  flex: 2,
  label: {
    marginTop: '21px'
  },
  input: {
    marginTop: '1px',
    maxWidth: '100%'
  },
  variants: {
    type: {
      linkGenerator: {
        label: {
          marginTop: '20px'
        },
        input: {
          marginTop: '2px',
          maxWidth: '100%'
        }
      },
      deepLink: {}
    }
  }
})

const ButtonWrapper = styled('div', {
  width: '100%',
  variants: {
    type: {
      linkGenerator: {
        '@bp5': {
          width: '52%'
        }
      },
      deepLink: {}
    }
  }
})

const DeepLinkSection = styled('div', {
  cursor: 'pointer',
  flex: 2,
  variants: {
    type: {
      linkGenerator: {
        marginLeft: 0,
        '@bp3': {
          marginLeft: '12px'
        },
        '@bp5': {
          marginLeft: '48px'
        }
      },
      deepLink: {}
    }
  }
})

const FailWrapper = styled('div', {
  padding: '15px',
  color: '#a94442',
  borderColor: '#ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  lineHeight: 1.428,
  fontWeight: '$bold',
  fontFamily: '$label'
})

const CheckboxOption = styled(Checkbox, {
  variants: {
    view: {
      linkGenerator: { padding: '24px 0 29px' },
      deepLink: {
        padding: '23px 12px 28px',
        label: {
          fontSize: '14px'
        }
      }
    }
  }
})

const LinkSection = styled('section', {
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  justifyContent: 'start',
  gridGap: '1rem'
})
const YourLink = styled('input', {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '12px',
  fontSize: '$size15',
  fontFamily: '$body',
  fontWeight: '$bold',
  lineHeight: 1.37,
  border: '1px solid $blue7',
  borderRadius: '1px',
  width: '100%',
  maxWidth: '100%',
  // maxWidth: '275px',
  minHeight: '44px',
  maxHeight: 'fit-content',
  margin: '10px 0',
  color: '#666',

})

const FormContent: React.FC<Props> = ({ handler, type = 'linkGenerator',
                                        error='', isShort=true, isBuildALink=false,
                                        enabledDeepLink, deeplinkUrl }):any => {
  const [linkGenForm, setLinkGenForm] = useState({
    url: '',
    id: '',
    isShort
  })
  const [copyLongLink, setCopyLongLink] = useState(false);
  const [copyShortLink, setCopyShortLink] = useState(false);
  const [copyAction] = useCopy();

  const handleChange = (field: string, value: string): void => {
    if (field==="isShort") {
      setLinkGenForm({ ...linkGenForm, ...{ isShort: linkGenForm.isShort!==true } })
    }
    else {
      setLinkGenForm({ ...linkGenForm, ...{ [field]: value } })
    }
  }

  let btnStyle = null;
  let errorStyle = null;
  if (isBuildALink) {
    btnStyle = "build-a-link-form";
    errorStyle = "errorStyle";
  }

  // // FS set events for clicking on copy
  // const setFSClick = (value):void => {
  //   if (value.indexOf("brandcycle.shop") !== -1) {
  //     void FullStory('trackEvent', {
  //       name: 'bookmarklet short-link copy'
  //     });
  //   } else if (value.indexOf("brandcycle.go2cloud.org") !== -1) {
  //     void FullStory('trackEvent', {
  //       name: 'bookmarklet full-link copy'
  //     });
  //   }
  // }

  return (
    <>
      {
        (enabledDeepLink==="1" || enabledDeepLink===true) &&
        <>
          <InputsSection type={type}>
            {
              error!=='' && (
                <FailWrapper className={errorStyle}>
                  <span>{error}</span>
                </FailWrapper>
              )
            }
            <Input label="Destination URL" type="url" className="value" view={type} optional={false} value={linkGenForm.url}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange('url', e.target.value)
                  }}
            />
            <Input label="Aff Tracking ID" type="text" className="value" view={type} optional={true}
                  labelSpacedVariant={type === 'linkGenerator'} value={linkGenForm.id}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange('id', e.target.value)
                  }}
            />
          </InputsSection>
          <DeepLinkSection type={type} className="links-section" >
            <CheckboxOption name="deepLinking" id="deepLinking" type="secondary" view={type} isAccept={linkGenForm.isShort}
                            callback={(value: string) => {handleChange('isShort', value)}} >
              Create Short URL
            </CheckboxOption>
            <ButtonWrapper type={type}>
              <Button
                type="linkGenerator"
                onClick={() => {
                  handler?.(linkGenForm)
                }}
                className={btnStyle}
              >
                CREATE DEEP LINK
              </Button>
            </ButtonWrapper>
          </DeepLinkSection>
        </>
      }
      {
        (enabledDeepLink==="0" || enabledDeepLink===false || enabledDeepLink==="") &&
        <InputsSection type={type}>
          {
            error!=='' && (
              <FailWrapper className={errorStyle}>
                <span>{error}</span>
              </FailWrapper>
            )
          }
          <h4>Unfortunately, this brand does not currently support deep linking.</h4>

          <LinkSection>
            <div style={{fontSize:"85%"}}>Build a Homepage Short Link</div><br/>
            <YourLink type="text" value={deeplinkUrl.shortLink} readOnly={true}></YourLink>
            <Button type="action"
                    onClick={() => {
                      copyAction(deeplinkUrl.shortLink);
                      setCopyLongLink(true);
                      // setFSClick(deeplinkUrl.shortLink);
                    }}
                    style={{marginTop: "1px", width:"50%"}}
            >
              COPY THIS LINK
              {copyLongLink && (
                <CopyDisclaimer
                  css={{
                    marginTop: '0.3rem'
                  }}
                >
                  Link Copied
                </CopyDisclaimer>
              )}
            </Button>
          </LinkSection>

          <LinkSection>
            <div style={{fontSize:"85%"}}>Build a Homepage Full Link</div><br/>
            <YourLink type="text" value={deeplinkUrl.longLink} readOnly={true}></YourLink>
            <Button type="action"
                    onClick={() => {
                      copyAction(deeplinkUrl.longLink);
                      setCopyShortLink(true);
                      // setFSClick(deeplinkUrl.longLink);
                    }}
                    style={{marginTop: "1px", width:"50%"}}
            >
              COPY THIS LINK
              {copyShortLink && (
                <CopyDisclaimer
                  css={{
                    marginTop: '0.3rem'
                  }}
                >
                  Link Copied
                </CopyDisclaimer>
              )}
            </Button>
          </LinkSection>
        </InputsSection>
      }
    </>
  );
}

export default FormContent;