// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { styled, type ThemeProps } from './../../../theme/index'
import {UserContext} from "../../../context/UserContext";
import TrackingLinkForm from './../../molecules/TrackingLinkForm/TrackingLinkForm'
import BrandHeader from './../../organisms/BrandHeader/BrandHeader'
import client from "../../api";
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import ControlledCarousel from "../../ControlledCarousel";
import ErrorMessage from "../../ErrorMessage";
import getParentDetails from "../../../hook/getParentDetails";

interface Props extends ThemeProps {
  brandOptions?: GridProps[]

  pagination?: {
    current: number
    total: number
    pageSize: number
  }
}

const Container = styled('div', {
  padding: 0,
  width: '100%',
  maxWidth: '100%'
})

const BrandsDetailsBMK: React.FC<Props> = ({ ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [label, setLabel] = useState("");
  const [info, setInfo] = useState({});
  const {id} = useParams();
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [urlsLoading, setURLsLoading] = useState(false);
  const [parentURL, setParentURL] = useState("");
  const [imagesList, setImagesList] = useState([]);
  const [longUrl, setLongUrl] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const [enableDeepLink, setEnableDeepLink] = useState(false);
  const navigate = useNavigate();

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    setDetailsLoading(true);

    setTimeout(function() {
      // fetchFrameDetails();
      getParentDetails(parentURL, setParentURL, setImagesList);
    }, 2000)
// console.log("parentURL: ", parentURL);

    if (password.length>2 && parentURL!=="") {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get(`/api/brands/${id}/get/details?_format=json`, {
        headers: { csrf_token },
        auth: { username, password }
      })
        .then(({ data }) => {
          setLabel(data.label);
          setInfo(data.info);

          serverAPICall();

          setDetailsLoading(false);
        })
        .catch(({ response }) => {
          // console.log("BMK brand error: ", response);
          if (response.status===403) {
            localStorage.setItem("isBookmarklet", "/request-a-brand/bmk");
            localStorage.setItem("reason", response.data)
            navigate("/request-a-brand/bmk");
          }
          else if (response.status===500) {
            navigate("/request-a-brand/bmk");
          }
          setErrorMessages(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password, parentURL]);

  const serverAPICall = (inputAffTrackingID=""):void => {
    setURLsLoading(true);

    if (parentURL==="") {
      setErrorMessages(true);
      setURLsLoading(false);
    }

    void client.post(`/api/bmk/get/links/${id}?_format=json`, {
      targetUrl: parentURL,
      affTracking: inputAffTrackingID
    },{
      headers: { "Content-type": "application/x-www-form-urlencoded", csrf_token },
      auth: {username, password}
    })
      .then(({data}) => {
        setLongUrl(data.longUrl);
        setShortUrl(data.shortUrl);
        setEnableDeepLink(data.enabledDeepLinking)

        setURLsLoading(false);
      })
      .catch(({ response }) => {
        console.log("server sent an error: ", response) // TODO: if brand is inactive, need to update the response from the server & here
        setErrorMessages(true);
      });
  }

  const handleImageClick = (boolState):void => {
    setURLsLoading(boolState);
  }

  return (
    <Container {...props}>

      {detailsLoading && <LoadingOverlay style={{position: "absolute", zIndex: "100"}} />}
      { errorMessages && <ErrorMessage contactSupport={true}  valueOf={null}/> }

      <BrandHeader label={label} info={info} bmkFlag={true} />

      {urlsLoading && <LoadingOverlay style={{position: "absolute", zIndex: "100"}} />}
      <TrackingLinkForm brandId={id} bmkFlag={true} parentURL={parentURL} shortUrl={shortUrl} longUrl={longUrl}
                        enabledDeepLink={enableDeepLink} updateAffId={serverAPICall} />

      {
        imagesList.length>0 && <ControlledCarousel imagesList={imagesList} shortUrl={shortUrl} longUrl={longUrl} showLoad={handleImageClick} />
      }
    </Container>
  )
}

export default BrandsDetailsBMK;
