import React from 'react'
import { styled } from './../../../theme/index'
import SocialItems from './../../molecules/SocialItems/SocialItems'

const Container = styled('div', {
  paddingBottom: 0,
  paddingTop: 0,
  textAlign: 'center'
})

const Title = styled('h2', {
  padding: '25px 0 7px',
  color: '$black2',
  fontFamily: '$heading',
  fontSize: '19px',
  fontWeight: '$bold',
  letterSpacing: '1px',
  lineHeight: 1,
  textAlign: 'center',
  borderBottom: 'none',
  '@bp3': {
    padding: '7px 0'
  }
})

const iconStyle = {
  justifyContent: 'space-around',
  margin: '0 auto',
  width: '90%',
  a: {
    fontSize: '26px',
    color: '$blue4',
  }
}

const SocialSection: React.FC = () => {
  return (
    <Container>
      <Title>FOLLOW US ON SOCIAL</Title>
      <SocialItems type="admin" fontAwesomeIcon={true} css={iconStyle} />
    </Container>
  )
}

export default SocialSection
