// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useRef, useContext, useState} from 'react';
import { UserContext } from '../../../context/UserContext';
import { styled, type ThemeProps } from './../../../theme/index'

import Link from './../../atoms/Link/Link'
import Text from './../../atoms/Text/Text'
import Input from './../../atoms/Input/Input'
import { Button } from '../../atoms/Button/Button'
import ReCAPTCHA from 'react-google-recaptcha';
import client from "../../api";

interface Data {
  password: string
  username: string
}

interface Props extends ThemeProps {
  callback?: (userData: Data) => void
}

const Container = styled('div')

const Cta = styled('div', {
  margin: '0 auto',
  textAlign: 'center',
  letterSpacing: '-0.1px',
  '@bp2': {
    fontSize: '$size17'
  }
})

const Title = styled(Text, {
  color: '$darkgray',
  margin: '0 auto',
  fontSize: '24px',
  fontWeight: '$bold',
  textAlign: 'center',
  letterSpacing: '.5px',
  fontFamily: '$heading',
  textTransform: 'uppercase',
  paddingTop: '5px'
})

const Disclaimer = styled(Text, {
  maxWidth: '440px',
  margin: '0 auto',
  fontWeight: '$bold',
  textAlign: 'center',
  letterSpacing: '-0.1px',
  lineHeight: '1.52',
  fontFamily: '$body',
  padding: '10px 0',
  fontSize: '16px',
  '@bp2': {
    fontSize: '17px'
  }
})

const Card = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '710px',
  padding: '25px 25px 50px',
  boxShadow: '0 1px 4px 0 rgb(0 0 0 / 50%)',
  margin: '0 auto 28px'
})

const Body = styled('div', {
  width: '100%',
  maxWidth: '475px',
  margin: '0 auto'
})

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16'
})
const Success = styled('div', {
  fontFamily: '$label',
  color: '#325e1c',
  border: '1px solid #ebccd1',
  borderColor: '#c9e1bd',
  backgroundColor: '#f3faef',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16'
});

const LinkResource = styled(Link, {
  marginRight: '8px',
  fontSize: '$size14',
  color: '#4b516c',
  textTransform: 'none',
  width: 'max-content',
  '@bp2': {
    fontSize: '$size17'
  }
})

const errors = {
  login:
    'Log in to access the latest campaigns and opportunities and track your affiliate activity.',
  recover: 'Username or e-mail address field is required.'
}

const styleLink = {
  a: {
    textDecoration: 'none',
    '&:hover': {
      color: '#275153',
      textDecoration: 'underline'
    },
    '@bp2': {
      fontSize: '$size17',
      letterSpacing: '-0.1px'
    }
  }
}

const ENV = process.env.REACT_APP_ENV;
let recaptchaKey=null;
if (ENV==="production") {
  recaptchaKey = process.env.REACT_APP_PROD_GOOGLE_RECAPTCHA;
}
else if (ENV==="stage") {
  recaptchaKey = process.env.REACT_APP_STAGE_GOOGLE_RECAPTCHA;
}
else {
  recaptchaKey = process.env.REACT_APP_LOCAL_GOOGLE_RECAPTCHA;
}

const LoginForm: React.FC<Props> = ({ callback, ...props }) => {
  // updating the User Context
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { userCredentials, updateUserCredentials } = useContext(UserContext);

  // const navigate = useNavigate();
  const [view, setView] = useState('login');
  const [errorMessages, setErrorMessages] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const inputUser = useRef(null);
  const inputPass = useRef(null);
  const captchaRef = useRef(null);
  const forgotPwdEMail = useRef(null);

  // useEffect(() => {
  //   void client.get(`/user/isLogin?_format=json`)
  //     .then(({ data }) => {
  //       console.log("/user/isLogin?", data);
  //       if (data===true) { // user is already logged in the system
  //         setErrorMessages("User already logged in the system. Please re-log with the same credentials.")
  //       }
  //       // else { // user was not logged in
  //       // }
  //     }) // end client.post(`/user/isLogin`, { name, pass }
  //     .catch(e => {
  //       console.log("Error: ", e);
  //       // if (e.response.status===400) {
  //       //   setErrorMessages(e.response.data.message);
  //       // }
  //     })
  // }, []);

  const handleRecoverSubmit = (event: { preventDefault: () => void; target: any[] }): void => {
    event.preventDefault()
    const user = event.target[0]
    if (user.value === '') {
      setErrorMessages(errors.recover)
    } else {
      setErrorMessages('')
      setView('login')
    }
  }
  const handleLoginSubmit = async (event: { preventDefault: () => void; target: any[] }): void => {
    event.preventDefault();

    const token = captchaRef.current.getValue();
    if (token==="") {
      setErrorMessages("Please check the reCAPTCHA challenge.");
    }
    else {
      // preflight login - check if user is already login
      const name = inputUser.current.value.trim();
      const pass = inputPass.current.value.trim();

      // get token from site
      void client.get(`/session/token`, {mode: 'cors'})
        .then(res => {
          const token = res.data;

          // attempt to login
          void client.post(`/user/login?_format=json`, {name, pass}, {
            withCredentials: false,
            headers: {'Content-Type': 'application/json', 'X-Csrf-Token': token}
          })
            .then(({ data }) => {
              if (data!==undefined) {
                if (data.message!==undefined) {
                  setErrorMessages(String(data.message));
                }
                else if (data.current_user.role!==undefined) {
                  processUserData(name, pass, data);
                }
              }
            }).catch(e => {
              if (e.code==="ERR_NETWORK") {
                setErrorMessages("Sorry, unrecognized credentials.");
              }
              else if (e.response?.data.message!==undefined) {
                setErrorMessages(String(e.response.data.message));

              }
              else {
                setErrorMessages("An error occurred, please contact support at support@brandcycle.com");
              }
            })
        }).catch(e => { // token problem
          if (e.response.status===400) {
            setErrorMessages(String(e.response.data.message));
          }
          else {
            setErrorMessages("An error occurred, please contact support at support@brandcycle.com");
          }
        })
    }
  }

  const processUserData = (name, pass, data):void => {
    const newCredentials = {
      password: pass,
      username: !validateEmail(name) ? name : data.current_user.name,
      csrf_token: data.csrf_token,
      logout_token: data.logout_token,
      user: data.current_user
    };

    updateUserCredentials(newCredentials);
    window.localStorage.setItem("user_credit", JSON.stringify(newCredentials));

    // setting 'level' to storage
    window.localStorage.setItem("level", data.current_user.role);

    if (localStorage.getItem("isBookmarklet")!==null) {
      window.location.href = localStorage.getItem("isBookmarklet");
    }
    else if ( (newCredentials.user.terms===1 && newCredentials.user.privacy===1) || data.current_user.role==="admin" ) {
      window.location.href = '/';
    }
    else if (newCredentials.user.terms===0) { // user did not accept term&conditions
      window.location.href = "/terms-and-conditions";
    }
    else { // user did not accept privacy policy
      window.location.href = "/privacy-policy";
    }
  }
  const validateEmail = (email):boolean => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleView = (e: { preventDefault: () => void }): void => {
    e.preventDefault()
    setView(view === 'login' ? 'recover' : 'login')
    setErrorMessages('')
  }

  const handleForgotPwd = ():void => {
    const forgotEMail = forgotPwdEMail.current.value;

    void client.post("/api/user/forgotpswd", {
      forgotEMail
    }, {
        headers: { "Content-type": "application/x-www-form-urlencoded" },
    })
    .then(() => {
      setSuccessMessage(`If ${forgotEMail} is a valid account, an email will be sent with instructions to reset your password.`);
      setTimeout(function() {
        setSuccessMessage('');
      }, 5000);
    })
    .catch(e => {
      // console.log("error", e);
      setErrorMessages(e.response.data);
    });
  }

  return (
    <>
      {view === 'login' && (
        <Container onSubmit={handleLoginSubmit} {...props}>
          <Card>
            <Body className="form-body">
              <Title>WELCOME BACK</Title>
              <Disclaimer>
                Log in to access the latest campaigns and opportunities and track your affiliate
                activity.
              </Disclaimer>

              {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}
              {successMessage !== '' ? <Success>{successMessage}</Success> : ''}

              <div style={{ padding: '39px 0 20px' }}>
                <Input
                  label="Username or Email address"
                  required
                  asterisk="red"
                  name="user-input"
                  type="text"
                  blockInput={true}
                  view="inputForm"
                  inputArgs={{
                    autoComplete: 'username',
                    id: 'login_username'
                  }}
                  css={{ paddingBottom: '33px', '& > span': { color: '#3c3c3c' } }}
                  inputRef={inputUser}
                />
                <Input
                  label="Password"
                  required
                  asterisk="red"
                  name="password-input"
                  inputArgs={{
                    autoComplete: 'current-password'
                  }}
                  resourceLink={
                    <LinkResource onClick={handleView} type={'fourth'}>
                      Reset password here
                    </LinkResource>
                  }
                  type="password"
                  blockInput={true}
                  view="inputForm"
                  css={{
                    '&>span': {
                      marginBottom: '8px',
                      color: '#3c3c3c'
                    }
                  }}
                  inputRef={inputPass}
                />
              </div>

              <div className='formGroup'>
                <ReCAPTCHA sitekey={recaptchaKey} ref={captchaRef}  />
              </div>

              <div
                style={{
                  padding: '16px 0 10px',
                  marginTop: '10px'
                }}
              >
                <Button type="login">SIGN IN</Button>
              </div>
            </Body>
          </Card>
          <Cta css={styleLink}>
            Don&apos;t have a BrandCycle account?{' '}
            <Link href="https://www.brandcycle.com/apply-now/" type={'fourth'} css={{ display: 'inline-block' }}>
              Apply for one now
            </Link>
          </Cta>
        </Container>
      )}

      {view === 'recover' && (
        <Container onSubmit={handleRecoverSubmit} {...props}>
          <Card>
            <Body className="form-body">
              <Title>FORGOT YOUR PASSWORD?</Title>
              <Disclaimer className="disclaimer" css={{ paddingBottom: '48px' }}>
                We&apos;ll send you a link to reset your password.
              </Disclaimer>
              {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}
              <Input
                label="Username or Email Address"
                name="user-input"
                type="text"
                blockInput={true}
                view="inputForm"
                required
                css={{ letterSpacing: '-0.2px' }}
                inputRef={forgotPwdEMail}
              />
              <div
                style={{
                  padding: '44px 0 10px'
                }}
              >
                <Button type="login" onClick={handleForgotPwd} >SEND</Button>
              </div>
            </Body>
          </Card>
          <Text className="view-cta" css={{ textAlign: 'center' }}>
            <Link onClick={handleView} type={'fourth'} css={{ textDecoration: 'underline', display: 'inline-block' }}>
              Back to login
            </Link>
          </Text>
        </Container>
      )}
    </>
  )
}

export default LoginForm
