import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'

interface Props extends ThemeProps {
  column?: boolean
}

const CheckIcon = styled('div', {
  display: 'flex',
  backgroundColor: '$green1',
  borderRadius: '50px',
  top: '-1px',
  width: '13px',
  height: '13px',
  position: 'relative',
  marginRight: '6px',
  '&:after': {
    position: 'absolute',
    content: '',
    left: '4px',
    top: '2px',
    width: '5px',
    height: '7px',
    border: 'solid',
    borderColor: '$clear',
    borderWidth: '0 2px 2px 0',
    transform: 'rotate(48deg)'
  }
})

const Container = styled('div', {
  display: 'flex',
  fontSize: '$size15',
  color: '$black',
  letterSpacing: '-0.2px',
  fontWeight: '600',
  fontFamily: '$body',
  lineHeight: 1
})

const CopyDisclaimer: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Container {...props}>
      <CheckIcon />
      {children}
    </Container>
  )
}
export default CopyDisclaimer
