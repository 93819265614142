import React from 'react'
import { styled } from './../../../theme/index'
import { SubUsersAddForm } from './../../molecules/SubUsersAddForm/SubUsersAddForm'
// import { type Option as SelectProps } from './../../atoms/Select/Select'

// export interface Props {
//   optionsSelect?: SelectProps[]
// }

const Container = styled('div')

// const initRoles = [
//   {
//     "value": "link_creator",
//     "label": "Link Creator"
//   },
//   {
//     "value": "analyst",
//     "label": "Analyst"
//   }
// ];
const SubUsersAdd: React.FC = ({ ...props }) => {
  return (
    <>
      <Container {...props}>
        <SubUsersAddForm />
      </Container>
    </>
  )
}

export default SubUsersAdd
