// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { styled } from './../../../theme/index'
import PostContent from './../../atoms/PostContent/PostContent'
import BlogList from './../../molecules/BlogList/BlogList'
import { type Props as BlogSnippetProps } from './../../molecules/BlogSnippet/BlogSnippet'
import {UserContext} from "../../../context/UserContext";
import client from "../../api";
import moment from 'moment';
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import ErrorMessage from "../../ErrorMessage";

interface Props {
  content?: string
  postList?: BlogSnippetProps[]
  postTitle?: string
  postDate?: string
  category?: string
}

const Container = styled('div')

const SingleTitle = styled('h1', {
  fontFamily: '$quote',
  fontSize: '24px',
  marginTop: 0,
  marginBottom: '10px',
  fontWeight: '400',
  lineHeight: 1.33,
  color: '$darkgray',
  letterSpacing: '1px'
})

const PostInfo = styled('p', {
  fontSize: '15px',
  fontFamily: '$body',
  lineHeight: 1.56,
  margin: '0 0 12px'
})

const Bold = styled('span', {
  fontWeight: '700'
})

const Wrapper = styled('div', {
  marginTop: '76px'
})

const PaneTitle = styled('h2', {
  fontFamily: '$heading',
  fontSize: '18px',
  fontWeight: '$bold',
  letterSpacing: '1px',
  lineHeight: 1,
  color: '$black2',
  margin: '11px 0 18px',
  padding: '25px 0 17px',
  textAlign: 'center'
})

const BlogSingle: React.FC<Props> = ({ ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [postTitle, setPostTitle] = useState("");
  const [postDate, setPostDate] = useState(null);
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [postList, setPostList] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
console.log("load post #: ", id);
  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
console.log("useEffect: ", id);
    if (password.length>2 && username.length>2 && csrf_token.length>2) {
      setLoading(true);
      console.log("loading id: ", id)
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get(`/api/blog/${id}/get?_format=json`, {
        headers: { csrf_token },
        auth: { username, password }
      })
          .then((response) => {
            setPostTitle(response.data.postTitle);
            setPostDate(response.data.postDate);
            setCategory(response.data.category);
            setContent(response.data.content);
            setPostList(response.data.postList);

            setLoading(false);
          })
          .catch(e => {
            console.log("error", e);
            setErrorMessages(true);
            setLoading(false);
          });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, id]);

  return (
    <Container {...props}>

      {loading && <LoadingOverlay style={{position: "absolute", zIndex: "100"}} />}
      { errorMessages && <ErrorMessage contactSupport={true} /> }

      {!loading &&
        <>
          <SingleTitle>{postTitle}</SingleTitle>

          <PostInfo>
            Posted <Bold>{moment(postDate, 'X').format('MMMM, Do, YYYY')}</Bold> In <Bold>{category}</Bold>
          </PostInfo>

          <PostContent content={content} />

          <Wrapper>
            <PaneTitle>RECENT BLOGS</PaneTitle>
            <BlogList collection={postList} />
          </Wrapper>
        </>
      }

    </Container>
  )
}

export default BlogSingle
