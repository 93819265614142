// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useEffect, useState, useContext} from 'react';
import {styled, type ThemeProps} from './../../../theme/index'
import DateForm from './../../molecules/DateForm/DateForm'
import DataTables from './../../atoms/DataTables/DataTables'
import GraphHeader, {type HeaderValues} from './../../atoms/GraphHeader/GraphHeader'
import Graph from './../../atoms/Graph/Graph'
import GraphBottom from './../../atoms/GraphBottom/GraphBottom'
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import {Title as TitleStyles, Border, Container} from './styles'
import client from "../../api";
import {UserContext} from "../../../context/UserContext";
import moment from 'moment';
import ErrorMessage from "../../ErrorMessage";
import getSubUsersCall from "../../../hook/getSubUsersCall";

interface Props extends ThemeProps {
  data?: object[]
}

const Title = styled(TitleStyles, {
  paddingTop: '13px'
})

const ConversionContent: React.FC<Props> = ({...props}) => {
  const {userCredentials} = useContext(UserContext);
  const role = window.localStorage.getItem("level")!==undefined ? window.localStorage.getItem("level") : "affiliate";
  const [loading, setLoading] = useState(true)
  const [errorMessages, setErrorMessages] = useState('');
  // filter data
  const [dateFilter, setDateFilter] = useState("7-days-past");
  const [fromDate, setFromDate] = useState(moment().subtract(6, 'd').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [resolution, setResolution] = useState("day");
  const [affiliates, setAffiliates] = useState([]);
  const [selectedUser, setSelectedUser] = useState('All');

  // top graph data
  const [sales, setSales] = useState(0);
  const [payouts, setPayouts] = useState(0);
  const [orders, setOrders] = useState(0);
  const [lockedSales, setLockedSales] = useState(0);
  const [lockedPayouts, setLockedPayouts] = useState(0);
  const [lockedOrders, setLockedOrders] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const headerObj: HeaderValues = {
    sales: Number(sales),
    payouts: Number(payouts),
    orders: Math.floor(orders),
    lockedSales: Number(lockedSales),
    lockedPayouts: Number(lockedPayouts),
    lockedOrders: Number(lockedOrders)
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {password, username, csrf_token} = userCredentials;
  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      setLoading(true);

      if (role!=="account_manager" && role!=="admin") {
        // getSubUsersList();
        getSubUsersCall(password, username, csrf_token, setAffiliates, setErrorMessages);
      }
      else{
        getAllUsersList();
      }

      callApi(dateFilter, fromDate, toDate, resolution, selectedUser, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  const getAllUsersList = ():void => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      void client.get("/api/users/get?_format=json", {
        headers: {"Content-type": "application/x-www-form-urlencoded", csrf_token},
        auth: {username, password}
      })
        .then(({ data }) => {
          if (data.data?.length > 0) {
            const tempSubUsersList = data.data.map((item) => {
              return {value: item, label: item}
            });

            setAffiliates([{value: "All", label: "ALL"}, ...tempSubUsersList]);
          }
        })
        .catch(e => {
          setErrorMessages("Failed to get the Users list.");
          console.log("Error summary report, getting the report", e);
        });
    }
  }

  const handleFilterChange = (newValues): void => {
    setLoading(true);
    setErrorMessages("");

    setDateFilter(newValues.range.value === "free" ? "free" : newValues.range.value);
    setFromDate(moment(newValues.startDate).format('YYYY-MM-DD'));
    setToDate(moment(newValues.endDate).format('YYYY-MM-DD'));
    setResolution(newValues.filter.value);
    setSales(newValues.status.value);
    setPayouts(newValues.paidStatus.value);

    let users = 'All';
    if (newValues.affiliates!==undefined) {
      users = newValues.affiliates.label;
    }
    setSelectedUser(users);

    callApi(
      newValues.range.value,
      moment(newValues.startDate).format('YYYY-MM-DD'),
      moment(newValues.endDate).format('YYYY-MM-DD'),
      newValues.filter.value,
      users,
      false,
      newValues.offers.value.replaceAll(" ", ""),
      newValues.status.value.replaceAll(" ", ""),
      newValues.paidStatus.value
    );
  }

  const callApi = (dateFilter, fromDate, toDate, resolution, users,
                   exportType=false,
                   offersFilter="all",
                   statusFilter="all",
                   paidStatusFilter="all"):void => {
    setErrorMessages("");

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    void client.post("/api/reports?_format=json", {
      type: "conversion_report",
      dateFilter,
      fromDate,
      toDate,
      resolution,
      users: users!=="" ? users : "All",
      offers: offersFilter,
      status: statusFilter,
      paidStatus: paidStatusFilter,
      export_type: exportType ? "file" : ""
    }, {
      headers: {"Content-type": "application/x-www-form-urlencoded", csrf_token},
      auth: {username, password}
    })
      .then(({ data }) => {
        if (exportType) {
          // 2. download the file
          const element = document.createElement("a");
          const file = new Blob([data.data], {type: 'text/plain'});
          element.href = URL.createObjectURL(file);
          element.download = data.fileName;
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
        }
        else {
          setStates(data);
        }

        setLoading(false);
      })
      .catch(e => {
        // console.log("Error summary report, getting the report", e);
        if (e.response.data.message!==undefined) {
          setErrorMessages(e.data.message);
        }
        else {
          setErrorMessages("Something happen while getting report data.");
        }

        setLoading(false);
      });
  }

  const setStates = (data):void => {
    setSales(data.totals.sales ?? 0);
    setPayouts(data.totals.payouts ?? 0);
    setOrders(data.totals.orders ?? 0);
    setLockedSales(data.totals.locked_sales ?? 0);
    setLockedPayouts(data.totals.locked_payouts ?? 0);
    setLockedOrders(data.totals.locked_orders ?? 0);

    // if (data.graphData.length>0) {
      // const processedData = (data.graphData.map((item) => {
      //   return {
      //     name: item.date.indexOf(" - ")>-1 ? item.date.split(" - ").pop() : item.date, // '2023-02-20',
      //     sales: item.sales,
      //     clicks: item.clicks,
      //     orders: item.orders,
      //     payouts: item.payouts
      //   };
      // }));
      // setGraphData(processedData);
      setGraphData(data.graphData);
    // }

    // if (data.tableData.length>0) {
      setTableData(data.tableData);
    // }
  }

  const handleExportFile = ():void => {
    setLoading(true);

    callApi(
      dateFilter, fromDate, toDate, resolution, selectedUser, true
    );
  }

  return (
    <Container {...props}>

      {loading && <LoadingOverlay/>}

      <Title>Conversion Report</Title>

      {errorMessages !== '' ? <ErrorMessage>{errorMessages}</ErrorMessage> : ''}

      <DateForm type="conversion" affiliates={affiliates} callback={handleFilterChange} exportFile={handleExportFile} />
      <Border>
        <GraphHeader data={headerObj}/>
        <Graph type="conversion" data={graphData}/>
        <GraphBottom options={['sales', 'clicks', 'orders', 'payouts']}/>
        <DataTables variant="conversion" data={tableData} />
      </Border>
    </Container>
  )
}

export default ConversionContent
