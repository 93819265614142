// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useState, useEffect, useContext} from 'react'
import {type ThemeProps} from './../../../theme/index'
import DataTables from './../../atoms/DataTables/DataTables'
import GraphHeader, {type HeaderValues} from './../../atoms/GraphHeader/GraphHeader'
import Graph from './../../atoms/Graph/Graph'
import GraphBottom from './../../atoms/GraphBottom/GraphBottom'
import LoadingOverlay from './../../atoms/LoadingOverlay/LoadingOverlay'
import DateForm from './../../molecules/DateForm/DateForm'
import {Title, Border, Container} from './styles'
import {UserContext} from "../../../context/UserContext";
import client from "../../api";
import moment from 'moment';
import ErrorMessage from "../../ErrorMessage";
import getSubUsersCall from "../../../hook/getSubUsersCall";

interface Props extends ThemeProps {
  data?: object[]
}

const SummaryContent: React.FC<Props> = ({...props}) => {
  const {userCredentials} = useContext(UserContext);
  const role = window.localStorage.getItem("level")!==undefined ? window.localStorage.getItem("level") : "affiliate";
  const [loading, setLoading] = useState(false);
  const [ variant, setVariant ] = useState('summary1');
  const [errorMessages, setErrorMessages] = useState('');
  // filter data
  const [dateFilter, setDateFilter] = useState("7-days-past");
  const [fromDate, setFromDate] = useState(moment().subtract(6, 'd').format('YYYY-MM-DD HH:mm:ss'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss')); // 07/05/2023
  const [resolution, setResolution] = useState("day");
  const [affiliates, setAffiliates] = useState([]);
  const [selectedUser, setSelectedUser] = useState('All');

  // top graph data
  const [clicks, setClicks] = useState(0);
  const [sales, setSales] = useState(0);
  const [payouts, setPayouts] = useState(0);
  const [orders, setOrders] = useState(0);
  const [lockedSales, setLockedSales] = useState(0);
  const [lockedPayouts, setLockedPayouts] = useState(0);
  const [lockedOrders, setLockedOrders] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const headerObj: HeaderValues = {
    clicks: Math.floor(clicks),
    sales,
    payouts,
    orders: Math.floor(orders),
    lockedSales,
    lockedPayouts,
    lockedOrders
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {password, username, csrf_token} = userCredentials;
  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      setLoading(true);

      getSubUsersCall(password, username, csrf_token, setAffiliates, setErrorMessages);
      callApi(dateFilter, fromDate, toDate, resolution, selectedUser, "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  const handleFilterChange = (newValues): void => {
    setLoading(true);

    setDateFilter(newValues.range.value === "free" ? "free" : newValues.range.value);
    setFromDate(moment(newValues.startDate).format('YYYY-MM-DD HH:mm:ss'));
    setToDate(moment(newValues.endDate).format('YYYY-MM-DD HH:mm:ss'));
    setResolution(newValues.filter.value);

    let users = 'All';
    if (newValues.affiliates!==undefined) {
      users = newValues.affiliates.label;
    }
    setSelectedUser(users);
    setVariant('summary'); // display all in graph header
    
    callApi(
      newValues.range.value,
      moment(newValues.startDate).format('YYYY-MM-DD'),
      moment(newValues.endDate).format('YYYY-MM-DD'),
      newValues.filter.value,
      users,
      ""
    );
  }

  const callApi = (dateFilter, fromDate, toDate, resolution, users, exportType):void => {
    setErrorMessages("");

    let newData = null;

    if (role!=="account_manager" && role!=="admin") {
      newData = {
        type: "summary_report",
        dateFilter,
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        resolution,
        users,
        export_type: exportType
      }
    }
    else {
      newData = {
        type: "summary_report",
        dateFilter,
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        resolution
      }
    }

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    void client.post("/api/reports?_format=json", newData, {
      headers: {"Content-type": "application/x-www-form-urlencoded", csrf_token},
      auth: {username, password}
    })
      .then(({ data }) => {
        if (exportType==="file") {
          // 2. download the file
          const element = document.createElement("a");
          const file = new Blob([data.data], {type: 'text/plain'});
          element.href = URL.createObjectURL(file);
          element.download = data.fileName;
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
        }
        else {
          setStates(data);
        }

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setErrorMessages("Failed to get data for the report.");
        console.log("Error summary report, getting the report", e);
      });
  }

  const setStates = (data):void => {
    setClicks(parseInt(data.totals.clicks) ?? 0);
    setSales(parseFloat(data.totals.sales) ?? 0);
    setPayouts(parseFloat(data.totals.payouts) ?? 0);
    setOrders(parseInt(data.totals.orders) ?? 0);
    setLockedSales(parseFloat(data.totals.locked_sales) ?? 0);
    setLockedPayouts(parseFloat(data.totals.locked_payouts) ?? 0);
    setLockedOrders(parseInt(data.totals.locked_orders) ?? 0);

    if (data.graphData.length>0) {
      const processedData = (data.graphData.map((item) => {
        return {
          name: item.date.indexOf(" - ")>-1 ? item.date.split(" - ").pop() : item.date, // '2023-02-20',
          sales: item.sales,
          clicks: item.clicks,
          orders: item.orders,
          payouts: item.payouts ?? item.payout ?? 0
        };
      }));
      setGraphData(processedData);
    }
    else {
      setGraphData(data.graphData);
    }

    // if (data.tableData.length>0) {
      setTableData(data.tableData);
    // }
  }

  const handleExportFile = ():void => {
    setLoading(true);

    callApi(
      dateFilter, fromDate, toDate, resolution, selectedUser, "file"
    );
  }

  return (
    <Container {...props}>

      {loading && <LoadingOverlay/>}

      <Title>Summary Report</Title>

      {errorMessages !== '' ? <ErrorMessage>{errorMessages}</ErrorMessage> : ''}

      <DateForm type="summary" affiliates={affiliates} callback={handleFilterChange} exportFile={handleExportFile} />
      <Border>
        <GraphHeader data={headerObj} variant={variant}/>
        <Graph data={graphData} resoution={resolution}/>
        <GraphBottom options={['sales', 'clicks', 'orders', 'payouts']}/>
        <DataTables variant="summary" data={tableData} />
      </Border>
    </Container>
  )
}

export default SummaryContent
