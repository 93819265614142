// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'
import { styled } from './../../../theme/index'
import { type VariantProps } from '../../../hook/useTableTotals/useTableTotals'
import { SECONDARY_BACKGROUND } from './../../atoms/DataTables/DataTables'

interface Props extends VariantProps {
  totals: Array<string | number | null>
}

const Tr = styled('tr', {
  overflowWrap: 'break-word',
  border: '1px solid $gray4',
  borderBottomWidth: '0',
  verticalAlign: 'top',
  textAlign: 'left',
  color: '$black',
  fontWeight: '$bold',
  lineHeight: '1',
  fontSize: '15px',
  variants: {
    type: {
      summary: {},
      conversion: {
        borderColor: '$black',
        borderWidth: '2px 1px 0'
      },
      brands: {},
      traffic: {},
      payment: {}
    }
  }
})

const Td = styled('td', {
  border: '1px solid $gray4',
  borderBottomWidth: '1px',
  fontFamily: '$heading',
  textAlign: 'left',
  color: '$black',
  lineHeight: '1',
  fontSize: '15px',
  margin: '0',
  padding: '0.5rem',
  variants: {
    type: {
      summary: {
        borderBottomColor: '$black',
        borderBottomWidth: '1px'
      },
      conversion: {
        borderColor: '$black',
        borderWidth: '2px 1px 2px'
      },
      brands: {
        borderBottomColor: '$black',
        borderBottomWidth: '1px'
      },
      traffic: {
        borderBottomColor: '$black',
        borderBottomWidth: '1px'
      },
      payment: {
        borderBottomColor: '$black',
        borderBottomWidth: '1px'
      }
    }
  }
})

const TotalsBar: React.FC<Props> = ({ totals, variant }) => {
  return (
    <thead>
      <Tr role="row" type={variant as any}>
        {totals.map((total, i) => {
          let output = total

          // use numbers instead of strings for any number type
          if (total !== undefined && typeof total === 'number') {
            output = Number(output); // .toLocaleString()

            // Add $ to float types (Optional)
            if ( (variant==="summary" || variant==="brands" || variant==="conversion") && i>=3) {
              output = output.toFixed(2);
              output = `$${output}`
            }
            else if (variant==="traffic" && i>=6) {
              output = output.toFixed(2);
              output = `$${output}`
            }
            else if (variant==="paymentBalance") {
              output = `$${output}`
            }
            else if (variant==="payment" && i>=2) {
              output = output.toFixed(2);
              output = `$${output}`
            }
            else if (typeof output==="string"){
              output = `${output}`
            }
            // else if (Number.isInteger(output) || i>=3) {
            //   output = `$${output}`
            // }
          }

          const noRightBorder = { borderRight: 0 }
          const noLeftBorder = { borderLeft: 0 }

          const noBorderStyles = {
            brands: {
              0: { ...noRightBorder },
              1: { ...noLeftBorder }
            },
            traffic: {
              0: { ...noRightBorder },
              1: { ...noRightBorder, ...noLeftBorder },
              2: { ...noRightBorder, ...noLeftBorder },
              3: { ...noLeftBorder }
            }
          }

          const conversionStyles = i > 5 ? { background: SECONDARY_BACKGROUND } : {}
          const noBorder =
            variant === 'brands' || variant === 'traffic' ? noBorderStyles[variant][i] : {}

          return (
            <Td
              type={variant as any}
              key={`total-tr-${i}`}
              style={variant === 'conversion' ? conversionStyles : noBorder}
            >
              {output}
            </Td>
          )
        })}
      </Tr>
    </thead>
  )
}

export default TotalsBar
