// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState } from 'react'
import { styled } from './../../../theme/index'

const Container = styled('div', {
  maxWidth: '100%'
})

const Title = styled('h4', {
  color: '$black',
  fontFamily: '$heading',
  fontSize: '$size18',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1,
  textTransform: 'uppercase',
  marginBottom: '29px'
})

export const VideosLibrary: React.FC<Props> = ({ data, callback, ...props }) => {
  const [videoList] = useState([
    {
      "title": "BrandCycle Platform 101",
      "url": `${process.env.REACT_APP_STAGE_DOMAIN}/sites/default/files/videos/BrandCycle_Platform_101.mp4`
    },
    {
      "title": "How to Build a BrandCycle Link - Portal",
      "url": `${process.env.REACT_APP_STAGE_DOMAIN}/sites/default/files/videos/How_to_Build_a_BrandCycle_Link.mp4`
    },
    {
      "title": "How to Build a BrandCycle Link - iOS App",
      "url": `${process.env.REACT_APP_STAGE_DOMAIN}/sites/default/files/videos/How_to_Build_a_BrandCycle_Link_iOS_App.mp4`
    },
    {
      "title": "BrandCycle Reporting 101",
      "url": `${process.env.REACT_APP_STAGE_DOMAIN}/sites/default/files/videos/BrandCycle_Reporting_101.mp4`
    },
  ]);
  // const initialState = {
  //   brand: data?.brand ?? '',
  //   url: data?.url ?? '',
  //   message: data?.message ?? ''
  // }
  // const [formInfo, setFormInfo] = React.useState(initialState)
  // const changeHandler = (key: string, value: string): void => {
  //   setFormInfo({ ...formInfo, ...{ [key]: value } })
  // }
  return (
    <Container {...props}>
    {
      videoList.map((value, i) => (
        <div key={i}>
          <Title>{value.title}</Title>
          <div>
            <video width="100%" controls controlsList="nodownload" onContextMenu={ () => {return false;}}>
              <source src={value.url} type="video/mp4" />
                Your browser does not support the video tag. Please update your browser or use a different one.
            </video>
            <i>Disclaimer: BrandCycle&apos;s payout threshold is now $20. Please note that these videos were produced before this adjustment, when the threshold was $50.</i>
          </div>
        </div>
      ))
    }
    </Container>
  )
}
