// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect, useContext } from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import {UserContext} from "../../../context/UserContext";
import client from "../../api";
import TitleSection from './../../atoms/TitleSection/TitleSection'
import Text from './../../atoms/Text/Text'
import Checkbox from './../../atoms/Checkbox/Checkbox'
import Button from "../../../components/atoms/Button/Button";

const Container = styled('div', {
  display: 'block',
  width: '100%',
  paddingLeft: 0,
  paddingRight: 0,
  alignItems: 'center',
  fontFamily: '$body',
  a: {
    color: 'black',
    fontWeight: '$regular',
    textDecoration: 'none',
    cursor: 'pointer',
    marginRight: '3px',
    marginLeft: '3px',
    wordBreak: 'break-word'
  },
  '.title-section': {
    padding: '15px 20px',
    justifyContent: 'left'
  },
  '.text-bold': {
    fontWeight: '$bold'
  },
  '.paragraph-bold': {
    fontFamily: '$label',
    marginBottom: '6px',
    fontWeight: '$bold',
    fontSize: 'size16',
    lineHeight: 1.4,
    color: '$darkgray'
  }
})

const Paragraph = styled('p', {
  marginBottom: '6px',
  fontSize: 'size16',
  fontFamily: '$body',
  lineHeight: '24px',
  maxWidth: '100%',
  wordBreak: 'break-word'
})

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16'
});

const TermsAndConditionsOfUse: React.FC<ThemeProps> = ({ isAccept=false, readOnly=false, ...props }):Element => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState('');
  const [lockValue, setLockValue] = useState(false);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;

  useEffect(() => {
    const userCred = JSON.parse(window.localStorage.getItem("user_credit"));
    if (userCred.user.terms===1) {
      setLockValue(true);
    }
  }, []);

  const handleAccepted = ():void => {
    setIsAcceptedTerms(true);
  }

  const handleChanged = ():null => {
    if (isAcceptedTerms) {
      // update the window.localStorage Obj.
      const userDetails = JSON.parse(window.localStorage.getItem('user_credit', {}));
      userDetails.user.terms = 1;
      window.localStorage.setItem('user_credit', JSON.stringify(userDetails));

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get("/api/user/termsncondition/set?_format=json", {
        headers: {csrf_token},
        auth: {username, password}
      })
        .then(() => {
          window.location.href = '/privacy-policy';
        })
        .catch(e => {
          // console.log("error", e);
          setErrorMessages(e.response.data.message);
        });
    }
    else {
      setErrorMessages("You must Accept the Terms and Conditions to continue.");
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }

  return (
    <Container {...props}>

      {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}

      <TitleSection as="h3" className="title-section">
        Terms and Conditions of Use
      </TitleSection>
      <div style={{
          padding: '20px',
          maxHeight: '250px',
          overflowY: 'scroll',
          position: 'relative'
        }} >
        <div>
          <Text as="h4" className="text-bold">
            BRANDCYCLE TERMS & CONDITIONS
          </Text>
          <Paragraph>Effective Date: April 13, 2021</Paragraph>
          <Paragraph>
            Thank you for your interest in joining the BrandCycle affiliate program (the “Program”)
            operated by BrandCycle Inc. (“we”, “our”, “us” or “BrandCycle”). By submitting an
            application or joining the Program, you agree to be bound by the terms and conditions
            below (the “Agreement”). Therefore, please read this Agreement carefully. If you do not
            agree to all of the terms and conditions of this Agreement, then you should not apply to
            or participate in the Program. We may change this Agreement at any time upon notice to
            you, so you should check this site often to see the latest version.
          </Paragraph>
          <Paragraph>
            If an individual is accessing the Program on behalf of a business entity, by doing so,
            such individual represents that they have the legal capacity and authority to bind such
            business entity to this Agreement. The term “you” shall refer to both the business
            entity and the individual accessing the Program on behalf of such business entity.
          </Paragraph>
        </div>
        <Text as="h4" className="text-bold">
          1. ENROLLMENT IN THE PROGRAM
        </Text>
        <div>
          <Paragraph>
            (a) In order to join the Program, you must submit an application through the service
            (the “BrandCycle Service”). All information provided by you in connection with the
            application and your participation in the Program must be complete and accurate. If your
            information changes, it is your responsibility to immediately update such information.
          </Paragraph>
          <Paragraph>
            (b) Upon receiving your application, we will review your information and notify you of
            your acceptance or rejection into our Program. We reserve the right to reject any
            application or later revoke an accepted application; however, we encourage you to
            contact us if you feel we have made an incorrect decision.
          </Paragraph>
          <Paragraph>
            (c) You are authorized to participate in the Program only during such time as we have
            approved your participation.
          </Paragraph>
          <Paragraph>
            (d) As part of your application, and at any time upon request, you must identify for us
            all websites, apps and other services that you will use in connection with the Program
            (“Your Services”). All of Your Services are subject to our prior written approval. You
            may not add to Your Services without our prior written approval. We may monitor, crawl
            or otherwise investigate Your Services in connection with the Program.
          </Paragraph>
          <Paragraph>
            (e) You may not use sub-affiliates or otherwise broker your participation in the Program
            to any third party without our prior written approval. If we do provide any such
            approval, you are responsible for ensuring that such third party complies with the terms
            and conditions of this Agreement, and you shall be responsible for such third party’s
            acts and omissions.
          </Paragraph>
          <Paragraph>
            (f) In order to participate in the Program, you may be required to create an account
            with us. Your account is for your sole, personal use, you may not authorize others to
            use your account, and you may not assign or otherwise transfer your account to any other
            person or entity. All registration information about you must be truthful, and you may
            not use any aliases or other means to mask your true identity. You are responsible for
            the security of your password and will be solely liable for any use or unauthorized use
            under such password. You must notify us immediately in the event that any unauthorized
            access to your account is suspected. We may suspend or terminate your access at any time
            with or without notice.
          </Paragraph>
        </div>
        <Text as="h4" className="text-bold">
          2. AFFILIATE SALES COMMISSION
        </Text>
        <div>
          <Paragraph>
            (a) The Program contains a number of offers for various merchants (“Offers”). The Offers
            are provided by various merchants (each a “Merchant”) who are not under our control. You
            agree to comply with all terms, conditions and rules set forth by a Merchant, the
            program that originates an Offer or otherwise made available to you in connection with
            an Offer.
          </Paragraph>
          <Paragraph>
            (b) The Merchant and/or their affiliate network provider will determine what qualifying
            sales or actions will be attributed to you (“Qualifying Sale”). This may include, but is
            not limited, their practices for tracking sales or actions, dealing with multiple
            affiliate referrals, reversing fraudulent sales or actions and dealing with returns.
            Their decisions shall be final and binding.
          </Paragraph>
          <Paragraph>
            (c) We shall pay you a sales commission for all Qualifying Sales at the rate set forth
            in your account on the BrandCycle Service (defined below) (“Sales Commission”).
            Notwithstanding anything to the contrary, we shall only be liable for the payment of the
            Sales Commission to the extent that we have been paid by or on behalf of the Merchant or
            its network.
          </Paragraph>
          <Paragraph>
            (d) You shall be responsible for all taxes and other similar levies applicable to the
            Sales Commission pursuant to any law or regulation. You shall report the Sales
            Commission to its taxation authorities as required by law. While we may provide certain
            information regarding taxes, you acknowledge that we are not tax consultants and that
            you should consult with your own financial professionals and advisors.
          </Paragraph>
          <Paragraph>
            (e) We shall post and maintain a designated password-protected web page for you on the
            BrandCycle Service showing your participation in the Program including the number of
            Qualifying Sales and an estimate of the Sales Commission due. Subject to subsection (c)
            above, we shall, during the month after which we receive payment from the Merchant or
            its network, mail, PayPal or otherwise transmit the Sales Commission representing the
            amount payable for the sales finalized. We reserve the right to delay sending payment as
            deemed reasonably necessary to prevent suspected cases of fraud. You will receive the
            Sales Commission in US dollars unless other arrangements have been mutually agreed upon
            by the parties.
          </Paragraph>
        </div>
        <Text as="h4" className="text-bold">
          3. WEBSITE RESTRICTIONS
        </Text>
        <Text>You and Your Service(s) may not:</Text>
        <div className="listBold">
          <Text className="paragraph-bold">
            infringe on our or any anyone else’s copyright or other intellectual property, right of
            publicity, privacy or other rights.
          </Text>
          <Text className="paragraph-bold">Violate any law, rule or regulation.</Text>
          <Text className="paragraph-bold">
            Contain or use any content that is threatening, harassing, defamatory, obscene, harmful
            to minors, or contains nudity, pornography or sexually explicit materials.
          </Text>
          <Text className="paragraph-bold">
            Contain or use any viruses, Trojan horses, worms, time bombs, cancelbots, or other
            computer programming routines that are intended to damage, interfere with,
            surreptitiously intercept or expropriate any system, data, or personal information.
          </Text>
          <Text className="paragraph-bold">
            Contain or use software or use technology that attempts to intercept, divert or redirect
            Internet traffic to or from any other website, or that potentially enables the diversion
            of Sales Commissions from another website.
          </Text>
          <Text className="paragraph-bold">
            Contain or use any content that is deceptive, fraudulent or misleading.
          </Text>
        </div>
        <Text as="h4" className="text-bold">
          4. LINKS TO THE OFFERS
        </Text>
        <Paragraph>
          Links for the Offers will be made available to you through the Program. You agree to abide
          by the following:
        </Paragraph>
        <div>
          <Text className="paragraph-bold">
            You will only use linking code obtained from the Program without modification or
            manipulation.
          </Text>
          <Text className="paragraph-bold">
            All portions of Your Services must be listed in your account on the BrandCycle Service.
          </Text>
          <Text className="paragraph-bold">
            You may not engage in cookie stuffing or include pop-ups, false or misleading links on
            Your Service. In addition, wherever possible, you will not attempt to mask the referring
            url information (i.e. the page from where the click is originating).
          </Text>
          <Text className="paragraph-bold">
            Your Service may not in any way copy, resemble, or mirror the look and feel of our
            services.
          </Text>
          <Text className="paragraph-bold">
            You may not use redirects to bounce a click off of a domain from which the click did not
            originate in order to give the appearance that it came from that domain.
          </Text>
          <Text className="paragraph-bold">
            No type of automated “clicking”, by any technique, is allowed.
          </Text>
          <Text className="paragraph-bold">
            You may not use misleading, confusing or fraudulent means in order to elicit a physical
            click by the end user on the Links.
          </Text>
        </div>
        <Text as="h4" className="text-bold">
          5. TRADEMARKS - DOMAIN NAMES & SEARCH ENGINE MARKETING / PPC
        </Text>
        <Paragraph>
          Use of any of our trademarks or any terms substantially similar (“BC Trademarks”) as part
          of the domain or sub- domain for Your Service is strictly prohibited without prior
          approval by us. If you participate in paid search engine advertising or Pay Per Click
          (“PPC”), you agree as follows:
        </Paragraph>
        <div>
          <Text className="paragraph-bold">
            You may not bid on any of the BC Trademarks or Merchant trademarks, including any
            variations or misspellings thereof for search or content-based campaigns on Google,
            Bing, Yahoo or any other keyword-based advertising network.
          </Text>
          <Text className="paragraph-bold">
            You may not use the BC Trademarks or Merchant trademarks in sequence with any other
            keyword.
          </Text>
          <Text className="paragraph-bold">
            You may not use the BC Trademarks or Merchant trademarks in your ad title, ad copy,
            display name or as the display URL.
          </Text>
          <Text className="paragraph-bold">
            You may not direct link to the BrandCycle Service or Merchant site from any PPC ad or
            use redirects that yield the same result. Customers must be directed to an actual page
            on Your Service. If you automate your PPC campaigns, it is your responsibility to
            exclude the BC Trademarks and Merchant trademarks from your program.
          </Text>
        </div>
        <Text as="h4" className="text-bold">
          6. EMAIL / TEXT MARKETING
        </Text>
        <Paragraph>
          If you intend to promote our Program via e-mail or text campaigns, you must adhere to the
          following:
        </Paragraph>
        <div>
          <Text className="paragraphBold">
            Abide by the CAN-SPAM Act of 2003 (Public Law No. 108-187) (the “Act”) with respect to
            our Program.
          </Text>
          <Text className="paragraph-bold">
            E-mail must be sent on your behalf and must not imply that the e-mail is being sent on
            behalf of us or the Merchant. You shall ensure that you are the “sender” of all such
            emails as defined under the Act.
          </Text>
          <Text className="paragraph-bold">
            You will not send any unsolicited commercial emails unless the end user has specifically
            opted in to receiving the emails. You will not promote our Program through an “opt-out”
            email list where the end user has not specifically opted in to receiving your emails.
          </Text>
          <Text className="paragraph-bold">
            You may not send SMS or MMS messages to an end user’s device unless the user has
            specifically opted in to receiving such messages, in compliance with all applicable
            laws, rules, regulations and best industry practices.
          </Text>
          <Text className="paragraph-bold">
            You may not use any sweepstakes or contests in connection with the Program without
            complying with all applicable laws, rules and regulations.
          </Text>
        </div>
        <Text as="h4" className="text-bold">
          7. PRIVACY
        </Text>
        <Paragraph>
          Your Services must contain a prominent privacy policy that fully, clearly and accurately
          describes the information collection and use practices for such sites. BrandCycle’s
          Privacy Policy is incorporated into and part of this Agreement. Without limiting the
          foregoing, you must comply with all applicable laws, rules and regulations regarding
          privacy, including any industry best practices or self-regulatory programs. You may not
          collect personal information from children under 13 in violation of the Children’s Online
          Privacy Protection Act (“COPPA”). In addition, you may not place links to the Program or
          the BrandCycle Service on websites targeted towards children under 13 or send us personal
          information in violation of COPPA. If you engage in any retargeting or interest-based /
          behavioral advertising, you must comply with all principles set forth by the Digital
          Advertising Alliance (“DAA”), including the Self-Regulatory Principles for Online
          Behavioral Advertising.
        </Paragraph>
        <Text as="h4" className="text-bold">
          8. SOCIAL MEDIA
        </Text>
        <Paragraph>
          Promotion on Facebook, Twitter, and other social media platforms is permitted following
          these general guidelines:
        </Paragraph>
        <div>
          <Text className="paragraph-bold">
            Notwithstanding any authorization provided by us, you must comply with all terms,
            conditions, guidelines and rules set forth by the applicable social media platform
            provider and shall not take any actions that would put us in violation of such
            requirements.
          </Text>
          <Text className="paragraph-bold">
            Subject to the foregoing, You ARE allowed to promote the BrandCycle Service to your own
            lists; more specifically, you are welcome to use the links on your own Facebook,
            Twitter, etc. pages.
          </Text>
        </div>
        <Text as="h4" className="text-bold">
          9. OPERATIONS OUTSIDE UNITED STATES
        </Text>
        <Paragraph>
          If you are conducting business in or taking orders from persons in other countries, you
          will follow the laws of those countries. For example, you will comply with the General
          Data Protection Regulation (EU) 2016/679 and Privacy and Electronic Communications
          Directive 2002/58 if you are conducting business in or taking orders from persons in one
          or more of the European Union countries, or for persons in Canada the Personal Information
          Protection and Electronic Documents Act (PIPEDA).
        </Paragraph>
        <Text as="h4" className="text-bold">
          10. FTC DISCLOSURE REQUIREMENTS
        </Text>
        <Paragraph>
          You shall include a disclosure statement within any and all pages, blog/posts, or social
          media posts where links to the Offers are posted, and where it is not clear that the link
          is a paid advertisement. This disclosure statement should be clear and concise, stating
          that we are compensating you for your review or endorsement. If you received the product
          for free from us, this also must be clearly stated in your disclosure.
        </Paragraph>
        <div className="listBold">
          <Text className="paragraph-bold">
            Disclosures must be made as close as possible to the claims and may not appear solely in
            a “Terms of Use”, “Legal”, “About Us” or other linked page.
          </Text>
          <Text className="paragraph-bold">
            Disclosures must be made at each instance of a sponsored link. A single statement on the
            site, or a separate page with your general disclosure statement is not sufficient.
          </Text>
          <Text className="paragraph-bold">
            Disclosures should be placed above the fold; scrolling should not be necessary to find
            the disclosure. (e.g., disclosure should be visible before the jump).
          </Text>
          <Text className="paragraph-bold">
            Pop-up, hover state and button disclosures are prohibited.
          </Text>
          <Text className="paragraph-bold">
            Disclosure policy applies to all social media, even when space is restricted (e.g.,
            tweets) You must comply with all Federal Trade Commission (&quot;FTC&quot;) disclosure
            requirements, including the FTC&apos;s &quot;Dot Com Disclosures&quot; Guidelines at{' '}
            <a href="http://www.ftc.gov/os/2013/03/130312dotcomdisclosures.pdf%3B">
              http://www.ftc.gov/os/2013/03/130312dotcomdisclosures.pdf
            </a>
            ; and the FTC&apos;s Guides Concerning the Use of Endorsements and Testimonials in
            Advertising at
            <a href="http://www.ftc.gov/os/2013/03/130312dotcomdisclosures.pdf%3B">
              http://www.ftc.gov/sites/default/files/attachments/press-releases/ftc-publishes-final-guides-
              governing-endorsements-testimonials/091005revisedendorsementguides.pdf
            </a>{' '}
            and further explained at{' '}
            <a href="http://business.ftc.gov/advertising-and-marketing/endorsements">
              http://business.ftc.gov/advertising-and-marketing/endorsements
            </a>
            If you engage in so called “native advertising”, you further agree to comply with the
            FTC’s Enforcement Policy Statement on Deceptively Formatted Advertisements at{' '}
            <a href="https://www.ftc.gov/system/files/documents/public_statements/896923/151222deceptiveenforcement.pdf">
              https://www.ftc.gov/system/files/documents/public_statements/896923/151222deceptiveenforcement.pdf
            </a>
            and the related guidelines.
          </Text>
        </div>
        <Text as="h4" className="text-bold">
          11. REVERSAL OF COMMISSIONS
        </Text>
        <Paragraph>
          The Company seeks to maintain a very low reversal rate, and transparent communication with
          our affiliates. However, we, the Merchants and their networks, reserve the right to
          reverse Qualifying Sales due to order cancellations, duplicate tracking, returns, disputed
          charges, and program violations as outlined in this Agreement. Additionally, if we ask you
          for clarification or more information on any orders or clicks that we suspect may be in
          violation of our this Agreement, we expect that you will respond in a timely and honest
          manner. Below are violations of our communications policy.
        </Paragraph>
        <div>
          <Text className="paragraph-bold">
            You are not forthcoming, intentionally vague or are found to be lying.
          </Text>
          <Text className="paragraph-bold">
            You are not responsive within a reasonable time period and after multiple attempts to
            contact you.
          </Text>
          <Text className="paragraph-bold">
            You cannot substantiate or validate the source of your traffic to our program with clear
            and demonstrable proof.
          </Text>
        </div>
        <Text className="text-bold">12. VIOLATIONS OF THIS AGREEMENT</Text>
        <Paragraph>
          Without limiting any of our other rights or remedies, if we reasonably determine that you
          have violated any of term of this Agreement, we may take any one or more of the following
          actions, regardless of whether we have sought any clarification from you:
        </Paragraph>
        <ul style={{ paddingLeft: '38px', listStyleType: 'number' }}>
          <li className="paragraph-bold">
            {' '}
            Reverse your orders, transactions or Sales Commissions.
          </li>
          <li className="paragraph-bold"> Require you to forfeit any or all Sales Commissions.</li>
          <li className="paragraph-bold"> Set your Sales Commission level to 0%.</li>
          <li className="paragraph-bold"> Suspend you in full or in part from the Program.</li>
          <li className="paragraph-bold"> Terminate you from the Program.</li>
          <li className="paragraph-bold"> Refer your activities to legal authorities.</li>
          <li className="paragraph-bold">
            {' '}
            Share your information with third parties who are the victims, targets or otherwise
            affected by your violation of this Agreement.
          </li>
        </ul>
        <Text as="h4" className="text-bold">
          13. RELATIONSHIP OF THE PARTIES
        </Text>
        <Paragraph>
          While the parties shall work hand-in-hand for the benefit of both, you acknowledge and
          agree that you shall, from a legal perspective, act as and shall be an independent
          contractor and not an employee or agent of us. Nothing in this Agreement shall create a
          partnership, joint venture, agency, or franchise between the parties in the legal sense of
          these terms. You shall not sign any document in the name of or on behalf of us nor hold
          yourself out as being an agent of us or as having apparent authority to contract for or
          bind us. You shall not act or omit to act in any situation that would put us or a Merchant
          in violation of any applicable law, rule or regulation.
        </Paragraph>
        <Text as="h4" className="text-bold">
          14. DIGITAL MILLENIUM COPYRIGHT ACT
        </Text>
        <Paragraph>
          If you believe any materials accessible on the website or services infringe your
          copyright, or a copyright belonging to a third party in violation of the Digital
          Millennium Copyright Act, you may request removal of those materials (or access thereto)
          from the Sites or Services by contacting our copyright agent (identified below) and
          providing the following information:
        </Paragraph>
        <div>
          <Text className="paragraph-bold">
            Identification of the copyrighted work that you believe to be infringed. Please describe
            the work, and where possible include a copy or the location (e.g., URL) of an authorized
            version of the work.
          </Text>
          <Text className="paragraph-bold">
            Identification of the material that you believe to be infringing and its location.
            Please describe the material and provide Us with its URL or any other pertinent
            information that will allow Us to locate the material.
          </Text>
          <Text className="paragraph-bold">
            Your name, address, telephone number and (if available) e-mail address.
          </Text>
          <Text className="paragraph-bold">
            A statement that you have a good faith belief that the use of the materials at issue is
            not authorized by the copyright owner, its agent, or the law.
          </Text>
          <Text className="paragraph-bold">
            A statement that the information that you have supplied is accurate, and indicating that
            &quot;under penalty of perjury,&quot; you are the copyright owner or are authorized to
            act on the copyright owner&apos;s behalf.
          </Text>
          <Text className="paragraph-bold">
            A signature or the electronic equivalent from the copyright holder or authorized
            representative.
          </Text>
        </div>
        <Paragraph>BrandCycle’s agent for copyright issues is as follows:</Paragraph>
        <Paragraph>BrandCycle, Inc.</Paragraph>
        <Paragraph>Attn: Copyright Agent</Paragraph>
        <Paragraph>16 Rae Ave</Paragraph>
        <Paragraph>Needham MA 02492</Paragraph>
        <Paragraph>
          Email: <a>support@brandcycle.com</a>
        </Paragraph>
        <Paragraph>
          This contact information is only for suspected copyright infringement. We will remove any
          content that infringes upon the copyright of any person under the laws of the United
          States upon receipt of such a statement (or any statement in conformance with 17 U.S.C. §
          512(c)(3)) and will terminate the access privileges of those who repeatedly infringe on
          the copyright of others.
        </Paragraph>
        <Text as="h4" className="text-bold">
          15. DISCLAIMER; LIMITATION OF LIABILITY; INDEMNIFICATION
        </Text>
        <Paragraph>
          The Program and other services are provided “as is” without any warranties, express or
          implied. We make no guaranty that you will earn any amount of Sales Commissions. In no
          event shall we be liable to you or any third party for special, incidental, consequential
          or punitive damages, including, without limitation, any damages resulting from loss of
          profits, loss of business or loss of goodwill arising out of or in connection with this
          Agreement, our service or the Program, whether or not we have been advised of the
          possibility of such damages. In any event, our maximum liability to you for any claims
          shall in no event exceed the amount of Sales Commissions paid to you during the prior six
          (6) months.
        </Paragraph>
        <Paragraph>
          You agree to defend, indemnify and hold harmless BrandCycle, its officers, directors,
          employees, business partners and agents, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses (including but not limited
          to attorney&apos;s fees) arising from: (i) any breach by you of any of this Agreement,
          (ii) your promotional activities or (iii) your use of materials or features available in
          the Program (except to the extent a claim is based upon infringement of a third party
          right by materials created by us).
        </Paragraph>
        <Text as="h4" className="text-bold">
          16. INTELLECTUAL PROPERTY
        </Text>
        <Paragraph>
          BrandCycle or its licensors are the exclusive owners of all copy, software, graphics,
          designs and all copyrights, trademarks and other intellectual property or proprietary
          rights contained on or used in connection with the BrandCycle Service and Program. Except
          as set forth herein, you agree not to copy, distribute, modify or make derivative works of
          any materials without the prior written consent of the owner of such materials. You
          acknowledge that the BrandCycle Service contains confidential information, and therefore
          you shall not disclose such materials to any third party without our prior written
          approval. All rights not granted under this Agreement are reserved by BrandCycle.
        </Paragraph>
        <Paragraph>
          You agree to protect all BrandCycle Confidential Information from unauthorized disclosure.
          “Confidential Information” means all non-public information provided by or obtained
          through BrandCycle, including but not limited to, the BrandCycle Service portal and
          user-interface, access credentials, pricing, commission rates and list of Merchants or
          Offers. You may not share or provide access to any Confidential Information to any party
          that has not been authorized in writing by BrandCycle to receive such information from
          you.
        </Paragraph>
        <Text as="h4" className="text-bold">
          17. TERM OF THE AGREEMENT
        </Text>
        <Paragraph>
          (a) Either party may terminate this Agreement and/or your participation in the Program at
          any time upon written notice.
        </Paragraph>
        <Paragraph>
          (b) As soon as notice of termination of this Agreement is given or upon termination as
          herein provided, you shall immediately cease marketing and advertising the BrandCycle
          Service and shall eliminate all mention, links and references to our service. Pending the
          completion of the foregoing, we may hold any Sales Commissions due.
        </Paragraph>
        <Text as="h4" className="text-bold">
          18. ENTIRE AGREEMENT; MODIFICATION; WAIVER
        </Text>
        <Paragraph>
          This Agreement constitutes the entire agreement between the parties pertaining to the
          subject matter contained in it and supersedes all prior and contemporaneous agreements,
          representations, and understandings of the parties. No supplement, modification or
          amendment of this Agreement shall be binding unless executed in writing by all the
          parties. No waiver of any of the provisions of this Agreement shall be deemed, or shall
          constitute, a waiver of any other provision, whether or not similar, nor shall any waiver
          constitute a continuing waiver. No waiver shall be binding unless executed in writing by
          the party making the waiver.
        </Paragraph>
        <Paragraph>
          In addition to this Agreement, you must comply with all instructions, policies and
          procedures set forth on the BrandCycle Service, including those set forth by a Merchant.
        </Paragraph>
        <Text as="h4" className="text-bold">
          19 ASSIGNMENT
        </Text>
        <Paragraph>
          This Agreement shall be binding on, and shall inure to the benefit of, the parties to it
          and their respective heirs, legal representatives, successors and assigns; provided,
          however, you may not assign any of your rights under this Agreement without written
          consent from us.
        </Paragraph>
        <Text as="h4" className="text-bold">
          20. GOVERNING LAW; VENUE
        </Text>
        <Paragraph>
          This Agreement shall be construed in accordance with, and governed by, the laws of the
          Commonwealth of Massachusetts and without reference to conflict of laws principles. Each
          party consents to the exclusive jurisdiction and venue of the courts located in Boston,
          Massachusetts for all matters.
        </Paragraph>
        <Text as="h4" className="text-bold">
          21. RECOVERY OF LITIGATION EXPENSES
        </Text>
        <Paragraph>
          If any legal action or any arbitration or other proceeding is brought for the enforcement
          of this Agreement, or because of an alleged dispute, breach, default or misrepresentation
          in connection with any of the provisions of this Agreement, the successful or prevailing
          party or parties shall be entitled to recover reasonable attorneys’ fees and other costs
          incurred in that action or proceeding, in addition to any other relief to which it or they
          may be entitled.
        </Paragraph>
        <Text as="h4" className="text-bold">
          22. SEVERABILITY
        </Text>
        <Paragraph>
          If any term, provision, covenant or condition of this Agreement is held by an arbitrator
          or court of competent jurisdiction to be invalid, void or unenforceable, the rest of the
          Agreement shall remain in full force and effect and shall in no way be affected, impaired
          or invalidated.
        </Paragraph>
        <Text as="h4" className="text-bold">
          23. NON-WAIVER
        </Text>
        <Paragraph>
          No delay or omission by us in exercising any right under this Agreement shall operate as a
          waiver of that or any other right.
        </Paragraph>
        <Text className="text-bold">Contact Information:</Text>
        <Paragraph>BrandCycle</Paragraph>
        <Paragraph>16 Rae Avenue</Paragraph>
        <Paragraph>Needham, MA 02492</Paragraph>
        <Paragraph>
          Email: <a>info@brandcycle.com</a>
        </Paragraph>

        <Checkbox name="termsNDCond" className="text-bold" readOnly={lockValue} isAccept={isAccept} callback={handleAccepted} >
          Accept Terms & Conditions of Use *
        </Checkbox>

        { readOnly===false &&
          <Button type="action" style={{width: '10rem', marginTop: '2rem'}}
            onClick={() => {
              handleChanged();
            }}
          >
            Continue
          </Button>
        }
      </div>
    </Container>
  )
}

export default TermsAndConditionsOfUse
