import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import banner from './../../../images/login.jpg'

interface Props extends ThemeProps {
  height?: number
  src?: string
}

const Container = styled('div', {
  display: 'none',
  position: 'relative',
  width: '100%',
  color: '$clear',
  '@bp2': {
    display: 'block'
  }
})

const Overlay = styled('div', {
  zIndex: 0,
  height: '100%',
  width: '100%',
  position: 'absolute',
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
})

const Display = styled('div', {
  height: '350px',
  display: 'flex',
  alignItems: 'center',
  backgroundSize: 'cover'
})

const Title = styled('h1', {
  fontSize: '44px',
  margin: '0 auto',
  letterSpacing: '5px',
  fontFamily: '$heading',
  fontWeight: '$bold',
  textTransform: 'uppercase',
  position: 'relative'
})

const HeroBanner: React.FC<Props> = ({ height, title="LOG IN", ...props }) => {
  const displayStyles: any = {
    backgroundImage: `url(${banner})`,
    backgroundPosition: 'center center'
  }

  if (height !== undefined) {
    displayStyles.height = `${height}`
  }

  return (
    <Container {...props}>
      <Overlay />
      <Display css={displayStyles}>
        <Title>{title}</Title>
      </Display>
    </Container>
  )
}

export default HeroBanner
