import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import Image from './../../atoms/Image/Image'

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  aspectRatio?: number
  src: string
}

const Container = styled('div', {
  display: 'block',
  position: 'relative',
  overflow: 'hidden',
  width: '100%'
})

const Logo: React.FC<Props> = ({ src, aspectRatio = 0.8, css, ...props }) => {
  return (
    <Container {...props}>
      <Image src={src} alt="Site Logo" aspectRatio={aspectRatio} css={css} />
    </Container>
  )
}

export default Logo
