import { styled } from "./../../../theme/index";

export const Container = styled('div', {
  width: '100%',
  maxWidth: '99%',
  position: 'relative'
})

export const Border = styled('div', {
  border: '1px solid #e1e5e9',
  marginTop: '27px'
})

export const Title = styled('h1', {
  fontFamily: '$heading',
  fontSize: '18px',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: '18px',
  textTransform: 'uppercase',
  marginBottom: '30px',
  color: '$black',
  margin: '11px 0 20px',
  paddingTop: '11px'
})