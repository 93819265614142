import React from 'react'
import type { CSS } from '@stitches/react'
import { styled } from './../../../theme/index'

interface Props {
  aspectRatio?: number
  css?: CSS
  src: string
  alt?: string
}

const ImageElement = styled('div', {
  display: 'block',
  position: 'relative',
  overflow: 'hidden',
  maxWidth: '100%',
  width: '100%'
})

const Text = styled('span', {
  display: 'block',
  width: '100%',
  maxWidth: '100%'
})

  const Display = styled('img', {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 'auto',
    maxWidth: '100%'
  })


const Image: React.FC<Props> = ({ src, alt, aspectRatio = 1, css = undefined, ...props }) => {
  return (
    <ImageElement {...props} css={css}>
      <Text
        style={{
          paddingTop: `${aspectRatio * 100}%`
        }}
      ></Text>
      <Display src={src} loading="lazy" alt={alt} />
    </ImageElement>
  )
}

export default Image
