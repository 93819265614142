import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import Text from './../../atoms/Text/Text'
import Image from './../../atoms/Image/Image'
import Download from './../../../images/download-on-the-app-store-badge.png'

const Container = styled('div', {
  display: 'block',
  maxWidth: '100%'
})

const lH = { lineHeight: 1.562 }

const Paragraph = styled(Text, {
  marginBottom: '10px',
  letterSpacing: '-0.2px',
  ...lH
})

const Heading = styled('div', {
  fontFamily: '$heading',
  fontSize: '18px',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1,
  textTransform: 'uppercase',
  color: '$black',
  margin: '40px 0 20px'
})

const fontBase = {
  fontFamily: '$body',
  fontSize: '16px',
  letterSpacing: '-0.2px',
  ...lH
}

const SubHeading = styled('div', {
  ...fontBase,
  fontWeight: '$bold',
  fontStyle: 'italic'
})

const OrderedList = styled('ol', {
  paddingLeft: '16px',
  margin: '0 0 10px'
})

const ListElement = styled('li', { ...fontBase })

const Quote = styled('span', {
  fontStyle: 'italic',
  ...lH
})

const LogoWrapper = styled('a', {
  display: 'block',
  maxWidth: '200px',
  img: {
    maxWidth: '100%'
  }
})

const Link = styled('a', {
  color: '$black',
  '&:hover': {
    color: '#275153'
  }
})

const LinkVariant = styled(Link, {
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
})

const Title = styled('h4', {
  color: '$black',
  fontFamily: '$heading',
  fontSize: '$size14',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1,
  textTransform: 'uppercase',
  marginBottom: '0'
})

const MobileAppContent: React.FC<ThemeProps> = ({ ...props }) => {
  const videoTitle = "How to Build a BrandCycle Link - iOS App";
  const videoSrc = `${process.env.REACT_APP_STAGE_DOMAIN}/sites/default/files/videos/How_to_Build_a_BrandCycle_Link_iOS_App.mp4`;

  return (
    <Container {...props}>
      <Paragraph>
        BrandCycle’s iOS app lets you build custom links directly from your iPhone and post them to
        your social channels, whenever and wherever you may be!
      </Paragraph>

      <Paragraph>
        There are two different ways to build links on the app. Read below for a step-by-step guide
        on how to download our app and create tracking links from your Apple device.
      </Paragraph>

      <Paragraph css={{ fontStyle: 'italic' }}>Download Our App Today!</Paragraph>

      <LogoWrapper
        href="https://apps.apple.com/us/app/brandcycle/id1644978168"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <Image src={Download} alt="app store logo" aspectRatio={0.34} />
      </LogoWrapper>

      <Heading>Instructions:</Heading>

      <OrderedList>
        <ListElement>
          Download the{' '}
          <Link
            href="https://apps.apple.com/us/app/brandcycle/id1644978168"
            target="_blank"
            rel="nofollow noreferrer"
          >
            BrandCycle iOS App from the App Store
          </Link>
        </ListElement>

        <ListElement>Sign in with your BrandCycle username and password</ListElement>

        <ListElement>
          Open your mobile browser and go to the brand page or product page from which you would
          like to build a tracking link
        </ListElement>
      </OrderedList>

      <SubHeading>Option #1</SubHeading>

      <OrderedList start={4 as any}>
        <ListElement>
          Click the ‘Share’ button within the mobile browser and select BrandCycle
        </ListElement>
      </OrderedList>

      <Quote>
        *Note - If you don’t see the BrandCycle app pop up, click the three dots to add the
        BrandCycle app to the Share Sheet - from there, click Edit, add the BrandCycle app and click
        Done
      </Quote>

      <OrderedList start={5 as any}>
        <ListElement>
          BrandCycle popup will display within the browser and your custom link will be available to
          copy and share with your audience
        </ListElement>
      </OrderedList>

      <SubHeading>Option #2</SubHeading>

      <OrderedList start={4 as any}>
        <ListElement>Copy the URL</ListElement>

        <ListElement>
          Go back into the BrandCycle app and click ‘Build a Link’ at the bottom left and paste the
          destination URL into the designated box
        </ListElement>

        <ListElement>
          Click &apos;Generate Deep Links&apos; and then copy and share your personalized link with your
          audience
        </ListElement>
      </OrderedList>

      <Title>{videoTitle}</Title>
      <video width="100%" controls controlsList="nodownload" onContextMenu={ () => {return false;}}>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag. Please update your browser or use a different one.
      </video>

      <Paragraph>
        If you have any trouble downloading the BrandCycle IOS App, please{' '}
        <LinkVariant href="/support">contact support</LinkVariant>.
      </Paragraph>
    </Container>
  )
}

export default MobileAppContent
