// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { styled } from './../../../theme/index'
import Input from './../../atoms/Input/Input'
import Select from './../../atoms/Select/Select'
import Password from './../../molecules/Password/Password'
import TitleSection from './../../atoms/TitleSection/TitleSection'
import Checkbox from './../../atoms/Checkbox/Checkbox'
import DatePicker from './../../atoms/DatePicker/DatePicker'
import DragDropList from './../../molecules/DragDropList/DragDropList'
import { type Item as DragAndDropItem } from './../../molecules/DragDropList/DragDropItem'
import { Button } from '../../atoms/Button/Button'
import TermsAndConditionsOfUse from './../../molecules/TermsandConditionsofUse/TermsAndConditionsOfUse'
import Tooltips from './../../atoms/Tooltip/Tooltips'
import {UserContext} from "../../../context/UserContext";
import client from "../../api";
import moment from "moment";
import ErrorMessage from "../../ErrorMessage";


const Container = styled('div', {})

const Heading = styled('h2', {
  fontFamily: '$label',
  fontSize: '36px',
  fontWeight: '600',
  letterSpacing: '1px',
  lineHeight: 1.1,
  margin: 0,
  padding: '1px 0 24px',
  color: '$darkgray'
})

const SubHeading = styled('span', {
  display: 'block',
  fontFamily: '$heading',
  fontSize: '17px',
  fontWeight: '500',
  lineHeight: 1.1,
  textTransform: 'uppercase',
  marginBottom: '30px',
  color: '$black',
  padding: '20px 0 17px',
  margin: '53px 0 18px',
  borderBottom: '1px #e4e4e4 dashed'
})

const Form = styled('form')

const InputLabel = styled(Input, {
  fontSize: '12px'
})

const SelectorLabel = styled('label', {
  display: 'block',
  fontFamily: '$label',
  fontWeight: '400',
  marginBottom: '20px'
})

const SpanLabel = styled('label', {
  fontWeight: '600',
  fontFamily: '$label',
  color: '$darkgray'
})

const SaveButton = styled(Button, {
  backgroundColor: '#595F7B',
  color: '$clear',
  textTransform: 'none',
  padding: '15px 22px',
  fontFamily: '$heading',
  fontWeight: '$bold',
  lineHeight: 1,
  letterSpacing: '0.8px',
  width: 'auto',
  '&:hover': {
    backgroundColor: '#484d66'
  }
})

const CancelButton = styled(Button, {
  backgroundColor: '#808080',
  color: '$clear',
  textTransform: 'none',
  padding: '15px 34px 14px 35px',
  marginLeft: '5px',
  fontFamily: '$heading',
  lineHeight: 1,
  width: 'auto',
  letterSpacing: '0.8px'
})

const Assistance = styled('span', {
  display: 'block',
  color: '#7c7c7c',
  fontSize: '12px',
  lineHeight: 1.75,
  letterSpacing: '0.3px',
  fontFamily: '$label',
  marginTop: '-20px',
  marginBottom: '20px'
})

const ButtonSection = styled('div', {
  display: 'flex'
})

const Category = styled('label', {
  marginBottom: '2px',
  display: 'block'
})

const CategoryWrapper = styled('div', {
  maxWidth: 'max-content'
})

const Wrapper = styled('div', {
  marginBottom: '20px'
})

// // const PasswordLink = styled('a', {
// //   fontSize: '12px',
// //   fontFamily: '$body',
// //   color: '$black',
// //   textDecoration: 'none'
// // })

const TooltipChildren = styled('div', {})

const InputStyles = {
  color: '$darkgray',
  margin: '20px',
  Input: {
    fontSize: '12px'
  }
}

const SectionTitle = styled(TitleSection, {
  color: '$darkgray'
})

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16'
});
const errors = {
  mustEnterValidEmailAddress: 'You must enter a valid email address.',
  originalPasswordMissing: 'You must enter your current password to update the password.',
  missingNewPassword: 'You must confirm the password.',
  mismatchPasswords: 'Passwords Do not match',
  firstNameMissing: 'You must enter First Name.',
  lastNameMissing: 'You must enter Last name.',
  dateOfBirthMissing: 'You must enter a date of Birth and be over 18 years old.',
  addressMissing: 'You must enter an address.',
  cityMissing: 'You must enter a city.',
  zipCodeMissing: 'You must enter a zip code.',
  phoneMissing: 'You must enter a phone.',
  websiteMissing: 'You must enter a website.',
  businessModelMissing: 'You must enter a business model.',
  productCategoriesMissing: 'You must enter a product category.',
  targetAudienceMissing: 'You must enter a target audience.',
  monthlyVisitorsMissing: 'You must enter a monthly visitors.',
  monthlyPageViews: 'You must enter a month page views.',
  productCategory: 'You must select at least one.',
  passwordLessThanSixChars: 'Password must be at least 6 characters long.'
}

const Success = styled('div', {
  fontFamily: '$label',
  color: '#325e1c',
  border: '1px solid #ebccd1',
  borderColor: '#c9e1bd',
  backgroundColor: '#f3faef',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16'
});

const UserEditInfo: React.FC = () => {
  // updating the User Context
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { userCredentials, updateUserCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState('');
  const [contactError, setContactError] = useState(false);
  const [successMessages, setSuccessMessages] = useState('');
  const navigate = useNavigate();

  // const [lockValue] = useState(true);
  const [countries] = useState<DragAndDropItem[]>([
    {
      "value": "afghanistan",
      "label": "Afghanistan"
    },
    {
      "value": "aland_islands",
      "label": "Aland Islands"
    },
    {
      "value": "albania",
      "label": "Albania"
    },
    {
      "value": "algeria",
      "label": "Algeria"
    },
    {
      "value": "american_samoa",
      "label": "American Samoa"
    },
    {
      "value": "andorra",
      "label": "Andorra"
    },
    {
      "value": "angola",
      "label": "Angola"
    },
    {
      "value": "anguilla",
      "label": "Anguilla"
    },
    {
      "value": "antarctica",
      "label": "Antarctica"
    },
    {
      "value": "antigua_and_barbuda",
      "label": "Antigua and Barbuda"
    },
    {
      "value": "argentina",
      "label": "Argentina"
    },
    {
      "value": "armenia",
      "label": "Armenia"
    },
    {
      "value": "aruba",
      "label": "Aruba"
    },
    {
      "value": "australia",
      "label": "Australia"
    },
    {
      "value": "austria",
      "label": "Austria"
    },
    {
      "value": "azerbaijan",
      "label": "Azerbaijan"
    },
    {
      "value": "bahamas",
      "label": "Bahamas"
    },
    {
      "value": "bahrain",
      "label": "Bahrain"
    },
    {
      "value": "bangladesh",
      "label": "Bangladesh"
    },
    {
      "value": "barbados",
      "label": "Barbados"
    },
    {
      "value": "belarus",
      "label": "Belarus"
    },
    {
      "value": "belgium",
      "label": "Belgium"
    },
    {
      "value": "belize",
      "label": "Belize"
    },
    {
      "value": "benin",
      "label": "Benin"
    },
    {
      "value": "bermuda",
      "label": "Bermuda"
    },
    {
      "value": "bhutan",
      "label": "Bhutan"
    },
    {
      "value": "bolivia",
      "label": "Bolivia"
    },
    {
      "value": "bonaire",
      "label": "Bonaire"
    },
    {
      "value": "bosnia_and_herzegovina",
      "label": "Bosnia and Herzegovina"
    },
    {
      "value": "botswana",
      "label": "Botswana"
    },
    {
      "value": "bouvet_island",
      "label": "Bouvet Island"
    },
    {
      "value": "brazil",
      "label": "Brazil"
    },
    {
      "value": "brunei_darussalam",
      "label": "Brunei Darussalam"
    },
    {
      "value": "bulgaria",
      "label": "Bulgaria"
    },
    {
      "value": "burkina_faso",
      "label": "Burkina Faso"
    },
    {
      "value": "burundi",
      "label": "Burundi"
    },
    {
      "value": "cabo_verde",
      "label": "Cabo Verde"
    },
    {
      "value": "cambodia",
      "label": "Cambodia"
    },
    {
      "value": "cameroon",
      "label": "Cameroon"
    },
    {
      "value": "canada",
      "label": "Canada"
    },
    {
      "value": "cayman_islands",
      "label": "Cayman Islands"
    },
    {
      "value": "central_african_republic",
      "label": "Central African Republic"
    },
    {
      "value": "chad",
      "label": "Chad"
    },
    {
      "value": "chile",
      "label": "Chile"
    },
    {
      "value": "china",
      "label": "China"
    },
    {
      "value": "christmas_island",
      "label": "Christmas Island"
    },
    {
      "value": "cocos_islands",
      "label": "Cocos Islands"
    },
    {
      "value": "colombia",
      "label": "Colombia"
    },
    {
      "value": "comoros",
      "label": "Comoros"
    },
    {
      "value": "congo",
      "label": "Congo"
    },
    {
      "value": "congo",
      "label": "Congo"
    },
    {
      "value": "cook_islands",
      "label": "Cook Islands"
    },
    {
      "value": "costa_rica",
      "label": "Costa Rica"
    },
    {
      "value": "cote_d'ivoire",
      "label": "Cote d'Ivoire"
    },
    {
      "value": "croatia",
      "label": "Croatia"
    },
    {
      "value": "cuba",
      "label": "Cuba"
    },
    {
      "value": "curacao",
      "label": "Curacao"
    },
    {
      "value": "cyprus",
      "label": "Cyprus"
    },
    {
      "value": "czech_republic",
      "label": "Czech Republic"
    },
    {
      "value": "denmark",
      "label": "Denmark"
    },
    {
      "value": "djibouti",
      "label": "Djibouti"
    },
    {
      "value": "dominica",
      "label": "Dominica"
    },
    {
      "value": "dominican_republic",
      "label": "Dominican Republic"
    },
    {
      "value": "ecuador",
      "label": "Ecuador"
    },
    {
      "value": "egypt",
      "label": "Egypt"
    },
    {
      "value": "el_salvador",
      "label": "El Salvador"
    },
    {
      "value": "equatorial_guinea",
      "label": "Equatorial Guinea"
    },
    {
      "value": "eritrea",
      "label": "Eritrea"
    },
    {
      "value": "estonia",
      "label": "Estonia"
    },
    {
      "value": "ethiopia",
      "label": "Ethiopia"
    },
    {
      "value": "falkland_islands",
      "label": "Falkland Islands"
    },
    {
      "value": "faroe_islands",
      "label": "Faroe Islands"
    },
    {
      "value": "fiji",
      "label": "Fiji"
    },
    {
      "value": "finland",
      "label": "Finland"
    },
    {
      "value": "france",
      "label": "France"
    },
    {
      "value": "french_guiana",
      "label": "French Guiana"
    },
    {
      "value": "french_polynesia",
      "label": "French Polynesia"
    },
    {
      "value": "french_southern_territories",
      "label": "French Southern Territories"
    },
    {
      "value": "gabon",
      "label": "Gabon"
    },
    {
      "value": "gambia",
      "label": "Gambia"
    },
    {
      "value": "georgia",
      "label": "Georgia"
    },
    {
      "value": "germany",
      "label": "Germany"
    },
    {
      "value": "ghana",
      "label": "Ghana"
    },
    {
      "value": "gibraltar",
      "label": "Gibraltar"
    },
    {
      "value": "greece",
      "label": "Greece"
    },
    {
      "value": "greenland",
      "label": "Greenland"
    },
    {
      "value": "grenada",
      "label": "Grenada"
    },
    {
      "value": "guadeloupe",
      "label": "Guadeloupe"
    },
    {
      "value": "guam",
      "label": "Guam"
    },
    {
      "value": "guatemala",
      "label": "Guatemala"
    },
    {
      "value": "guernsey",
      "label": "Guernsey"
    },
    {
      "value": "guinea",
      "label": "Guinea"
    },
    {
      "value": "guinea-bissau",
      "label": "Guinea-Bissau"
    },
    {
      "value": "guyana",
      "label": "Guyana"
    },
    {
      "value": "haiti",
      "label": "Haiti"
    },
    {
      "value": "heard_and_mcdonald_islands",
      "label": "Heard and McDonald Islands"
    },
    {
      "value": "holy_see",
      "label": "Holy See"
    },
    {
      "value": "honduras",
      "label": "Honduras"
    },
    {
      "value": "hong_kong",
      "label": "Hong Kong"
    },
    {
      "value": "hungary",
      "label": "Hungary"
    },
    {
      "value": "iceland",
      "label": "Iceland"
    },
    {
      "value": "india",
      "label": "India"
    },
    {
      "value": "indonesia",
      "label": "Indonesia"
    },
    {
      "value": "iran",
      "label": "Iran"
    },
    {
      "value": "iraq",
      "label": "Iraq"
    },
    {
      "value": "ireland",
      "label": "Ireland"
    },
    {
      "value": "isle_of_man",
      "label": "Isle of Man"
    },
    {
      "value": "israel",
      "label": "Israel"
    },
    {
      "value": "italy",
      "label": "Italy"
    },
    {
      "value": "jamaica",
      "label": "Jamaica"
    },
    {
      "value": "japan",
      "label": "Japan"
    },
    {
      "value": "jersey",
      "label": "Jersey"
    },
    {
      "value": "jordan",
      "label": "Jordan"
    },
    {
      "value": "kazakhstan",
      "label": "Kazakhstan"
    },
    {
      "value": "kenya",
      "label": "Kenya"
    },
    {
      "value": "kiribati",
      "label": "Kiribati"
    },
    {
      "value": "korea",
      "label": "Korea"
    },
    {
      "value": "korea",
      "label": "Korea"
    },
    {
      "value": "kuwait",
      "label": "Kuwait"
    },
    {
      "value": "kyrgyzstan",
      "label": "Kyrgyzstan"
    },
    {
      "value": "lao_peoples",
      "label": "Lao People's"
    },
    {
      "value": "latvia",
      "label": "Latvia"
    },
    {
      "value": "lebanon",
      "label": "Lebanon"
    },
    {
      "value": "lesotho",
      "label": "Lesotho"
    },
    {
      "value": "liberia",
      "label": "Liberia"
    },
    {
      "value": "libya",
      "label": "Libya"
    },
    {
      "value": "liechtenstein",
      "label": "Liechtenstein"
    },
    {
      "value": "lithuania",
      "label": "Lithuania"
    },
    {
      "value": "luxembourg",
      "label": "Luxembourg"
    },
    {
      "value": "macao",
      "label": "Macao"
    },
    {
      "value": "macedonia",
      "label": "Macedonia"
    },
    {
      "value": "madagascar",
      "label": "Madagascar"
    },
    {
      "value": "malawi",
      "label": "Malawi"
    },
    {
      "value": "malaysia",
      "label": "Malaysia"
    },
    {
      "value": "maldives",
      "label": "Maldives"
    },
    {
      "value": "mali",
      "label": "Mali"
    },
    {
      "value": "malta",
      "label": "Malta"
    },
    {
      "value": "marshall_islands",
      "label": "Marshall Islands"
    },
    {
      "value": "martinique",
      "label": "Martinique"
    },
    {
      "value": "mauritania",
      "label": "Mauritania"
    },
    {
      "value": "mauritius",
      "label": "Mauritius"
    },
    {
      "value": "mayotte",
      "label": "Mayotte"
    },
    {
      "value": "mexico",
      "label": "Mexico"
    },
    {
      "value": "micronesia",
      "label": "Micronesia"
    },
    {
      "value": "moldova",
      "label": "Moldova"
    },
    {
      "value": "monaco",
      "label": "Monaco"
    },
    {
      "value": "mongolia",
      "label": "Mongolia"
    },
    {
      "value": "montenegro",
      "label": "Montenegro"
    },
    {
      "value": "montserrat",
      "label": "Montserrat"
    },
    {
      "value": "morocco",
      "label": "Morocco"
    },
    {
      "value": "mozambique",
      "label": "Mozambique"
    },
    {
      "value": "myanmar",
      "label": "Myanmar"
    },
    {
      "value": "namibia",
      "label": "Namibia"
    },
    {
      "value": "nauru",
      "label": "Nauru"
    },
    {
      "value": "nepal",
      "label": "Nepal"
    },
    {
      "value": "netherlands",
      "label": "Netherlands"
    },
    {
      "value": "new_caledonia",
      "label": "New Caledonia"
    },
    {
      "value": "new_zealand",
      "label": "New Zealand"
    },
    {
      "value": "nicaragua",
      "label": "Nicaragua"
    },
    {
      "value": "niger",
      "label": "Niger"
    },
    {
      "value": "nigeria",
      "label": "Nigeria"
    },
    {
      "value": "niue",
      "label": "Niue"
    },
    {
      "value": "norfolk_island",
      "label": "Norfolk Island"
    },
    {
      "value": "northern_mariana_islands",
      "label": "Northern Mariana Islands"
    },
    {
      "value": "norway",
      "label": "Norway"
    },
    {
      "value": "oman",
      "label": "Oman"
    },
    {
      "value": "pakistan",
      "label": "Pakistan"
    },
    {
      "value": "palau",
      "label": "Palau"
    },
    {
      "value": "palestine",
      "label": "Palestine"
    },
    {
      "value": "panama",
      "label": "Panama"
    },
    {
      "value": "papua_new_guinea",
      "label": "Papua New Guinea"
    },
    {
      "value": "paraguay",
      "label": "Paraguay"
    },
    {
      "value": "peru",
      "label": "Peru"
    },
    {
      "value": "philippines",
      "label": "Philippines"
    },
    {
      "value": "pitcairn",
      "label": "Pitcairn"
    },
    {
      "value": "poland",
      "label": "Poland"
    },
    {
      "value": "portugal",
      "label": "Portugal"
    },
    {
      "value": "puerto_rico",
      "label": "Puerto Rico"
    },
    {
      "value": "qatar",
      "label": "Qatar"
    },
    {
      "value": "reunion",
      "label": "Reunion"
    },
    {
      "value": "romania",
      "label": "Romania"
    },
    {
      "value": "russian_federation",
      "label": "Russian Federation"
    },
    {
      "value": "rwanda",
      "label": "Rwanda"
    },
    {
      "value": "saint_barthelemy",
      "label": "Saint Barthelemy"
    },
    {
      "value": "saint_helena",
      "label": "Saint Helena"
    },
    {
      "value": "saint_kitts_and_nevis",
      "label": "Saint Kitts and Nevis"
    },
    {
      "value": "saint_lucia",
      "label": "Saint Lucia"
    },
    {
      "value": "saint_martin",
      "label": "Saint Martin"
    },
    {
      "value": "saint_pierre_and_miquelon",
      "label": "Saint Pierre and Miquelon"
    },
    {
      "value": "saint_vincent_and_the_grenadines",
      "label": "Saint Vincent and the Grenadines"
    },
    {
      "value": "samoa",
      "label": "Samoa"
    },
    {
      "value": "san_marino",
      "label": "San Marino"
    },
    {
      "value": "sao_tome_and_principe",
      "label": "Sao Tome and Principe"
    },
    {
      "value": "saudi_arabia",
      "label": "Saudi Arabia"
    },
    {
      "value": "senegal",
      "label": "Senegal"
    },
    {
      "value": "serbia",
      "label": "Serbia"
    },
    {
      "value": "seychelles",
      "label": "Seychelles"
    },
    {
      "value": "sierra_leone",
      "label": "Sierra Leone"
    },
    {
      "value": "singapore",
      "label": "Singapore"
    },
    {
      "value": "sint_maarten",
      "label": "Sint Maarten"
    },
    {
      "value": "slovakia",
      "label": "Slovakia"
    },
    {
      "value": "slovenia",
      "label": "Slovenia"
    },
    {
      "value": "solomon_islands",
      "label": "Solomon Islands"
    },
    {
      "value": "somalia",
      "label": "Somalia"
    },
    {
      "value": "south_africa",
      "label": "South Africa"
    },
    {
      "value": "south_georgia",
      "label": "South Georgia"
    },
    {
      "value": "south_sudan",
      "label": "South Sudan"
    },
    {
      "value": "spain",
      "label": "Spain"
    },
    {
      "value": "sri_lanka",
      "label": "Sri Lanka"
    },
    {
      "value": "sudan",
      "label": "Sudan"
    },
    {
      "value": "suriname",
      "label": "Suriname"
    },
    {
      "value": "svalbard_and_jan mayen",
      "label": "Svalbard and Jan Mayen"
    },
    {
      "value": "swaziland",
      "label": "Swaziland"
    },
    {
      "value": "sweden",
      "label": "Sweden"
    },
    {
      "value": "switzerland",
      "label": "Switzerland"
    },
    {
      "value": "syrian_arab_republic",
      "label": "Syrian Arab Republic"
    },
    {
      "value": "taiwan",
      "label": "Taiwan"
    },
    {
      "value": "tajikistan",
      "label": "Tajikistan"
    },
    {
      "value": "tanzania",
      "label": "Tanzania"
    },
    {
      "value": "thailand",
      "label": "Thailand"
    },
    {
      "value": "timor_leste",
      "label": "Timor Leste"
    },
    {
      "value": "togo",
      "label": "Togo"
    },
    {
      "value": "tokelau",
      "label": "Tokelau"
    },
    {
      "value": "tonga",
      "label": "Tonga"
    },
    {
      "value": "trinidad_and_tobago",
      "label": "Trinidad and Tobago"
    },
    {
      "value": "tunisia",
      "label": "Tunisia"
    },
    {
      "value": "turkey",
      "label": "Turkey"
    },
    {
      "value": "turkmenistan",
      "label": "Turkmenistan"
    },
    {
      "value": "turks_and_caicos islands",
      "label": "Turks and Caicos Islands"
    },
    {
      "value": "tuvalu",
      "label": "Tuvalu"
    },
    {
      "value": "uganda",
      "label": "Uganda"
    },
    {
      "value": "ukraine",
      "label": "Ukraine"
    },
    {
      "value": "united_arab_emirates",
      "label": "United Arab Emirates"
    },
    {
      "value": "united_kingdom",
      "label": "United Kingdom"
    },
    {
      "value": "united_states",
      "label": "United States"
    },
    {
      "value": "uruguay",
      "label": "Uruguay"
    },
    {
      "value": "uzbekistan",
      "label": "Uzbekistan"
    },
    {
      "value": "vanuatu",
      "label": "Vanuatu"
    },
    {
      "value": "venezuela",
      "label": "Venezuela"
    },
    {
      "value": "viet_nam",
      "label": "Viet Nam"
    },
    {
      "value": "virgin_islands",
      "label": "Virgin Islands"
    },
    {
      "value": "virgin_islands",
      "label": "Virgin Islands"
    },
    {
      "value": "wallis_and_futuna",
      "label": "Wallis and Futuna"
    },
    {
      "value": "western_sahara",
      "label": "Western Sahara"
    },
    {
      "value": "yemen",
      "label": "Yemen"
    },
    {
      "value": "zambia",
      "label": "Zambia"
    },
    {
      "value": "zimbabwe",
      "label": "Zimbabwe"
    }
  ]); // const
  const [businessType] = useState<DragAndDropItem[]>([
    {
      "value": "individual",
      "label": "Individual"
    },
    {
      "value": "company",
      "label": "Company"
    },
    {
      "value": "other",
      "label": "Other"
    }
  ]);
  const [callingTime] = useState<DragAndDropItem[]>([
    {
      "value": "morning",
      "label": "Morning"
    },
    {
      "value": "noon",
      "label": "Noon"
    },
    {
      "value": "evening",
      "label": "Evening"
    }
  ]);
  const [businessModel] = useState<DragAndDropItem[]>([
    {
      "value": "content",
      "label": "Content"
    },
    {
      "value": "blog",
      "label": "Blog"
    },
    {
      "value": "discount",
      "label": "Discount"
    }
  ]);
  const [targetAudience] = useState<DragAndDropItem[]>([
    {
      "value": "women",
      "label": "Mostly Women"
    },
    {
      "value": "men",
      "label": "Mostly Men"
    },
    {
      "value": "both",
      "label": "Both"
    }
  ]);
  const [monthlyVisitors] = useState<DragAndDropItem[]>([
    {
      "value": "1",
      "label": "Up to 1,000"
    },
    {
      "value": "2",
      "label": "1,000 to 500,000"
    },
    {
      "value": "3",
      "label": "more than 500,000"
    }
  ]);
  const [monthlyViews] = useState<DragAndDropItem[]>([
    {
      "value": "1",
      "label": "Up to 1,000"
    },
    {
      "value": "2",
      "label": "1,000 to 500,000"
    },
    {
      "value": "3",
      "label": "more than 500,000"
    }
  ]);
  const [categories] = useState<DragAndDropItem[]>([
    "Baby Boomer",
    "Entertainment & Art",
    "Food & Drink",
    "Men",
    "Mom & Family",
    "Pets",
    "Travel",
    "Women",
    "Wedding",
    "Style & Beauty",
    "Home & Decor",
    "Health & Wellness",
    "Baby & Kids",
    "Eco-Friendly"
  ]);

  const level = window.localStorage.getItem("level");
  let forgotPassFlow = false;
  if (window.localStorage.getItem("forgotPass")!==null) {
    forgotPassFlow = true;
  }

  let isReadOnly = false;
  if (level==="analyst" || level==="link_creator") {
    isReadOnly = true;
  }

  const [pageHeader, setPageHeader] = useState("");
  const [additionalDomains, setAdditionalDomains] = useState([]);
  const [passwordValue, setPasswordValue] = useState('')
  const [confirmValue, setConfirmPassword] = useState('')
  const [selectValue, setSelectValue] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [selectCountry, setSelectCountry] = useState("");
  const [selectBestTimeToCall, setSelectBestTimeToCall] = useState([]);
  const [selectBusinessModel, setSelectBusinessModel] = useState([]);
  const [selectTargetAudience, setSelectTargetAudience] = useState([]);
  const [selectMonthlyVisitors, setSelectMonthlyVisitors] = useState([]);
  const [selectMonthlyPageViews, setSelectMonthlyPageViews] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [acceptedTNC, setAcceptedTNC] = useState(false);

  const inputEMail = useRef(null);
  const inputCurrentPassword = useRef(null);
  const inputFirstName = useRef(null);
  const inputLastName = useRef(null);
  const inputUsername = useRef(null);
  const inputCompany = useRef(null);
  const inputAddress = useRef(null);
  const inputCity = useRef(null);
  const inputRegion = useRef(null);
  const inputZipCode = useRef(null);
  const inputPhone = useRef(null);
  const inputFax = useRef(null);
  const inputWebsite = useRef(null);
  const inputSecondaryWebsite = useRef(null);
  const inputFacebook = useRef(null);
  const inputInstagram = useRef(null);
  const inputTwitter = useRef(null);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token, logout_token, user } = userCredentials;
  useEffect(() => {
    if (password.length>2 && username.length>2 && csrf_token.length>2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get("/api/user/get/details/full", {
        headers: { csrf_token },
        auth: { username, password }
      })
        .then(({data}) => {
          // setOldDetails({...data}); // saving original data for compare before submitting the new
          setPageHeader(data.userName);
          inputEMail.current.value = data.email;

          inputFirstName.current.value = data.firstName;
          inputLastName.current.value = data.lastName;

          if (level==="analyst" || level==="link_creator") {
            inputUsername.current.value = username;
          }

          if (inputCompany.current!==null) {
            inputCompany.current.value = data.company;
          }
          if (inputAddress.current!==null) {
            inputAddress.current.value = data.address;
          }
          if (inputCity.current!==null) {
            inputCity.current.value = data.city;
          }
          if (inputRegion.current!==null) {
            inputRegion.current.value = data.region;
          }
          if (inputZipCode.current!==null) {
            inputZipCode.current.value = data.zipCode;
          }
          if (inputPhone.current!==null) {
            inputPhone.current.value = data.phone;
          }
          if (inputFax.current!==null) {
            inputFax.current.value = data.fax;
          }
          if (inputWebsite.current!==null) {
            inputWebsite.current.value = data.webSite;
          }
          if (inputSecondaryWebsite.current!==null) {
            inputSecondaryWebsite.current.value = data.secondaryWebsite;
          }
          if (inputFacebook.current!==null) {
            inputFacebook.current.value = data.facebookPage;
          }
          if (inputInstagram.current!==null) {
            inputInstagram.current.value = data.instagramHandle;
          }
          if (inputTwitter.current!==null) {
            inputTwitter.current.value = data.twitterHandle;
          }

          setSelectValue(data.businessType);
          setDateOfBirth(new Date(data.dateOfBirth * 1000));
          setSelectCountry(data.country);
          setSelectBestTimeToCall(data.bestTimeToCall ?? "Morning");
          setAdditionalDomains(data.additionalDomains);
          setSelectBusinessModel(data.businessModel);
          setProductCategories(data.productCategories);
          setSelectTargetAudience(data.targetAudience);
          setSelectMonthlyVisitors(data.monthlyVisitors);
          setSelectMonthlyPageViews(data.monthlyPageViews);
          setAcceptedTNC(data.termsNCond);
        })
        .catch(e => {
          console.log("error", e);
          setContactError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, username]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSubmit = (event: { preventDefault: () => void; target: any[] }): void => {
    event.preventDefault();
    setSuccessMessages("");
    setErrorMessages("");
    setContactError(false);

    window.scrollTo(0, 0);

    let newData;
    if (level==="analyst" || level==="link creator") {
      newData = {
        "email" : inputEMail.current?.value,
        "inputCurrentPassword" : !forgotPassFlow ? inputCurrentPassword.current?.value : "",
        passwordValue,
        confirmValue,
        "firstName" : inputFirstName.current?.value,
        "lastName" : inputLastName.current?.value,
        "forgotPass" : forgotPassFlow
      };
    }
    else {
      newData = {
        "email" : inputEMail.current?.value,
        "inputCurrentPassword" : !forgotPassFlow ? inputCurrentPassword.current?.value : "",
        passwordValue,
        confirmValue,
        "firstName" : inputFirstName.current?.value,
        "lastName" : inputLastName.current?.value,
        "company" : inputCompany.current?.value,
        "businessType": selectValue,
        dateOfBirth,
        "address" : inputAddress?.current?.value,
        "city" : inputCity.current?.value,
        "region" : inputRegion.current?.value,
        "country" : selectCountry,
        "forgotPass" : forgotPassFlow,
        "zipCode" : inputZipCode.current?.value,
        "phone" : inputPhone.current?.value,
        "fax" : inputFax.current?.value,
        "bestTimeToCall" : selectBestTimeToCall,
        "website" : inputWebsite.current?.value,
        additionalDomains,
        "businessModel" : selectBusinessModel,
        productCategories,
        "targetAudience" : selectTargetAudience,
        "monthlyVisitors" : selectMonthlyVisitors,
        "monthlyPageViews" : selectMonthlyPageViews,
        "secondaryWebsite" : inputSecondaryWebsite.current?.value,
        "facebook" : inputFacebook.current?.value,
        "instagram" : inputInstagram.current?.value,
        "twitter" : inputTwitter.current?.value
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    if (formValidate(newData)===1) {
      return 0;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { password, username, csrf_token } = userCredentials;
    if (password.length>2 && username.length>2 && csrf_token.length>2) {
      void client.post("/api/user/save?_format=json", newData, {
        headers: { "Content-type": "application/x-www-form-urlencoded", csrf_token },
        auth: { username, password }
      })
        .then((response) => {
          setSuccessMessages(response.data);

          // updating the localStorage user password
          if (passwordValue!=="") {
            const newUserCredit = JSON.parse(window.localStorage.getItem("user_credit"));
            newUserCredit.password = passwordValue;
            window.localStorage.setItem("user_credit", JSON.stringify(newUserCredit));

            const newCredentials = {
              password: passwordValue,
              username,
              csrf_token,
              logout_token,
              user
            };

            updateUserCredentials(newCredentials);
          }

          if (window.localStorage.getItem("forgotPass")!==null) {
            window.localStorage.removeItem("forgotPass");
          }
        })
        .catch(e => {
          console.log("while saving user: ", e);
          // if (e.response.data.message!==undefined) {
          //   setErrorMessages(e.response.data.message);
          // }
        });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const formValidate = (newData) => {
    const role = window.localStorage.getItem("level"); // user role to set the number of validations

    // validate email
    const isEmailValid = newData.email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (isEmailValid===null) {
      setErrorMessages(errors.mustEnterValidEmailAddress);
      return 1;
    }

    // validate passwords
    const currentPass = newData.inputCurrentPassword ?? "";
    const newPass = newData.passwordValue ?? "";
    const confirmPass = newData.confirmValue ?? "";

    if (newPass!=="" || confirmPass!=="") {
      // check current password if not in 'forgot password flow'
      if (!forgotPassFlow) {
        if (currentPass==="") {
          setErrorMessages(errors.originalPasswordMissing);
          return 1;
        }
      }

      if (confirmPass==="") {
        setErrorMessages(errors.missingNewPassword);
        return 1;
      }
      if (newPass!==confirmPass) {
        setErrorMessages(errors.mismatchPasswords);
        return 1;
      }
      if (newPass.length<6) {
        setErrorMessages(errors.passwordLessThanSixChars);
        return 1;
      }
    }

    if (role==="administrator" || role==="account_manager" || role==="affiliate") {
      if (newData.firstName==="") {
        setErrorMessages(errors.firstNameMissing);
        return 1;
      }
      if (newData.lastName==="") {
        setErrorMessages(errors.lastNameMissing);
        return 1;
      }
      if (newData.dateOfBirth===null) {
        setErrorMessages(errors.dateOfBirthMissing);
        return 1;
      }
      if (newData.address==="") {
        setErrorMessages(errors.addressMissing);
        return 1;
      }
      if (newData.city==="") {
        setErrorMessages(errors.cityMissing);
        return 1;
      }
      if (newData.zipCode==="") {
        setErrorMessages(errors.zipCodeMissing);
        return 1;
      }
      if (newData.phone==="") {
        setErrorMessages(errors.phoneMissing);
        return 1;
      }
      if (newData.website==="") {
        setErrorMessages(errors.websiteMissing);
        return 1;
      }
      if (newData.businessModel==="") {
        setErrorMessages(errors.businessModelMissing);
        return 1;
      }
      if (newData.targetAudience==="") {
        setErrorMessages(errors.targetAudienceMissing);
        return 1;
      }
      if (newData.monthlyVisitors==="") {
        setErrorMessages(errors.monthlyVisitorsMissing);
        return 1;
      }
      if (newData.monthlyPageViews==="") {
        setErrorMessages(errors.monthlyPageViews);
        return 1;
      }
      if (newData.productCategories.length===0) {
        setErrorMessages(errors.productCategory);
        return 1;
      }
    } // end if role==="administrator" || "account_manager" || "affiliate"
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleProductCategoriesChange = (newCategory) => {
    setProductCategories([...productCategories, newCategory]);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const basicFields = () => {
    return (
      <>
        <InputLabel type="text" label="First name" view="editForm" required css={{color: '$darkgray'}}
                    inputRef={inputFirstName} lockValue={isReadOnly} />
        <InputLabel type="text" label="Last name" view="editForm" required css={{color: '$darkgray'}}
                    inputRef={inputLastName} lockValue={isReadOnly} />

        {
          (level==="analyst" || level==="link_creator") &&
          <InputLabel type="text" label="Username" view="editForm" required css={{ color: '$darkgray' }} inputRef={inputUsername} lockValue={true} />
        }

        <InputLabel type="text" label="E-mail address" view="editForm" required css={{ color: '$darkgray' }} inputRef={inputEMail} lockValue={isReadOnly} />
        <Assistance>
          A valid e-mail address. All e-mails from the system will be sent to this address. The
          e-mail address is not made public and will only be used if you wish to receive a new
          password or wish to receive certain news or notifications by e-mail.
        </Assistance>

        {
          !forgotPassFlow &&
          <>
            <InputLabel type="password" label="Current password" view="editForm" css={{ color: '$darkgray' }}
                        value="" inputRef={inputCurrentPassword} />
            <Assistance>
              Enter your current password to change <i>the E-mail</i> address or <i>Password.</i>{' '}
            </Assistance>
          </>
        }

        <Tooltips content="To change the current user password, enter the new password in both fields.">
          <div>
            <Password
              confirmPassword={confirmValue}
              password={passwordValue}
              setConfirmPassword={setConfirmPassword}
              setPassword={setPasswordValue}
            />
          </div>
        </Tooltips>
      </>
    );
  }

  const extraFields = ():Element => {
    return (
      <>
        <InputLabel type="text" label="Company" view="editForm" css={{ color: '$darkgray' }} inputRef={inputCompany} />

        <SelectorLabel htmlFor="Business Type">
          <SpanLabel>Business Type</SpanLabel>
          <Select isForm id="Business Type" placeholder="- None -" name="Business Type" options={businessType} value={{label: selectValue}}
                  setValue={(select) => {
                    setSelectValue(select.label);
                  }} />
        </SelectorLabel>

        <SubHeading>User Information</SubHeading>
        <SectionTitle>Date of Birth *</SectionTitle>
        <Tooltips content="(Must be over 18)">
          <Wrapper style={{ marginBottom: '74px' }}>
            <Tooltips content="E.g., 2023-03-15">
              <TooltipChildren css={InputStyles}>
                <DatePicker type="secondary" value={dateOfBirth} name="initial-date" callback={(dob) => {
                  setDateOfBirth(moment(dob).format("YYYY-MM-DD"));
                  // setDateOfBirth(dob);
                }} />
              </TooltipChildren>
            </Tooltips>
          </Wrapper>
        </Tooltips>

        <Tooltips content="Please enter a valid contact address.">
          <div>
            <InputLabel type="text" label="Address" view="editForm" required css={{ color: '$darkgray' }} inputRef={inputAddress} />
          </div>
        </Tooltips>

        <InputLabel type="text" label="City" view="editForm" required css={{ color: '$darkgray' }} inputRef={inputCity} />
        <InputLabel type="text" label="Region" view="editForm" css={{ color: '$darkgray' }} inputRef={inputRegion} />

        <SelectorLabel htmlFor="Country">
          <SpanLabel>Country</SpanLabel>
          <Select isForm id="Country" placeholder="- None -" options={countries} value={{label: selectCountry}} setValue={(select) => {
              setSelectCountry(select.label);
            }} />
        </SelectorLabel>

        <Tooltips content="Please enter a valid zipcode.">
          <div>
            <InputLabel type="number" label="ZipCode" view="editForm" required css={{ color: '$darkgray' }} inputRef={inputZipCode} />
          </div>
        </Tooltips>

        <Tooltips content="Please enter a valid phone.">
          <div>
            <InputLabel type="string" label="Phone" view="editForm" required css={{ color: '$darkgray' }} inputRef={inputPhone} />
          </div>
        </Tooltips>

        <InputLabel type="text" label="Fax" view="editForm" css={{ color: '$darkgray' }} inputRef={inputFax} />

        <SelectorLabel htmlFor="calling Time">
          <SpanLabel>Best time to call</SpanLabel>
          <Select isForm id="calling Time" placeholder="- None -" options={callingTime} value={{label: selectBestTimeToCall}} setValue={(select) => {
            setSelectBestTimeToCall(select.label);
          }} />
        </SelectorLabel>
        <SubHeading>WebSite Details</SubHeading>
        <InputLabel type="text" label="Website" view="editForm" required css={{ color: '$darkgray' }} inputRef={inputWebsite} />
        <Wrapper>
          <DragDropList title="Additional Domains" data={additionalDomains} onChange={setAdditionalDomains} />
        </Wrapper>
        <SelectorLabel htmlFor="business-model">
          <SpanLabel>Business Model *</SpanLabel>
          <Select isForm id="business-model" placeholder="- None -" options={businessModel} value={{label: selectBusinessModel}} setValue={(select) => {
            setSelectBusinessModel(select.label);
          }} />
        </SelectorLabel>

        <Wrapper>
          <SpanLabel>Product Categories *</SpanLabel>
          {categories.map((category, i) => (
            <CategoryWrapper key={`category-${categories}-${i}`}>
              <Category>
                <Checkbox name={category} type="editForm"
                          isAccept={productCategories.includes(category)}
                          callback={(category) => {handleProductCategoriesChange(category)}} >
                  {category}
                </Checkbox>
              </Category>
            </CategoryWrapper>
          ))}
        </Wrapper>

        <SelectorLabel htmlFor="Target Audience">
          <SpanLabel>Target Audience *</SpanLabel>
          <Select isForm id="Target Audience" placeholder="- None -" options={targetAudience} value={{label: selectTargetAudience}} setValue={(select) => {
            setSelectTargetAudience(select.label);
          }} />
        </SelectorLabel>
        <SelectorLabel htmlFor="Monthly Visitors">
          <SpanLabel>Monthly Visitors *</SpanLabel>
          <Select isForm id="Monthly Visitors" placeholder="- None -" options={monthlyVisitors} value={{label: selectMonthlyVisitors}} setValue={(select) => {
            setSelectMonthlyVisitors(select.label);
          }} />
        </SelectorLabel>
        <SelectorLabel htmlFor="Monthly Page Views">
          <SpanLabel>Monthly Page Views *</SpanLabel>
          <Select isForm id="Monthly Page Views" placeholder="- None -" options={monthlyViews} value={{label: selectMonthlyPageViews}} setValue={(select) => {
            setSelectMonthlyPageViews(select.label);
          }} />
        </SelectorLabel>

        <InputLabel type="text" label="Secondary Website" view="editForm" css={{ color: '$darkgray' }} inputRef={inputSecondaryWebsite} />
        <InputLabel type="text" label="Facebook Page" view="editForm" css={{ color: '$darkgray' }} inputRef={inputFacebook} />
        <InputLabel type="text" label="Instagram Handle" view="editForm" css={{ color: '$darkgray' }} inputRef={inputInstagram} />
        <InputLabel type="text" label="Twitter Handle" view="editForm" css={{ color: '$darkgray' }} inputRef={inputTwitter} />
      </>
    );
  }

  return (
    <Container>
      {
        (level!=="analyst" && level!=="link_creator") &&
        <>
          <Heading>{pageHeader}</Heading>
          <SubHeading>ACCOUNT DETAILS</SubHeading>
        </>
      }
      {
        (level==="analyst" || level==="link_creator") && <Heading>ACCOUNT DETAILS</Heading>
      }

      <Form>
        { errorMessages !== '' ? <Error>{errorMessages}</Error> : '' }
        { contactError && <ErrorMessage contactSupport={true} /> }
        { successMessages !== '' ? <Success>{successMessages}</Success> : '' }

        { basicFields() }

        { level==="affiliate" ? extraFields() : null }

        { (level!=="link_creator" && level!=="analyst") && <TermsAndConditionsOfUse isAccept={acceptedTNC} readOnly={true} /> }

        <ButtonSection>
          <SaveButton onClick={handleSubmit} >Submit</SaveButton>
          {level!=="link_creator" && level!=="analyst" ? <CancelButton onClick={() => {
            window.scrollTo(0, 0);
            navigate("/user/cancel");
          }}>Cancel account</CancelButton> : null }
        </ButtonSection>

        { (level==="link_creator" || level==="analyst") && <TermsAndConditionsOfUse isAccept={acceptedTNC} readOnly={true} /> }
      </Form>
    </Container>
  )
}

export default UserEditInfo
