// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useRef } from 'react'
import { styled } from '../../../theme/index'
import DeepLinkingBoxMBK from "./../../molecules/DeepLinkingBoxMBK/DeepLinkingBoxMBK";
import Input from '../../atoms/Input/Input';
import { Button } from '../../atoms/Button/Button'
import './LinkGeneratorFormBMK.css';

const Container = styled('div', {
  display: 'block',
  width: '100%',
  maxWidth: '100%',
  variants: {
    type: {
      linkGenerator: {
        '@bp5': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }
      },
      deepLink: {}
    }
  }
})

const ButtonWrapper = styled('div', {
  width: '100%',
  variants: {
    type: {
      linkGenerator: {
        '@bp5': {
          width: '52%'
        }
      },
      deepLink: {}
    }
  }
})

const Additionals = styled('div', {
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gridGap: '1rem',
  placeItems: 'center'
})

const LinkGeneratorFormBMK: React.FC = ({ brandId=0, parentURL, shortUrl, longUrl, updateLinks, enabledDeepLink }) => {
  const type = 'linkGenerator';
  const inputAffTrackingID = useRef(null);

  const handleSubmit = ():void => {
    updateLinks(inputAffTrackingID.current.value);
  }

  return (
    <Container>

      <DeepLinkingBoxMBK title="Short Link" value={shortUrl} />
      <DeepLinkingBoxMBK title="Full Link" value={longUrl} />

      {
        (enabledDeepLink==="1" || enabledDeepLink===true)  &&
          <Additionals>
            <Input label="Aff Tracking ID" type="text" className="value" view={type} optional={true}
                   labelSpacedVariant={type === 'linkGenerator'} inputRef={inputAffTrackingID} style={{marginLeft: "0!important", maxWidth: "100%!important"}}/>

            <ButtonWrapper type={type}>
              <Button
                type="linkGenerator"
                onClick={() => {

                  handleSubmit();
                }}
                style={{fontSize: "1em", padding: "6px 20px", height: "44px", marginTop: "19px"}}
              >
                REGENERATE
              </Button>
            </ButtonWrapper>
          </Additionals>
      }
    </Container>
  )
}
export default LinkGeneratorFormBMK
