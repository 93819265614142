import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  color?: 'black' | 'green'
  children?: React.ReactNode
  href?: string
  underline?: boolean
}

const Link = styled('a', {
  fontSize: '$size26',
  fontFamily: '$deco',
  cursor: 'pointer',
  fontWeight: '$light',
  textDecoration: 'none',
  variants: {
    underline: {
      true: {
        '&:hover': {
          textDecoration: 'underline'
        }
      }
    },
    color: {
      black: {
        color: '$black',
        textTransform: 'lowercase',
        '&:hover': {
          color: '$green'
        }
      },
      green: {
        color: '$green4',
        lineHeight: '14px',
        display: 'inline-block'
      }
    }
  },
  transition: '0.2s ease-in-out color'
})

export const CursiveLink: React.FC<Props> = ({
  children,
  colors = 'black',
  css,
  underline = false,
  ...props
}) => {
  return (
    <Link color={colors} css={css} underline={underline} {...props}>
      {children}
    </Link>
  )
}

export default CursiveLink
