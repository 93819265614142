// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'

const Icon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="2"
      >
        <path d="M1 1h20v20H1zM1 6.556h20m-13.333 0V21" />
      </g>
    </svg>
  )
}

export default Icon
