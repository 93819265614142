// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'
import { capitalize, snakeCase, replace } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointUp, faChartSimple } from '@fortawesome/free-solid-svg-icons'
import { styled } from './../../../theme/index'

export interface HeaderValues {
  clicks?: number
  sales?: number
  payouts?: number
  orders?: number
  lockedSales?: number
  lockedPayouts?: number
  lockedOrders?: number
  conversions?: number
}

interface Props {
  data: HeaderValues
  variant: string
}

const Container = styled('div', {
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '10px 0',
  '@media(min-width: 1000px)': {
    width: '100%',
    paddingBottom: '16px',
    display: 'flex'
  }
})

const Title = styled('span', {
  marginBottom: '5px',
  display: 'inline-block',
  fontSize: '13px',
  fontFamily: '$heading',
  fontWeight: '$bold',
  lineHeight: 2.53,
  color: '#555555',
  textTransform: 'capitalize'
})

const Item = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '0 10px',
  flex: ' 0 1 12%',
  fontFamily: '$quote',
  fontSize: '15px',
  color: '#808080',
  '&:not(:last-of-type)': {
    borderRight: '1px dotted gray'
  }
})

const Amount = styled('span', {
  lineHeight: 1,
  variants: {
    type: {
      clicks: {
        color: '#808080'
      },
      sales: {
        color: '#008080'
      },
      payouts: {
        color: '#800080'
      },
      orders: {
        color: 'darkgray'
      }
    }
  }
})

const Icon = styled('span', {
  paddingRight: '5px'
})

const GraphHeader: React.FC<Props> = ({ data, variant=""}) => {
  // const role = window.localStorage.getItem("level")!==undefined ? window.localStorage.getItem("level") : "affiliate";
  const items = Object.keys(data)

  return (
    <Container>
      {items.map((item, key) => {
        // if ( variant==="brands" && item!=="clicks" && (role!=="account_manager" && role!=="admin") ) {
        //   return null;
        // }

        // if ( (variant==="summary1") && item!=="clicks") {
        //   return null;
        // }

        if (data[item] !== undefined && parseFloat(data[item]) < 0) {
          return null
        }

        const type = replace(item, 'locked', '').toLowerCase()
        const title = capitalize(snakeCase(item).replace('_', ' '))
        const showIcon = !(type === 'sales' || type === 'payouts')

        return (
          <Item key={`heading-${key}`}>
            <Title>{title}</Title>
            <Amount type={type.toLowerCase() as any}>
              {showIcon && (
                <Icon>
                  <FontAwesomeIcon
                    icon={title.includes('Clicks') ? faHandPointUp : faChartSimple}
                  />
                </Icon>
              )}
              {!showIcon && '$'}
              <span>{showIcon ? data[item] : data[item].toFixed(2).toLocaleString()}</span>
            </Amount>
          </Item>
        )
      })}
    </Container>
  )
}

export default GraphHeader
