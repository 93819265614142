// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import type { ElementType, HTMLAttributes } from 'react'
import { createStitches, type CSS } from '@stitches/react'

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } =
  createStitches({
    theme: {
      colors: {
        font: '#212529',
        black: '#000000',
        black2: '#222222',
        clear: '#ffffff',
        clear2: '#f7fbfb',
        clear3: '#F5F5F5',
        clear4: '#F9F9F9',
        gray1: '#f6f8f8',
        gray2: '#eeeeee',
        gray3: '#cccccc',
        gray4: '#dddddd',
        lightgray1: '#cfdadc',
        lightgray2: '#d5d6db',
        lightgray3: '#bbbbbb',
        darkgray: '#3c3c3c',
        green: '#3ab0a5',
        green1: '#44918a',
        green2: '#a1c7c3',
        green3: '#0C423d',
        green4: '#33706b',
        blue: '#66afe9',
        blue1: '#55acee',
        blue2: '#3b5998',
        blue3: '#3f445a',
        blue4: '#747b99',
        blue5: '#595f7b',
        blue6: '#3e4047',
        blue7: '#9fa1a8',
      },
      fontWeights: {
        semibold: '600',
        bold: '700',
        regular: '400',
        light: '300'
      },
      fontSizes: {
        size32: '2rem', // 32px
        size26: '1.625rem', // 26px
        size24: '1.5rem', // 24px
        size21: '1.3125rem', // 21px
        size18: '1.125rem', // 18px
        size17: '1.0625rem', // 17px
        size16: '1rem', // 16px
        size15: '0.9375rem', // 15px
        size14: '0.875rem' // 14px
      },
      fonts: {
        heading: '"Josefin Sans", sans-serif',
        body: '"Nunito Sans", sans-serif',
        deco: '"Dancing Script", cursive',
        quote: '"Prata", serif',
        label: '"Source Sans Pro",Helvetica,Arial,sans-serif',
        postContent: '"Lato", sans-serif',
        page: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'
      },
      radii: {},
      space: {},
      lineHeights: {},
      letterSpacings: {},
      sizes: {},
      borderWidths: {},
      borderStyles: {},
      shadows: {
        shadow: '0 1px 3px 0 rgba(77, 77, 77,0.5)'
      },
      zIndices: {},
      transitions: {}
    },
    media: {
      bp0: '(min-width: 328px)',
      bp1: '(min-width: 576px)',
      bp2: '(min-width: 768px)',
      bp3: '(min-width: 992px)',
      bp4: '(min-width: 1025px)',
      bp5: '(min-width: 1200px)',
      bp6: '(min-width: 1400px)'
    },
    utils: {
      mx: (value) => ({ marginLeft: value, marginRight: value }),
      my: (value) => ({ marginTop: value, marginBottom: value }),
      px: (value) => ({ paddingLeft: value, paddingRight: value }),
      py: (value) => ({ paddingTop: value, paddingBottom: value })
    }
  })

export interface ThemeProps extends HTMLAttributes<any> {
  css?: CSS
  as?: ElementType
}
