import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { styled, type ThemeProps } from './../../../theme/index'
import Heading from './../../atoms/Heading/Heading'
import Text from './../../atoms/Text/Text'

interface Props extends ThemeProps {
  heading: string
}

const Container = styled('div', {
  display: 'block'
})

const Wrapper = styled('div', {
  display: 'block',
  width: '100%',
  height: '100%',
  '&.closed': {
    display: 'none'
  }
})

const Title = styled(Heading, {
  display: 'inline-flex',
  textTransform: 'uppercase',
  color: '$black',
  fontFamily: '$heading',
  fontSize: '18px',
  letterSpacing: '0.5px',
  fontWeight: '$bold',
  borderBottom: 'none',
  padding: '20px 0 0',
  margin: '25px 0 7px'
})

const Trigger = styled(Text, {
  fontSize: '18px',
  display: 'block',
  color: '$green4',
  fontWeight: '$bold',
  cursor: 'pointer',
  lineHeight: '22px',
  marginLeft: '6px',
  width: '40px',
  opacity: 1,
  '&:hover': {
    opacity: 0.8
  },
  svg: {
    marginBottom: '-2px',
    marginLeft: '1px'
  }
})

const AccordionSection: React.FC<Props> = ({ heading, children, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(true)
  const handleOpen = (): void => {
    setIsOpen(!isOpen)
  }

  const iconStyles = {
    fontWeight: '900',
    fontSize: '19px'
  }

  return (
    <Container {...props}>
      <Title as="h3">
        {heading}
        <Trigger onClick={handleOpen}>
          [<FontAwesomeIcon style={iconStyles} icon={isOpen ? faMinus : faPlus} />]
        </Trigger>
      </Title>
      <Wrapper className={isOpen ? 'open' : 'closed'}>{children}</Wrapper>
    </Container>
  )
}

export default AccordionSection
