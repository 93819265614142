// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {type Dispatch, type SetStateAction, useState, useEffect } from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link as SimpleLink} from "react-router-dom";
import Link, { type Props as LinkProps } from './../../atoms/Link/Link'
import type { MenuItem } from './../../../theme/menuLinks'
import { usePopup } from './../../../state/PopupProvider'

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  navCollection?: MenuItem[]
  type?: 'home' | 'sideDashboardDesktop' | 'sideDashboardMobile'
  revertPosition?: boolean
  closeSubMenuToggle?: boolean
  sendRef?: Dispatch<SetStateAction<boolean>>
}

const Container = styled('ul', {
  padding: 0,
  margin: '0 auto',
  display: 'flex',
  width: '100%',
  height: 'max-content',
  alignItems: 'flex-star',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  textAlign: 'center',
  zIndex: 5,
  position: 'relative',
  variants: {
    type: {
      home: {
        maxWidth: '650px',
        background: '$clear',
        padding: '15px 35px',
        '@bp3': {
          padding: '15px 10px'
        }
      },
      sideDashboardDesktop: {
        padding: '20px 0 120px',
        background: '$darkgray',
        maxWidth: '744px',
        '@bp3': {
          background: 'transparent',
          padding: 0
        }
      },
      sideDashboardMobile: {
        maxWidth: '744px',
        padding: '15px 0',
        background: '#545D5F',
        margin: '0 auto',
        width: '100%'
      }
    }
  }
})

const Item = styled('li', {
  display: 'inline-flex',
  alignItems: 'center',
  listStyle: 'none',
  textAlign: 'left',
  position: 'relative',
  variants: {
    type: {
      home: {
        padding: '14px 0',
        svg: {
          color: '#4B516C',
          transform: 'scale(0.9)',
          marginRight: '8px',
          width: '22px'
        },
        span: {
          color: '$black',
          display: 'block',
          alignSelf: 'flex-end'
        }
      },
      sideDashboardDesktop: {
        '&:hover': {
          background: 'transparent',
          '@bp3': {
            '&:hover': {
              background: '$blue6'
            }
          }
        }
      },
      sideDashboardMobile: {
        padding: '12px 45px 13px',
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        span: {
          color: '$clear',
          fontSize: '15px'
        }
      }
    },
    revertPosition: {
      true: {
        position: 'revert'
      }
    }
  },
  '&.active': {
    '& > div': {
      display: 'block'
    }
  }
})

const Anchor = styled(Link, {
  variants: {
    view: {
      home: {
        display: 'inline-flex',
        alignItems: 'center',
        opacity: 1,
        '&:hover': {
          opacity: 0.8
        }
      },
      sideDashboardDesktop: {
        width: '100%',
        display: 'inline-flex',
        gridTemplateColumns: '24px 1fr',
        gap: '12px',
        padding: '5px 44px 6px',
        alignItems: 'center',
        svg: {
          color: '$clear',
          fontSize: '19px',
          transform: 'translateY(-2px)'
        },
        span: {
          color: '$clear',
          letterSpacing: '1.5px',
          lineHeight: 1.764
        },
        '@bp3': {
          padding: '18px 33px 19px',
          svg: {
            color: '#A6AAB9'
          },
          span: {
            lineHeight: 1
          }
        }
      },
      sideDashboardMobile: {
        opacity: 1,
        width: '100%',
        maxWidth: 'max-content',
        color: '$clear',
        span: {
          color: '$clear',
          textDecorationColor: '$clear',
          '&:hover': {
            textDecoration: 'underline'
          }
        }
      }
    }
  }
})

const SubMenuWrapper = styled('div', {
  position: 'absolute',
  display: 'none',
  width: '100%',
  top: 0,
  left: 0,
  margin: 0,
  padding: 0,
  zIndex: 3,
  '@bp3': {
    left: '100%',
    top: '55%',
    transform: 'translateY(-50%)'
  }
})

const SubMenu = styled('ul', {
  display: 'block',
  margin: 0,
  padding: 0,
  background: '$clear',
  minHeight: '100vh',
  '@bp3': {
    minHeight: 'auto',
    background: '$blue6',
    minWidth: '350px',
    width: '100%'
  }
})

const SubMenuItem = styled('li', {
  listStyle: 'none',
  margin: 0
})

const Close = styled('span', {
  lineHeight: 1,
  fontSize: '23px',
  fontWeight: '$bold',
  position: 'absolute',
  right: '14px',
  top: '10px',
  color: '$black',
  opacity: 0.2,
  textShadow: '0 1px 0 $clear',
  cursor: 'pointer',
  transition: '0.2s ease opacity',
  '&:hover': {
    opacity: 0.5
  }
})

const SideMenu: React.FC<Props> = ({
  navCollection = [],
  type = 'home',
  closeSubMenuToggle = false,
  revertPosition = false,
  sendClose,
  ...props
}) => {
  const [bmkFlag, setbBkFlag] = useState(false); // BMK flag
  const [menuItems] = useState([
    {
      "label": "Brands",
      "src": "#",
      "icon": {
        "prefix": "far",
        "iconName": "heart",
        "icon": [
          512,
          512,
          [
            128153,
            128154,
            128155,
            128156,
            128420,
            129293,
            129294,
            129505,
            9829,
            10084,
            61578
          ],
          "f004",
          "M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
        ]
      },
    },
    {
      "label": "Promotions",
      "src": "#",
      "icon": {
        "prefix": "far",
        "iconName": "star",
        "icon": [
          576,
          512,
          [
            11088,
            61446
          ],
          "f005",
          "M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
        ]
      },
    },
    {
      "label": "Reports",
      "icon": {
        "prefix": "fas",
        "iconName": "chart-column",
        "icon": [
          512,
          512,
          [],
          "e0e3",
          "M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32zM160 224c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm128-64V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V160c0-17.7 14.3-32 32-32s32 14.3 32 32zm64 32c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32zM480 96V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V96c0-17.7 14.3-32 32-32s32 14.3 32 32z"
        ]
      },
      "items": [
        {
          "label": "Summary Report",
          "src": "#",
        },
        {
          "label": "Conversion Report",
          "src": "#",
        },
        {
          "label": "Brands Report",
          "src": "#",
        },
        {
          "label": "Traffic Referrals Report",
          "src": "#",
        },
        {
          "label": "Payments Report",
          "src": "#",
        }
      ]
    },
    {
      "label": "Tools",
      "icon": {
        "prefix": "fas",
        "iconName": "wrench",
        "icon": [
          512,
          512,
          [
            128295
          ],
          "f0ad",
          "M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
        ]
      },
      "items": [
        {
          "label": "BookMarklet Tool",
          "src": "#",
        },
        {
          "label": "Build A Link",
          "src": "#",
        },
        {
          "label": "Mobile App",
          "src": "#",
        }
      ]
    },
    {
      "label": "Blog",
      "src": "#",
      "icon": {
        "prefix": "fas",
        "iconName": "align-left",
        "icon": [
          448,
          512,
          [],
          "f036",
          "M288 64c0 17.7-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32H256c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H256c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
        ]
      }
    },
    {
      "label": "Support",
      "src": "#",
      "icon": {
        "prefix": "far",
        "iconName": "circle-question",
        "icon": [
          512,
          512,
          [
            62108,
            "question-circle"
          ],
          "f059",
          "M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
        ]
      },
    }
  ]);
  const iconsList = {
    BRANDS : {
      "icon": {
        "prefix": "far",
        "iconName": "heart",
        "icon": [
          512,
          512,
          [
            128153,
            128154,
            128155,
            128156,
            128420,
            129293,
            129294,
            129505,
            9829,
            10084,
            61578
          ],
          "f004",
          "M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
        ]
      }
    },
    PROMOTIONS : {
      "icon": {
        "prefix": "far",
        "iconName": "star",
        "icon": [
          576,
          512,
          [
            11088,
            61446
          ],
          "f005",
          "M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
        ]
      },
    },
    REPORTS : {
      "icon": {
        "prefix": "fas",
        "iconName": "chart-column",
        "icon": [
          512,
          512,
          [],
          "e0e3",
          "M32 32c17.7 0 32 14.3 32 32V400c0 8.8 7.2 16 16 16H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H80c-44.2 0-80-35.8-80-80V64C0 46.3 14.3 32 32 32zM160 224c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm128-64V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V160c0-17.7 14.3-32 32-32s32 14.3 32 32zm64 32c17.7 0 32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32zM480 96V320c0 17.7-14.3 32-32 32s-32-14.3-32-32V96c0-17.7 14.3-32 32-32s32 14.3 32 32z"
        ]
      },
    },
    MANAGER: { },
    TOOLS : {
      "icon": {
        "prefix": "fas",
        "iconName": "wrench",
        "icon": [
          512,
          512,
          [
            128295
          ],
          "f0ad",
          "M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7H336c-8.8 0-16-7.2-16-16V118.6c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
        ]
      },
    },
    BLOG : {
      "icon": {
        "prefix": "fas",
        "iconName": "align-left",
        "icon": [
          448,
          512,
          [],
          "f036",
          "M288 64c0 17.7-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32H256c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H256c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
        ]
      }
    },
    SUPPORT : {
      "icon": {
        "prefix": "far",
        "iconName": "circle-question",
        "icon": [
          512,
          512,
          [
            62108,
            "question-circle"
          ],
          "f059",
          "M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"
        ]
      },
    }
  }

  const { dispatch } = usePopup()
  const itemsRef = React.useRef(menuItems.map(() => React.createRef<HTMLLIElement>()))
  // const newTab = bmkFlag===true ? '_blank' : '_self';

  useEffect(() => {
    const pageURL = window.location.href;
    if (pageURL.includes("/bmk")) {
      setbBkFlag(true);
    }
  }, [bmkFlag]);

  const handleOpen = (index: number, eventType: string): void => {
    const current = itemsRef.current[index].current
    const isMobile = window.innerWidth < 992
    if ((isMobile && eventType === 'hover') || (!isMobile && eventType === 'click')) {
      return
    }

    current?.classList.toggle('active')
  }

  const linkCase = (element: MenuItem, event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
    if (element.label === 'REFER A FRIEND') {
      event.preventDefault()
      dispatch({
        type: 'TOGGLE_POPUP'
      })
      if (typeof sendClose !== 'undefined') {
        sendClose(false)
      }
    }
  }

  const targetUrl = !bmkFlag ? '_self' : '"_blank"';

  return (
    <Container type={type} {...props}>
      {navCollection.map((element, index) => {
        if (element?.show === 'footer') {
          return null
        }

        const isParent: boolean = element?.items !== undefined

        let icon

        if (iconsList[element.label]?.icon !== undefined) {
          if (typeof iconsList[element.label].icon === 'object') {
            icon = <FontAwesomeIcon icon={iconsList[element.label].icon} />
          } else {
            icon = <element.icon />
          }
        }

        return (
          <Item
            ref={isParent ? itemsRef?.current[index] : undefined}
            key={`${element?.label} ${index}`}
            type={type}
            revertPosition={revertPosition}
            onClick={
              isParent
                ? (): void => {
                    handleOpen(index, 'click')
                  }
                : (e): void => {
                    linkCase(element, e)
                  }
            }
            onMouseEnter={
              isParent
                ? (): void => {
                    handleOpen(index, 'hover')
                  }
                : undefined
            }
            onMouseLeave={
              isParent
                ? (): void => {
                    handleOpen(index, 'hover')
                  }
                : undefined
            }
          >
            {
              !isParent &&
              <SimpleLink to={element?.src} style={{width:"100%"}} target={targetUrl} rel="noopener noreferrer" >
                <Anchor
                  type="third"
                  view={type as any}
                  css={{ ...element?.css, ...{ letterSpacing: '0' } }}
                  // href={element?.src}
                >
                  {icon !== undefined ? icon : <div style={{ display: 'block' }} />}
                  <span>{element?.label}</span>
                </Anchor>
              </SimpleLink>
            }
            {
              isParent &&
              <div style={{width:"100%", cursor: "pointer"}}>
                <Anchor
                  type="third"
                  view={type as any}
                  css={{ ...element?.css, ...{ letterSpacing: '0' } }}
                  // href={element?.src}
                >
                  {icon !== undefined ? icon : <div style={{ display: 'block' }} />}
                  <span>{element?.label}</span>
                </Anchor>
              </div>
            }

            <SubMenuWrapper>
              {closeSubMenuToggle && <Close aria-hidden="true">×</Close>}
              <SubMenu>
                {isParent && element?.items !== undefined
                  ? element?.items.map((item, index) => {
                      const subtype =
                        type === 'sideDashboardMobile' ? 'sideDashboardMobile' : 'sideSubMenu'
                      return (
                        <SubMenuItem key={`dashboard-navigation-submenu-link-${index}`}>
                          <SimpleLink to={item.src} target={targetUrl} rel="noopener noreferrer" style={{textDecoration: 'none'}}>
                            <Link type={subtype as unknown as LinkProps['type']}>
                              {item.label}
                            </Link>
                          </SimpleLink>
                        </SubMenuItem>
                      )
                    })
                  : ''}
              </SubMenu>
            </SubMenuWrapper>
          </Item>
        )
      })}
    </Container>
  )
}

export default SideMenu
