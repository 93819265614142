// Controls basic transitions from header components
export const animation = {
  transition: '0.3s ease-in',
  transitionDelay: '100ms'
}

export const opacity = {
  opacity: 1,
  '&:hover': {
    opacity: 0.8
  }
}
