// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'
import { styled, type ThemeProps } from '../../../theme/index'
import TrackingUrlOutput from '../TrackingUrlOutput/TrackingUrlOutput'
import ShareActions from '../ShareActions/ShareActions'
import './LinkGeneratorForm.css';
import FormContent from "./../../molecules/LinkGeneratorForm/FormContent";

interface State {
  url: string
  id: string
  isShort: boolean
}

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  handler?: (e: State) => void
  ready?: boolean
  noAction?: boolean
  urlEmpty?: boolean
  urlNotMatch?: boolean
  type?: 'linkGenerator' | 'deepLink'
}

const Container = styled('div', {
  display: 'block',
  width: '100%',
  maxWidth: '100%',
  variants: {
    type: {
      linkGenerator: {
        '@bp5': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }
      },
      deepLink: {}
    }
  }
})

const OptionsUrl = styled('div', {
  marginTop: '20px',
  '@bp3': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

const Actions = styled('div', {
  marginTop: '40px',
  maxWidth: '100%',
  '@bp3': {
    // width: '140px',
    maxWidth: '100%',
    marginRight: '12px'
  }
})

const LinkGeneratorForm: React.FC<Props> = ({
  urlEmpty,
  urlNotMatch,
  handler,
  type = 'linkGenerator',
  ready = false,
  noAction = false,
  brandId=0,
  error='',
  generatedLink='',
  parentPage='',
  isShort,
  handleSetLoading,
  setErrorMessages,
  isBuildALink=false,
  enabledDeepLink,
  deeplinkUrl,
  ...props
}) => {

  const handleToggleLoading = ():void => {
    handleSetLoading();
  }

  const handleError = ():void => {
    setErrorMessages("Failed.");
  }

  if (isBuildALink===true) {
    return (
      <div type={type} {...props} className="form-container">
        <FormContent urlEmpty={urlEmpty}
          urlNotMatch={urlNotMatch}
          handler={handler}
          type={type}
          ready={ready}
          noAction={noAction}
          brandId={brandId}
          error={error}
          generatedLink={generatedLink}
          parentPage={parentPage}
          isShort={isShort}
          handleSetLoading={handleSetLoading}
          setErrorMessages={setErrorMessages}
          isBuildALink={true}
          enabledDeepLink={enabledDeepLink}
        />
      </div>
    );
  }

  return (
    <>
      <Container type={type} {...props}>
        <FormContent urlEmpty={urlEmpty}
                     urlNotMatch={urlNotMatch}
                     handler={handler}
                     type={type}
                     ready={ready}
                     noAction={noAction}
                     brandId={brandId}
                     error={error}
                     generatedLink={generatedLink}
                     parentPage={parentPage}
                     isShort={isShort}
                     handleSetLoading={handleSetLoading}
                     setErrorMessages={setErrorMessages}
                     isBuildALink={false}
                     enabledDeepLink={enabledDeepLink}
                     deeplinkUrl={deeplinkUrl}
        />

      </Container>

      {
        !noAction && ready &&
          <OptionsUrl className={type==='linkGenerator' ? 'ThreePart' : null}>
            <TrackingUrlOutput value={generatedLink} parentPage={parentPage} />
            <Actions>
              <ShareActions dealID={brandId} toCopy={generatedLink} column={true} icon={false} label="COPY" parentPage={parentPage}
                            handleToggleLoading={handleToggleLoading}
                            setErrorMessages={handleError}
              />
            </Actions>
          </OptionsUrl>
      }
    </>
  )
}
export default LinkGeneratorForm
