import React from 'react'
import { styled } from './../../../theme/index'

interface Props {
  content: string
}

const Container = styled('div', {
  maxWidth: '100%',
  p: {
    color: '$black',
    fontFamily: '$body',
    fontSize: '$size16',
    letterSpacing: '-0.2px',
    lineHeight: '1.5625',
    margin: '0 0 10px'
  },
  a: {
    color: '$black',
    fontFamily: '$body',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: '#275153',
      textDecoration: 'underline'
    }
  },
  ul: {
    paddingLeft: '40px',
    marginBottom: '28px',
    marginTop: '26px'
  },
  ol: {
    marginTop: 0,
    marginBottom: '10px'
  },
  li: {
    color: '$black',
    fontFamily: '$body',
    fontSize: '$size16',
    letterSpacing: '-0.2px',
    lineHeight: 1.5
  },
  span: {
    fontSize: '$size16',
    fontFamily: '$body',
    letterSpacing: '-0.2px',
    lineHeight: 1.5
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    padding: '10px 10px 10px 0',
    verticalAlign: 'middle'
  },
  h1: {
    fontFamily: '$body',
    fontSize: '30px',
    marginTop: 0,
    marginBottom: '10px',
    fontWeight: '500',
    lineHeight: 1.1,
    color: '$darkgray'
  },
  h2: {
    padding: 0,
    fontFamily: '$body',
    fontSize: '$size17',
    color: '#595F7B',
    margin: '11px 0 18px 0',
    textTransform: 'uppercase',
    fontWeight: '500'
  },
  h3: {
    fontSize: '$size17',
    color: '$black',
    padding: '20px 0 17px',
    margin: '45px 0 18px',
    textTransform: 'uppercase',
    borderBottom: '1px #e4e4e4 dashed',
    fontFamily: '$body',
    fontWeight: '500'
  },
  h4: {
    margin: 0,
    color: '$black',
    fontFamily: '$postContent',
    fontSize: '$size18',
    fontWeight: '700',
    textTransform: 'uppercase',
    lineHeight: 1.1
  },
  h5: {
    fontFamily: '$body',
    fontSize: '$size16',
    fontWeight: '700',
    color: '$darkgray',
    padding: 0,
    marginTop: '30px',
    marginBottom: '15px'
  },
  h6: {
    fontSize: '$size14',
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: '500',
    lineHeight: 1.1
  },
  hr: {
    border: 0,
    borderTop: '1px solid #e4e4e4',
    height: 0,
    marginTop: '20px',
    marginBottom: '20px',
    boxSizing: 'content-box'
  },
  small: {
    fontSize: '87%'
  },
  blockquote: {
    border: 'none',
    padding: '10px 20px',
    margin: '0 0 20px',
    fontSize: '20px',
    maxWidth: '100%',
    p: {
      '@bp2': {
        fontSize: 'size24'
      },
      fontFamily: '$quote',
      fontSize: '30px',
      letterSpacing: '2.4px',
      lineHeight: 1.66,
      textAlign: 'center'
    }
  },
  code: {
    padding: '2px 4px',
    fontSize: '90%',
    color: '#c7254e',
    backgroundColor: '#f9f2f4',
    borderRadius: '2px'
  },
  dt: {
    fontFamily: '$postContent',
    fontSize: '$size14',
    fontWeight: '700',
    lineHeight: 1.428,
    color: '$black'
  },
  dd: {
    fontFamily: '$postContent',
    fontSize: '$size14',
    lineHeight: 1.428,
    color: '$black',
    marginLeft: 0
  },
  pre: {
    padding: '9.5px',
    margin: '0 0 10px',
    fontSize: '$size15',
    lineHeight: '1.42857143',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    color: '$darkgray',
    backgroundColor: '$clear3',
    border: '1px solid $gray3',
    borderRadius: '2px'
  },
  label: {
    display: 'inline-block',
    marginBottom: '5px',
    fontWeight: '700',
    fontSize: '$size16',
    letterSpacing: '-0.2px',
    lineHeight: 1.562,
    fontFamily: '$body'
  }
})

const PostContent: React.FC<Props> = ({ content }) => {
  return <Container dangerouslySetInnerHTML={{ __html: content }} />
}

export default PostContent
