// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import type { EventHandler } from 'react'
// import { twitterHandle } from '../../theme/options'

const centralizeY = (dialogHeight: number): number => {
  return (window.outerHeight - dialogHeight) / 2
}

const centralizeX = (dialogWidth: number): number => {
  return (window.outerWidth - dialogWidth) / 2
}

export interface OpenDialogType {
  baseUrl: string
  width: number
  height: number
}

interface useSharingLinksReturnType {
  shareOnTwitter: EventHandler<any>
  shareOnFacebook: EventHandler<any>
}

const useSharingLinks = (): useSharingLinksReturnType => {
  const shareOnFacebook = (newUrl): void => {
    // const baseUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
    const baseUrl = `https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3D${encodeURIComponent(newUrl)}%26amp%253Bsrc%3Dsdkpreparse&&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_US`

    openShareDialog({ baseUrl, width: 555, height: 745 })
  }

  const shareOnTwitter = (newUrl): void => {
    // const encodedTitle = encodeURI('BrandCycle Promotion')
    // const baseUrl = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${url}${
    // const baseUrl = `https://twitter.com/intent/post?text=${encodedTitle}&url=${encodeURIComponent(newUrl)}${
    //   '&via=' + twitterHandle
    // }`
    const baseUrl = `https://twitter.com/intent/post?text= - ${encodeURIComponent(newUrl)}`

    openShareDialog({ baseUrl, width: 650, height: 650 })
  }

  const openShareDialog = ({ baseUrl, width, height }: OpenDialogType): void => {
    const settings = `top=${centralizeY(height)},left=${centralizeX(
      width
    )},width=${width}px,height=${height}px`

    window.open(baseUrl, '_blank', settings)
  }

  return {
    shareOnTwitter,
    shareOnFacebook
  }
}

export default useSharingLinks
