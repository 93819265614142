// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useEffect, useState, useContext} from 'react'
import { UserContext } from '../../../context/UserContext';
import { styled } from './../../../theme/index'
import Text from './../../atoms/Text/Text'
import { Button } from '../../atoms/Button/Button'
import SubUser from './../../molecules/SubUser/SubUser'
import client from "../../api";
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import { useNavigate } from "react-router-dom";

const Container = styled('div', {
  display: 'block',
  maxWidth: '100%',
  position: 'relative'
})

const Title = styled(Text, {
  fontSize: '41px',
  fontFamily: '$label',
  color: '$darkgray',
  fontWeight: '$bold',
  lineHeight: 1.1
})

const AddUserButton = styled(Button, {
  width: '200px',
  display: 'block',
  marginBottom: '22px'
})

const FooterSubUser = styled('div', {
  marginTop: '22px',
  marginBottom: '40px'
})

const Permissions = styled('span', {
  display: 'block',
  fontFamily: '$label',
  fontSize: '$size16',
  lineHeight: 1.428,
  color: '$darkgray'
})

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  fontSize: '$size16'
});

const SubUsers: React.FC = ({ ...props }) => {
  const [data, setData] = useState([]);
  const [errorMessages, setErrorMessages] = useState('');
  const [changedStatus, setChangedStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      "Header": "First name",
      "accessor": "first_name"
    },
    {
      "Header": "Last name",
      "accessor": "last_name"
    },
    {
      "Header": "Username",
      "accessor": "username"
    },
    {
      "Header": "Email",
      "accessor": "email"
    },
    {
      "Header": "Permissions",
      "accessor": "role"
    },
    {
      "Header": "Actions",
      "accessor": "col6"
    }
  ];
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { userCredentials } = useContext(UserContext);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length>2 && username.length>2 && csrf_token.length>2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get("/api/subusers/get/list", {
        headers: { csrf_token },
        auth: { username, password }
      })
        .then((response) => {
          if (response.data.length>0) {
            const processedRows = response.data.map((row) => {
              return {
                "uid": row.uid,
                "first_name": row.first_name,
                "last_name": row.last_name,
                "username": row.username,
                "email": row.email,
                "role": row.role,
                "status": row.status,
                "col6": [
                  "#",
                  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  "/edit/"+row.uid
                ]
              };
            });
            setData(processedRows);
          }

          setLoading(false);
        })
        .catch(e => {
          setErrorMessages("Error while getting sub-users. Please try again later.");
          console.log("error", e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csrf_token, password, username, changedStatus]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleAddUserClick = () => {
    navigate("/subusers/add");
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChangedStatus = () => {
    setChangedStatus(!changedStatus);
  }

  return (
    <>
      <Container {...props}>
        <Title as="h1"> Users </Title>

        {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}

        <AddUserButton type="action" style={{ marginLeft: 'auto' }} onClick={handleAddUserClick}>
          ADD USER
        </AddUserButton>

        {loading && <LoadingOverlay style={{position: "absolute", zIndex: "100"}} />}

        <SubUser data={data} columns={columns} handleChangedStatus={handleChangedStatus}></SubUser>
        <FooterSubUser>
          <Permissions css={{ fontWeight: '$bold' }}>User permission levels</Permissions>
          <Permissions>Link Creator - Can create links</Permissions>
          <Permissions>Analyst - Can create links and view reports</Permissions>
        </FooterSubUser>
      </Container>
    </>
  )
}

export default SubUsers
