// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState, useContext } from 'react'
import {type ThemeProps} from './../../../theme/index'
import DateForm from './../../molecules/DateForm/DateForm'
import DataTables from './../../atoms/DataTables/DataTables'
import { Title, Border, Container } from './styles'
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import client from "../../api";
import {UserContext} from "../../../context/UserContext";
import moment from 'moment';
import ErrorMessage from "../../ErrorMessage";

interface Props extends ThemeProps {
  data?: object[]
}

const headers = {
  paymentBalance: [
    {
      Header: ' ',
      columns: [
        {
          Header: 'Affiliate Name',
          accessor: 'name',
          style: {
            width: `calc(100%/10)`
          }
        }
      ]
    },
    {
      Header: ' ',
      columns: [
        {
          Header: 'Affiliate ID',
          accessor: 'id',
          style: {
            width: `calc(100%/12)`
          }
        }
      ]
    },
    {
      Header: 'Pending',
      columns: [
        {
          Header: 'Sales',
          accessor: 'pending_sales'
        },
        {
          Header: 'Payout',
          accessor: 'pending_payout'
        }
      ]
    },
    {
      Header: 'Approved',
      columns: [
        {
          Header: 'Sales',
          accessor: 'approved_sales'
        },
        {
          Header: 'Payout',
          accessor: 'approved_payout'
        }
      ]
    },
    {
      Header: 'Paid',
      columns: [
        {
          Header: 'Sales',
          accessor: 'paid_sales'
        },
        {
          Header: 'Payout',
          accessor: 'paid_payout'
        }
      ]
    },
    {
      Header: 'Balance',
      columns: [
        {
          Header: 'Sales',
          accessor: 'balance_sales'
        },
        {
          Header: 'Payout',
          accessor: 'balance_payout'
        }
      ]
    }
  ]
}

const PaymentBalance: React.FC<Props> = ({ ...props }) => {
  const {userCredentials} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState('');
  // filter fields
  const [dateFilter, setDateFilter] = useState("7-days-past");
  const [fromDate, setFromDate] = useState(moment().subtract(6, 'd').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [affiliate, setAffiliate] = useState("all");
  // filter lists
  const [affiliatesList, setAffiliatesList] = useState([{ value: "", label: "-- All --" }]);
  // table data
  const [dataTable, setDataTable] = useState([]);


  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {password, username, csrf_token} = userCredentials;
  useEffect(() => {
    setLoading(true);

    getUsersList();
    callApi(dateFilter, fromDate, toDate, false, affiliate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  const getUsersList = ():void => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get("/api/users/get?_format=json", {
        headers: {"Content-type": "application/x-www-form-urlencoded", csrf_token},
        auth: {username, password}
      })
        .then(({data}) => {
            if (data.data?.length > 0) {
              const tempAffiliateList = data.data.map((item) => {
                return {value: item, label: item}
              });

              setAffiliatesList([...affiliatesList, ...tempAffiliateList]);
            }

        })
        .catch(e => {
          setErrorMessages("Failed to get the Users list.");
          console.log("Failed to get the Users list.", e);
        });
    }
  }

  const callApi = (dateFilter, fromDate, toDate, exportType=false, affiliate):void => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.post("/api/reports?_format=json", {
        type: "payment_balance_report",
        dateFilter,
        fromDate,
        toDate,
        export_type: exportType ? "file" : "",
        users: affiliate
      }, {
        headers: {"Content-type": "application/x-www-form-urlencoded", csrf_token},
        auth: {username, password}
      })
        .then(({data}) => {
          if (exportType) {
            // 2. download the file
            const element = document.createElement("a");
            const file = new Blob([data.data], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = data.fileName;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
          }
          else {
            setDataTable(data.data);
          }

          setLoading(false);
        })
        .catch(e => {
          setErrorMessages("Failed to get data for the report.");
          console.log("Error Payment Balance report, getting the report", e);
        });
    }
  }

  const handleFilterChange = (newValues): void => {
    setLoading(true);

    setDateFilter(newValues.range.value);
    setFromDate(moment(newValues.startDate).format('YYYY-MM-DD'));
    setToDate(moment(newValues.endDate).format('YYYY-MM-DD'));
    setAffiliate(newValues.affiliates.value);

    callApi(newValues.range.value,
      moment(newValues.startDate).format('YYYY-MM-DD'),
      moment(newValues.endDate).format('YYYY-MM-DD'),
      false,
      newValues.affiliates.value
    );
  }

  const handleExportFile = ():void => {
    setLoading(true);

    callApi(dateFilter, fromDate, toDate, true, affiliate);
  }

  return (
    <Container {...props}>

      {loading && <LoadingOverlay/>}

      <Title>Payment Balance Report</Title>

      {errorMessages !== '' ? <ErrorMessage>{errorMessages}</ErrorMessage> : ''}

      <DateForm affiliates={affiliatesList} type="paymentBalance" callback={handleFilterChange} exportFile={handleExportFile} />
      <Border>
        <DataTables
          css={{ marginTop: '0' }}
          variant="paymentBalance"
          search
          data={dataTable}
          columns={headers.paymentBalance}
        />
      </Border>
    </Container>
  )
}

export default PaymentBalance
