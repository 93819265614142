// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState, useContext } from 'react'
import {type ThemeProps} from './../../../theme/index'
import DateForm from './../../molecules/DateForm/DateForm'
import DataTables from './../../atoms/DataTables/DataTables'
import GraphHeader, { type HeaderValues } from './../../atoms/GraphHeader/GraphHeader'
import { Title, Border, Container } from './styles'
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import {UserContext} from "../../../context/UserContext";
import client from "../../api";
import moment from 'moment';
import ErrorMessage from "../../ErrorMessage";
import getSubUsersCall from "../../../hook/getSubUsersCall";

interface Props extends ThemeProps {
  data?: object[]
}

const BrandsContent: React.FC<Props> = ({ ...props }) => {
  const {userCredentials} = useContext(UserContext);
  const role = window.localStorage.getItem("level")!==undefined ? window.localStorage.getItem("level") : "affiliate";
  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState('');
  // filter fields
  const [dateFilter, setDateFilter] = useState("7-days-past");
  const [startDate, setStartDate] = useState(moment().subtract(6, 'd').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [resolution, setResolution] = useState("day");
  const [affiliates, setAffiliates] = useState([]);
  const [selectedUser, setSelectedUser] = useState('All');

  // top graph data
  const [clicks, setClicks] = useState(0);
  const [sales, setSales] = useState(0);
  const [payouts, setPayouts] = useState(0);
  const [lockedSales, setLockedSales] = useState(0);
  const [lockedPayouts, setLockedPayouts] = useState(0);
  const [dataTables, setDataTables] = useState([{}]);

  const headerObj: HeaderValues = {
    clicks: Math.floor(Number(clicks)),
    sales: Number(sales),
    payouts: Number(payouts),
    lockedSales: Number(lockedSales),
    lockedPayouts: Number(lockedPayouts)
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {password, username, csrf_token} = userCredentials;

  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      setLoading(true);

      // getSubUsersList();
      getSubUsersCall(password, username, csrf_token, setAffiliates, setErrorMessages);
      callApi(dateFilter, startDate, endDate, resolution, selectedUser, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username])

  // const getSubUsersList = ():void => {
  //   if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
  //     void client.get("/api/subusers/get/list/all?_format=json", {
  //       headers: {"Content-type": "application/x-www-form-urlencoded", csrf_token},
  //       auth: {username, password}
  //     })
  //       .then(({ data }) => {
  //         if (data?.length > 0) {
  //           const tempSubUsersList = data.map((item) => {
  //             return {value: item, label: item}
  //           });
  //
  //           setAffiliates([...tempSubUsersList]);
  //         }
  //       })
  //       .catch(e => {
  //         setErrorMessages("Failed to get the Users list.");
  //         console.log("Error summary report, getting the report", e);
  //       });
  //   }
  // }
  
  const handleFilterChange = (newValues): void => {
    setLoading(true);

    setErrorMessages("");
    setDateFilter(newValues.range.value);
    setStartDate(moment(newValues.startDate).format('YYYY-MM-DD'));
    setEndDate(moment(newValues.endDate).format('YYYY-MM-DD'));
    setResolution(newValues.filter.value);

    let users = 'All';
    if (newValues.affiliates!==undefined) {
      users = newValues.affiliates.label;
    }
    setSelectedUser(users);

    callApi(
      newValues.range.value,
      moment(newValues.startDate).format('YYYY-MM-DD'),
      moment(newValues.endDate).format('YYYY-MM-DD'),
      newValues.filter.value,
      users,
      ""
    );
  }

  const callApi = (dateFilter, fromDate, toDate, resolution, users, exportType=false):void => {
    setErrorMessages("");

    let newData = null;

    if (role!=="account_manager" && role!=="admin") {
      newData = {
        type: "brands_report",
        dateFilter,
        fromDate,
        toDate,
        resolution,
        users,
        export_type: exportType
      }
    }
    else {
      newData = {
        type: "brands_report",
        dateFilter,
        fromDate,
        toDate,
        resolution
      }
    }

    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.post("/api/reports?_format=json", newData, {
        headers: {"Content-type": "application/x-www-form-urlencoded", csrf_token},
        auth: {username, password}
      })
        .then(({data}) => {
          if (exportType) {
            // 2. download the file
            const element = document.createElement("a");
            const file = new Blob([data.data], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = data.fileName;
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
          }
          else {
            // graph header
            setClicks(data.data.totals.clicks);
            setSales(data.data.totals.sales);
            setPayouts(data.data.totals.payouts);
            setLockedSales(data.data.totals.lockedSales);
            setLockedPayouts(data.data.totals.lockedPayouts);
            setDataTables(data.data.tableData);
          }

          setLoading(false);
        })
        .catch(e => {
          console.log("Error on getting Brands report", e);
          if (e.message.indexOf("Your time frame must")===-1) {
            setErrorMessages(e.response.data.data ?? e.message);
          }
          setLoading(false);
        });
    }
  }

  const handleExportFile = ():void => {
    setLoading(true);

    callApi(
      dateFilter, startDate, endDate, resolution, selectedUser, "file"
    );
  }

  return (
    <Container {...props}>

      {loading && <LoadingOverlay/>}

      <Title>Brands Report</Title>

      {errorMessages !== '' ? <ErrorMessage>{errorMessages}</ErrorMessage> : ''}

      <DateForm type="brands" affiliates={affiliates} callback={handleFilterChange} exportFile={handleExportFile} />
      <Border>
        <GraphHeader data={headerObj} variant="brands" />
        <DataTables variant="brands" data={dataTables} />
      </Border>
    </Container>
  )
}

export default BrandsContent
