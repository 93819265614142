import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import BrandCollectionItem, { type Item } from './../../atoms/BrandCollectionItem/BrandCollectionItem'

export interface BrandProps {
  options: Item[]
  title: string
}

export interface Props extends ThemeProps {
  brands: BrandProps[]
  indexItem: string
}

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  maxWidth: '100%',
  '@bp1': {
    gridTemplateColumns: '1fr 1fr'
  },
  '@media(min-width: 1200px)': {
    gridTemplateColumns: '1fr',
    maxWidth: '100%'
  }
})

const Row = styled('div', {
  borderBottom: '1px solid $lightgray2',
  width: '100%',
  padding: '12px',
  paddingBottom: '23px',
  '@bp1': {
    padding: '12px 18px 23px 0'
  },
  '@bp3': {
    padding: '0 0 23px'
  }
})

const HeadingTitle = styled('h2', {
  marginTop: '27px',
  fontSize: '44px',
  lineHeight: 1,
  fontFamily: '$quote',
  color: '$darkgray',
  padding: 0,
  borderBottom: 0,
  marginBottom: '12px',
  fontWeight: '500'
})

const ListWrapper = styled('ul', {
  margin: 0,
  padding: 0,
  display: 'grid',
  gridTemplateColumns: '1fr',
  '@media(min-width: 1200px)': {
    gridTemplateColumns: '1fr 1fr 1fr'
  }
})

const BrandsCollection: React.FC<Props> = ({ brands, indexItem, ...Props }) => {
  return (
    <Container {...Props}>
      {brands.map((value, i) => {
        if (indexItem !== 'All' && indexItem !== value.title) {
          return null
        }

        return (
          <Row key={`brandCollection-${value.title}-${i}`}>
            <HeadingTitle>{value?.title ?? ''}</HeadingTitle>
            <ListWrapper>
              {value.options.map((item, i) => (
                <BrandCollectionItem item={item} key={`userDetail-${item.name}-${i}`} />
              ))}
            </ListWrapper>
          </Row>
        )
      })}
    </Container>
  )
}

export default BrandsCollection
