// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useContext, useState } from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import { Button } from '../../atoms/Button/Button'
import DataTables from './../../atoms/DataTables/DataTables'
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import { UserContext } from '../../../context/UserContext';
import client from "../../api";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../ErrorMessage";

interface Props extends ThemeProps {
  data?: any[]
  columns?: any[]
  search?: boolean
}

const Container = styled('div', {
  display: 'flex',
  maxWidth: '100%',
  paddingLeft: 0,
  paddingRight: 0,
  alignItems: 'center'
})

const ButtonsCell = styled('div', {
  display: 'inline-flex',
  'button:first-of-type': {
    marginRight: '10px'
  }
})

const styleSubUser = {
  padding: 0
}

const SubUser: React.FC<Props> = ({ data, columns, search, handleChangedStatus, ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  data = React.useMemo(() => data, [data]) ?? []
  columns = React.useMemo(() => columns, [columns]) ?? []

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  const handleStatusChange = (uid):void => {
    setLoading(true);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    if (password.length>2 && username.length>2 && csrf_token.length>2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get(`/api/subusers/update_status/${uid}?_format=json`, {
        headers: { csrf_token },
        auth: { username, password }
      })
        .then((response) => {
          if (response.status===202) {
            handleChangedStatus(true);
            setLoading(false);
          }
        })
        .catch(e => {
          console.log("error", e);
          setErrorMessages(true);
        });
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleEditSubUser = (uid) => {
    navigate(`/subusers/edit/${uid}`);
  }

  if (data.length>0) {
    data = data.map((e, key) => ({
      ...e,
      ...{
        col6: (
          <ButtonsCell>
            <Button type="action" href={e[0]} css={{ textTransform: 'capitalize' }} onClick={() => {handleStatusChange(data[key].uid)}} >
              {data[key].status===false ? 'Activate' : 'Deactivate' }
            </Button>
            <Button type="action" href={e[1]} css={{ textTransform: 'capitalize' }} onClick={() => {handleEditSubUser(data[key].uid)}} >
              Edit
            </Button>
          </ButtonsCell>
        )
      }
    }))
  }

  return (
    <Container {...props}>

      { errorMessages && <ErrorMessage contactSupport={true} /> }

      {loading && <div style={{flex: "0 0 100%!important", position: "absolute", zIndex: "10", width:"100%"}}>
        <LoadingOverlay />
      </div>}

      <DataTables data={data} columns={columns} css={styleSubUser} showPagination={false} style={{width:"100%"}} />
    </Container>
  )
}

export default SubUser
