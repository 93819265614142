// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'
import { styled } from './../../../theme/index'
import BlogSnippet, { type Props as BlogSnippetProps } from './../../molecules/BlogSnippet/BlogSnippet'

export interface Props {
  children?: React.ReactNode
  collection?: BlogSnippetProps[]
  type?: 'postBlogListGrid' | 'featBlogListGrid'
}

const Grid = styled('div', {
  display: 'grid',
  gridGap: '36px 22px',
  width: '100%',
  justifyContent: 'center',
  '@bp3': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    justifyContent: 'normal',
    gridGap: '36px 30px',
    width: '95%',
  },
  '@bp4': {
    gridGap: '36px 32px',
    width: '100%'
  },
  variants: {
    type: {
      featBlogListGrid: {
        display: 'grid',
        gridGap: '36px 28px',
        width: '100%',
        justifyContent: 'center',
        '@bp3': {
          gridGap: '36px 22px',
          width: '100%',
          gridTemplateColumns: 'repeat(3, 1fr)',
          justifyContent: 'normal'
        },
        '@bp4': {
          gridGap: '36px 28px',
          width: '95%'
        }
      },
      postBlogListGrid: {
        display: 'grid',
        gridGap: '20px 32px',
        width: '100%',
        '@bp3': {
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: '36px 21px',
          width: '95%'
        },
        '@bp4': {
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: '36px 27px',
          width: '95%'
        }
      }
    }
  }
})

const BlogList: React.FC<Props> = ({collection, children,
                                     type = 'featBlogListGrid' }) => {
  return (
    <Grid type={type}>
      {collection?.length>0
        ? collection.map((cardProps, key) => (
            <BlogSnippet key={`blog-snippet-element-${key}`} {...cardProps} type={type} />
          ))
        : ''}
      {children}
    </Grid>
  )
}

export default BlogList
