import React, { useState } from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import HomepageTracking from './../../molecules/HomepageTracking/HomepageTracking'
import Text from './../../atoms/Text/Text'
import Image from './../../atoms/Image/Image'
import PopUp from './../../atoms/PopUp/PopUp'

export interface Info {
  url: string
  logo: string
  commission: string
  length: string
  description: string
  excludeDetails: string
  popupContent: string
  deeplinkUrl: string
}

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  info: Info
}

const Div = styled('div')

const InformationWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  maxWidth: '100%',
  width: '100%',
  '@bp3': {
    gridTemplateColumns: '1fr 40.5%'
  }
})

const Information = styled('div', {
  '@bp3': {
    paddingRight: '24px'
  }
})

const TrackingBox = styled('div', {
  height: 'max-content',
  textAlign: 'center',
  paddingTop: 0,
  paddingBottom: '28px',
  '@bp3': {
    paddingTop: '20px',
    paddingBottom: 0
  }
})

const ImageArea = styled(Image, {
  margin: '0 auto',
  width: '160px',
  '@bp3': {
    margin: '0 0 14px'
  }
})

const PopButton = styled('a', {
  display: 'block',
  fontFamily: '$body',
  textTransform: 'capitalize',
  textDecoration: 'underline',
  color: '$black',
  fontWeight: '$regular',
  fontSize: '16px',
  letterSpacing: '0.3px',
  lineHeight: 1,
  textAlign: 'center',
  cursor: 'pointer',
  width: 'max-content',
  margin: '78px auto 30px',
  '@bp3': {
    margin: '90px auto 0'
  }
})

const BrandLink = styled('a', {
  display: 'block',
  fontFamily: '$body',
  textDecoration: 'none',
  color: '$black',
  fontSize: '17px',
  fontWeight: '$bold',
  lineHeight: 1.176,
  letterSpacing: '-0.2px',
  marginBottom: '17px',
  '&:hover': {
    textDecoration: 'underline',
    color: '#275153'
  }
})

const Description = styled('div', {
  '&>div': {
    fontSize: '$size16',
    lineHeight: 1.562,
    letterSpacing: '-0.2px',
    fontFamily: '$body',
    p: {
      margin: '0 0 6px'
    }
  }
})

const Commission = styled(Text, {
  margin: '-4px 0 25px',
  fontSize: '$size12',
  lineHeight: 1.562,
  letterSpacing: '-0.2px',
  fontFamily: '$body',
  span: {
    fontWeight: '$bold',
    marginRight: '4px'
  },
  '@bp3': {
    margin: '-4px 0 41px',
    maxWidth: '100%',
    fontSize: '$size16'
  }
})

const PopUpHeading = styled('div', {
  background: '$clear',
  fontSize: '$size16',
  fontFamily: '$label',
  fontWeight: '$bold',
  textAlign: 'left',
  padding: '15px',
  borderBottom: '1px solid #e5e5e5'
})

const PopUpContent = styled('div', {
  background: '$clear',
  padding: '15px',
  textAlign: 'left',
  fontFamily: '$body',
  maxHeight: 'calc(80vh - 55px)',
  overflowY: 'auto',
  br: {
    display: 'none'
  },
  p: {
    margin: '0 0 6px',
    color: '$black',
    fontFamily: '$body',
    fontSize: '16px',
    letterSpacing: '-0.2px',
    lineHeight: '25px'
  },
  '@bp2': {
    padding: '15px 27px'
  }
})

const BrandHeader: React.FC<Props> = ({ label, brandId, info, handler, bmkFlag=false,
                                        showLoader, hideLoader, handleHomepageUrlError, ...props }) => {
  const { logo, url, description, excludeDetails, commission,
    length, popupContent, deeplinkUrl } = info
  const [popUpActive, setPopUpActive] = useState(false)

  const popUpHandler = (): void => {
    setPopUpActive(!popUpActive)
  }

  const handleShowLoader = ():void => {
    showLoader(true);
  }
  const handleHideLoader = ():void => {
    hideLoader(false);
  }
  const handleErrorGetHomepageURL = ():void => {
    handleHomepageUrlError();
  }

  return (
    <Div {...props}>
      <InformationWrap>
        <Information>
          {
            bmkFlag===true && <h1>{label}</h1>
          }

          <ImageArea src={logo} />
          <Div>
            {
              bmkFlag===false && (
                <>
                  <BrandLink href={url === undefined ? "" : (url.includes('http') ? url : `https://${url}`)}
                             target="_blank" rel="noopener noreferrer" >{url}</BrandLink>

                  <Description>
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </Description>

                  <Commission>
                    {excludeDetails !== undefined && <span>Commission excludes:</span>}
                    {excludeDetails}
                  </Commission>
                </>
              )
            }
          </Div>
        </Information>

        <TrackingBox>
          <HomepageTracking brandId={brandId} commission={commission}
                            length={length} url={deeplinkUrl} bmkFlag={bmkFlag}
                            handleShowLoader={handleShowLoader} handleHideLoader={handleHideLoader}
                            handleErrorGetHomepageURL={handleErrorGetHomepageURL}
          />

          <PopUp isShown={popUpActive} handler={popUpHandler} closeCss={{ top: '14px' }}>
            <PopUpHeading>Terms & Conditions</PopUpHeading>
            <PopUpContent dangerouslySetInnerHTML={{ __html: popupContent }} />
          </PopUp>
          <PopButton onClick={popUpHandler}>View Terms & Conditions</PopButton>
        </TrackingBox>
      </InformationWrap>
    </Div>
  )
}

export default BrandHeader
