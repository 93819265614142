import React from 'react'
import { styled } from './../../../theme/index'

interface Props {
  canPreviousPage: boolean
  canNextPage: boolean
  pageIndex: number
  pageSize: number
  previousPage: () => void
  setPageSize: (number: number) => void
  nextPage: () => void
}

const Container = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'end'
})

const Counter = styled('div', {
  display: 'inline-flex'
})

const Button = styled('button', {
  background: 'transparent',
  border: 'none',
  minWidth: '1.5em',
  marginLeft: '2px',
  textAlign: 'center',
  textDecoration: 'none',
  '&:not([disabled])': {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
      color: '#666666'
    }
  },
  '@bp0': {
    padding: '0.5em 1em'
  }
})

const text = {
  color: '$clear',
  padding: 0,
  fontWeight: '$regular',
  fontFamily: '$heading',
  letterSpacing: '.8px',
  fontSize: '$size15',
  lineHeight: 1.8,
  backgroundColor: '$blue5',
  '@bp0': {
    padding: '6px 22px'
  }
}

const Box = styled('span', {
  ...text,
  padding: '0 20px',
  margin: '0 2px',
  fontFamily: '$body',
  lineHeight: '2.5'
})

const Select = styled('select', {
  ...text,
  height: '40px',
  marginLeft: '5px',
  '-moz-appearance': 'none',
  '-webkit-appearance': 'none',
  cursor: 'pointer',
  option: {
    color: '$black',
    backgroundColor: '$clear'
  },
  padding: '8px 17px'
})

const Selector = styled('div', {
  fontSize: '16px',
  fontWeight: 'bold',
  fontFamily: '$label',
  display: 'inline-flex',
  alignSelf: 'flex-end',
  alignItems: 'center',
  marginTop: '3px',
  marginBottom: '5px',
  marginRight: '3.5px',
  input: { marginLeft: '7.5px' },
  '@bp0': {
    marginRight: '7.5px'
  }
})
const Pagination: React.FC<Props> = ({
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageIndex,
  pageSize,
  setPageSize
}) => {
  return (
    <Container>
      <Selector>
        <span>Show</span>
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number((e.target as HTMLSelectElement).value))
          }}
        >
          {[10, 20, 30, 40, 50, 9999999].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize === 9999999 ? 'All' : pageSize}
            </option>
          ))}
        </Select>
      </Selector>
      <Counter>
        <Button onClick={previousPage} disabled={!canPreviousPage}>
          {'Previous'}
        </Button>
        <Box>{Number(pageIndex) + 1}</Box>
        <Button onClick={nextPage} disabled={!canNextPage}>
          {'Next'}
        </Button>
      </Counter>
    </Container>
  )
}

export default Pagination
