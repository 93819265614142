// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useEffect, useState, useContext} from 'react';
import { UserContext } from '../../../context/UserContext';
import { styled } from '../../../theme/index'
import SnapshotReport from '../../organisms/SnapshotReport/SnapshotReport'
import DeepLinkForm from '../../organisms/DeepLinkForm/DeepLinkForm'
import BlogList from '../../molecules/BlogList/BlogList'
import SocialSection from '../../organisms/SocialSection/SocialSection'
import client from "../../api";
import ErrorMessage from "../../ErrorMessage";
import { FullStory, init as initFullStory } from '@fullstory/browser';
// import { styled, type ThemeProps } from '../../../theme/index'
// import { type reportData } from '../../molecules/DeepLinkReport/DeepLinkReport'
// import { type Props as BlogSnippetProps } from '../../molecules/BlogSnippet/BlogSnippet'

// interface Props extends ThemeProps {
//   // report: reportData[]
//   // blogList: BlogSnippetProps[]
//   // announceList: BlogSnippetProps[]
//   // postBlogListGrid?: ListProps
//   // featBlogListGrid?: ListProps
//   // homeSnapShot?: snapProps
// }

initFullStory({ orgId: 'o-1NCD85-na1' });
FullStory('setProperties', {
  type: 'page',
  properties: {
    pageName: 'BC-net Home',
  }
});

const Container = styled('div', {
  padding: '11px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@bp3': {
    padding: 0
  }
})

const Top = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  marginBottom: '31px',
  width: '100%',
  maxWidth: '706px',
  '@bp3': {
    width: '100%',
    maxWidth: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end'
  }
})

const DeepWrap = styled('div', {
  marginLeft: 0,
  '@bp3': {
    width: '100%',
    maxWidth: '488px',
    marginLeft: '5%'
  }
})

const Wrapper = styled('div', {
  width: '100%',
  marginTop: '6px',
  marginBottom: '36px'
})

const SocialWrap = styled('div', {
  Width: '100%',
  maxWidth: '254px',
  margin: '0 auto 50px'
})

const BlogWrapper = styled('div', {
  width: '100%',
  marginBottom: 0
})

const PanelFeatTitle = styled('h2', {
  fontFamily: '$heading',
  fontSize: '19px',
  fontWeight: '$bold',
  letterSpacing: '1px',
  lineHeight: 1,
  color: '$black2',
  marginRight: '15px',
  padding: '3px 0 9px',
  textAlign: 'center'
})

const PanelBlogTitle = styled('h2', {
  fontFamily: '$heading',
  fontSize: '19px',
  fontWeight: '$bold',
  letterSpacing: '1px',
  lineHeight: 1,
  color: '$black2',
  marginLeft: '12px',
  paddingBottom: '9px',
  textAlign: 'center'
})

export const Home: React.FC = ({ sendData }) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // const {password, username, csrf_token} = JSON.parse(window.localStorage.getItem("user_credit"));
  const [showReport, setShowReport] = useState(false);
  const [announceList, setAnnounceList] = useState([]);
  const [blogList, setBlogList] = useState([]);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length>2 && username.length>2 && csrf_token.length>2) {
      const fn = (a: string, b: string):string => a + ':' + b;
      const basicAuth = btoa(fn(username, password));
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get("/api/homepage/get?_format=json", {
        headers: { csrf_token, Authorization: 'Basic '+basicAuth }
        // auth: { username, password }
      })
        .then((response) => {
          setShowReport(response.data.show_report);
          setAnnounceList(response.data.announcements);
          setBlogList(response.data.blogs);

          // setting 'menus' to storage
          if (response.data.menus!==undefined) {
            window.localStorage.setItem("menus", JSON.stringify(response.data.menus));
            sendData(response.data.menus);
          }

          // setting 'level' to storage
          if (window.localStorage.getItem('level')===null) {
            window.localStorage.setItem("level", response.data.level);
          }
        })
        .catch(e => {
          console.log("Error get data for homepage:", e);
          setErrorMessages(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csrf_token]);

  return (
    <>
      <Container>

        { errorMessages && <ErrorMessage contactSupport={true} /> }

        <Top>
          {showReport ? <SnapshotReport homeSnapShot /> : null} {/* done 2023-05-24 */}

          <DeepWrap>
            <DeepLinkForm isHomePage={true} />
          </DeepWrap>
        </Top>

        <Wrapper>
          <PanelFeatTitle>FEATURED ANNOUNCEMENTS</PanelFeatTitle>
          <BlogList collection={announceList} type="featuredAnnouncements" />
        </Wrapper>

        <SocialWrap>
          <SocialSection />
        </SocialWrap>

        <BlogWrapper>
          <PanelBlogTitle>FROM THE BLOG</PanelBlogTitle>
          <BlogList collection={blogList} type="postBlogListGrid" />
        </BlogWrapper>
      </Container>
    </>
  )
}

export default Home;
