import React from 'react'
import Card from './Card'
import PopUp from './../../atoms/PopUp/PopUp'
import { usePopup } from './../../../state/PopupProvider'

const PopupReferral: React.FC = () => {
  const { state, dispatch } = usePopup()
  const { show, code, link } = state
  const handler = (): void => {
    dispatch({ type: 'TOGGLE_POPUP' })
  }

  return <PopUp isShown={show} handler={handler} card={<Card code={code} link={link} />} />
}

export default PopupReferral
