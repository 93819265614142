// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'
import { styled } from "../theme/index";
import { Link } from "react-router-dom";

const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  fontSize: '$size16'
});

const ErrorMessage = ({ contactSupport=false, message }):Element => {
  if (contactSupport) {
    return (
      <Error>Something went wrong, please try again later or <Link to="/contact-support">contact support</Link></Error>
    )
  }

  return (
    <Error>{message}</Error>
  )
}

export default ErrorMessage;