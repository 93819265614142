// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { styled, type ThemeProps } from './../../../theme/index'
import { Button } from '../../atoms/Button/Button';
import client from "../../api";
import ErrorMessage from "../../ErrorMessage";
// import {UserContext} from "../../../context/UserContext";

interface Props extends ThemeProps {
  handler?: ()=>void
}

const Container = styled('div')

const Title = styled('h2', {
  fontSize: '36px',
  marginTop: 0,
  fontFamily: '$label',
  marginBottom: '28px',
  padding: 0,
  color: '$darkgray'
})

const Paragraph = styled('p', {
  fontSize: '$size16',
  fontFamily: '$label',
  lineHeight: 1.312,
  letterSpacing: '0.3px',
  marginBottom: '30px'
})

const ButtonSection = styled('div', {
  display: 'flex'
})

const buttonStyles = {
  color: '$clear',
  textTransform: 'none',
  fontFamily: '$heading',
  fontWeight: '$bold',
  lineHeight: 1,
  letterSpacing: '0.8px',
  width: 'auto'
}

const CancelButton = styled(Button, {
  ...buttonStyles,
  padding: '15px 22px 14px',
  backgroundColor: '#595F7B',
  '&:hover': {
    backgroundColor: '#484d66'
  }
})

const SaveButton = styled(Button, {
  ...buttonStyles,
  padding: '15px 34px 14px',
  backgroundColor: '#808080',
  marginLeft: '5px',
})

const CancelAccount: React.FC<Props> = () => {
  // const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const navigate = useNavigate();

  // // Access the user credentials
  // // eslint-disable-next-line @typescript-eslint/naming-convention
  // const { password, username, csrf_token } = userCredentials;

  const clickHandler = (): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { username, password, csrf_token} = JSON.parse(window.localStorage.getItem("user_credit"));

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    void client.get("/api/user/cancel?_format=json", {
      headers: { csrf_token },
      auth: { username, password }
    })
      .then(() => {
        window.localStorage.removeItem("level");
        window.localStorage.removeItem("menus");
        window.localStorage.removeItem("user_credit");
        window.localStorage.removeItem("userInfo");

        window.location.href = '/';
      })
      .catch(e => {
        console.log("error", e);
        setErrorMessages(true);
      });
  }

  return (
    <Container>

      { errorMessages && <ErrorMessage contactSupport={true} /> }

      <Title>Are you sure you want to cancel your account?</Title>

      <Paragraph>
        If you choose to cancel your account, you will receive an email to complete the
        cancellation. Once your account is canceled you will no longer have access to it.
      </Paragraph>

      <ButtonSection>
        <CancelButton onClick={clickHandler}>Cancel account</CancelButton>
        <SaveButton onClick={() => {
          navigate("/user/edit");
        }}>Go Back</SaveButton>
      </ButtonSection>

    </Container>
  )
}

export default CancelAccount
