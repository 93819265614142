// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState } from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import { type MenuItem, siteUrl } from './../../../theme/menuLinks'
import {Link} from "react-router-dom";
// import Link from './../../atoms/Link/Link'
import Logo from './../../atoms/Logo/Logo'
import AnimatedToggle from './../../atoms/AnimatedToggle/AnimatedToggle'
import SideMenu from './../../molecules/SideMenu/SideMenu'
import AccordionProvider from './../../../state/AccordionProvider'
import logoWhite from './../../../images/logo_brandcicle_white.png'
import patternBackground from './../../../images/left-pattern.png'

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  items: MenuItem[]
  responsiveItems: MenuItem[]
}

const Container = styled('div', {
  position: 'relative',
  zIndex: 2,
  '.hidden': {
    opacity: 0,
    display: 'none',
    visibility: 'hidden'
  }
})

const Desktop = styled('div', {
  width: '260px',
  height: 'calc(100vh+1px)',
  display: 'none',
  position: 'sticky',
  left: 0,
  top: 0,
  '&:after': {
    content: ' ',
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg,#2F3034 72%,rgba(47,48,52,0) 100%)'
  },
  '&:before': {
    content: ' ',
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    background: `url(${patternBackground as unknown as string})`,
    backgroundColor: '#2F3034',
    backgroundRepeat: 'repeat-y',
    backgroundSize: '100%'
  },
  '@bp3': {
    display: 'block'
  }
})

const DesktopWrapper = styled('div', {
  padding: '23px 0 260px',
  position: 'relative',
  zIndex: 1
})

const LogoLink = styled('div', {
  display: 'block',
  width: '100%',
  marginLeft: '30px',
  a: {
    display: 'block',
    width: '144px',
    marginBottom: '24px'
  }
})

const Mobile = styled('div', {
  display: 'block',
  minHeight: '100%',
  minWidth: '100%',
  position: 'relative',
  '@bp3': {
    display: 'none'
  }
})

const MobileHeader = styled('div', {
  background: '#2F3034',
  texAlign: 'center'
})

const MobileWrap = styled('div', {
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '65px',
  maxWidth: '744px',
  margin: '0 auto'
})

const Absolute = styled('div', {
  opacity: 1,
  'max-height': '647px',
  position: 'absolute',
  width: '100%',
  top: '65px',
  left: 0
})

const toggle = {
  position: 'absolute',
  top: '19px',
  left: '22px'
}

const SideDashboard: React.FC<Props> = ({ items=[], responsiveItems, children,
                                          bmk, ...props }) => {
  const [isActive, setIsActive] = useState(false)
  const target = bmk===true ? "_blank" : "";

  return (
    <Container {...props} style={{zIndex:'10'}}>
      <Desktop>
        <DesktopWrapper>
          <LogoLink>
            <Link to={siteUrl} target={target}>
              <Logo aspectRatio={0.61} src={logoWhite} />
            </Link>
          </LogoLink>

          <SideMenu type="sideDashboardDesktop" navCollection={items} />
        </DesktopWrapper>
      </Desktop>

      <Mobile>
        <MobileHeader>
          <MobileWrap>
            <AnimatedToggle
              callback={() => {
                setIsActive(!isActive)
              }}
              isOpen={isActive}
              css={toggle}
              type="admin"
            />
            <a href={siteUrl}>
              <Logo
                aspectRatio={0.61}
                style={{ marginTop: '5px', width: '90px' }}
                src={logoWhite}
              />
            </a>
          </MobileWrap>
        </MobileHeader>
        <Absolute>
          <AccordionProvider state={isActive}>
            <SideMenu type="sideDashboardDesktop" navCollection={items} closeSubMenuToggle revertPosition />
            <SideMenu type="sideDashboardMobile" sendClose={setIsActive} navCollection={responsiveItems} />
          </AccordionProvider>
        </Absolute>
      </Mobile>
    </Container>
  )
}

export default SideDashboard
