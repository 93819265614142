// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'

const Icon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.609">
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <path d="M1 9.643v11.966h18V9.643l-9-8.284Z" />
        <path d="M7 13.509h6v8.1H7z" />
      </g>
    </svg>
  )
}

export default Icon
