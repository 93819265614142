import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import Image from './../../atoms/Image/Image'

interface LogoProps {
  img: string
  href?: string
}

export interface Props extends ThemeProps {
  title?: string
  logos: LogoProps[]
}

const Container = styled('div', {
  display: 'inline-block',
  padding: 0,
  marginBottom: 0,
  verticalAlign: 'middle',
  width: '100%',
  '@bp3': {
    marginBottom: 0,
    padding: 0,
    width: '100%'
  }
})

const Title = styled('span', {
  display: 'block',
  fontSize: '22px',
  fontFamily: '$deco',
  fontWeight: '$bold',
  letterSpacing: '0.93px',
  lineHeight: 1.136,
  textTransform: 'none',
  marginBottom: '5px',
  textAlign: 'left',
  borderRadius: 0,
  color: '$blue3',
  border: 'none',
  padding: 0,
  '@bp3': {
    textAlign: 'center',
    paddingBottom: '9px',
    lineHeight: 1.107,
    borderBottom: '3px solid #D2D4DC'
  },
  '@bp4': {
    fontSize: '28px'
  }
})

const Body = styled('ul', {
  padding: 0,
  marginTop: '17px',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: 0,
  '@bp3': {
    display: 'block',
    marginTop: '30px'
  }
})

const List = styled('li', {
  margin: 0,
  listStyleType: 'none',
  flex: '0 1 33.33%',
  '@bp3': {
    margin: '43px 0 0',
    height: 'auto'
  }
})

const Link = styled('a', {
  display: 'block',
  margin: '6px auto',
  maxWidth: '80px',
  '@bp3': {
    maxWidth: '120px'
  }
})

const Display = styled(Image, {
  width: 'auto',
  height: 'auto',
  margin: '0 auto'
})

const BrandShowcase: React.FC<Props> = ({ title, logos }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Body>
        {logos.map((value, i) => (
          <List key={`img-${value.img}-${i}`}>
            <Link href={value.href}>
              <Display src={value.img} aspectRatio={1} />
            </Link>
          </List>
        ))}
      </Body>
    </Container>
  )
}

export default BrandShowcase
