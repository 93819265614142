// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useContext, useEffect } from 'react'
import { styled } from './../../../theme/index'
import { BrandSelector } from './../../molecules/BrandSelector/BrandSelector'
import { type Option as SelectProps } from './../../atoms/Select/Select'
import BrandShowcase from './../../molecules/BrandShowcase/BrandShowcase'
import BrandsCollection, { type BrandProps } from './../../molecules/BrandsCollection/BrandsCollection'
import client from "../../api";
import {UserContext} from "../../../context/UserContext";
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import ErrorMessage from "../../ErrorMessage";

interface Props {
  categoriesSelect?: SelectProps[]
  brandsSelect?: SelectProps[]
  brandsCollection?: BrandProps[]
}

const Container = styled('div', {
  margin: 0,
  '@bp1': {
    maxWidth: '744px',
  },
  '@bp3': {
    padding: 0,
    maxWidth: '100%'
  }
})

const MainContent = styled('div', {
  width: '100%'
})

const Body = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',
  '@bp3': {
    display: 'grid',
    maxWidth: '100%',
    gridTemplateColumns: '75% 25%',
    justifyItems: 'center',
    gap: '24px'
  }
})

const Title = styled('h2', {
  padding: '15px 20px 15px 0',
  color: '$black',
  fontFamily: '$heading',
  fontSize: '25px',
  fontWeight: '$bold',
  letterSpacing: '1px',
  lineHeight: 1,
  textTransform: 'uppercase',
  margin: 0,
  '@bp3': {
    display: 'none'
  }
})

const SideContent = styled('div', {
  width: '100%',
  marginTop: '18px',
  '@bp1': {
    marginTop: '5px',
    marginRight: '5px'
  },
  '@bp3': {
    marginTop: '4px'
  }
})

const BrandsSelector = styled(BrandSelector, {
  '@bp4': {
    marginTop: 0,
    paddingBottom: '8px'
  }
})

let originalBrandsCollection = []; // saving old list while filtering

const BrandsList: React.FC<Props> = ({...props}) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [dataShowCase, setDataShowCase] = useState({
    "title": "Featured Brands",
    "logos": []
  });
  const [brandsCollection, setBrandsCollection] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [categoriesSelect, setCategoriesSelect] = useState([]);
  const [brandsSelect, setBrandsSelect] = useState([]);
  const [indexItem, setIndexItem] = useState('All')
  const [loading, setLoading] = useState(false);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length>2 && username.length>2 && csrf_token.length>2) {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get("/api/brands/list", {
        headers: { csrf_token },
        auth: { username, password }
      })
        .then(({ data }) => {
          setDataShowCase(data.featuredBrands);
          setBrandsCollection(data.brands);
          setCategoriesSelect([{value:'', label:'- Select a Category -'}, ...data.categories]);
          setBrandsSelect([{value:'', label:'- All Brands -'}, ...data.brandsSelect]);
          setLoading(false);
        })
        .catch(e => {
          console.log("error", e);
          setErrorMessages(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, username, csrf_token]);

  const onChangeHandler = (e): void => {
    setIndexItem(e)
  }

  const onFilterSelect = (key, value): void => {
    // backup the original list on first filter
    if (originalBrandsCollection.length===0) {
      originalBrandsCollection = brandsCollection;
    }

    const brandArray = originalBrandsCollection;

    if (value.value.length!==0) {
      if (key==="categories") {
        // eslint-disable-next-line array-callback-return
        const filteredCollection = brandArray.map(charObj => {
          const brandsOldArray = charObj.options;
          // eslint-disable-next-line array-callback-return
          const catExist = brandsOldArray.filter(brand => {
            // eslint-disable-next-line array-callback-return
            const isExists = brand.category.filter(cat => cat===value.value);
            return isExists.length === 1;
          });

          return {
            "title": charObj.title,
            "options": catExist
          }
        });

        setBrandsCollection(filteredCollection);
      }
      else if (key==="retailers") {
        let firstLetter = Array.from(value.label)[0];
        if (firstLetter.match(/[a-z]/i)===null) { // is not letter
          firstLetter = "#";
        }

        // eslint-disable-next-line array-callback-return
        const searchOptions = brandArray.filter(itemObj => {
          if (itemObj.title===firstLetter) {
            return itemObj;
          }
        });

        let newArray = [];
        if (searchOptions[0]!==undefined) {
          // eslint-disable-next-line array-callback-return
          newArray = searchOptions[0].options.filter(item => {
            if (item.name===value.label) {
              return item;
            }
          });
        }

        const newBrandsCol = [{
          "title" : firstLetter,
          "options" : newArray
        }];

        setBrandsCollection(newBrandsCol);
      } // end if key===brands
    }
    else {
      setBrandsCollection(originalBrandsCollection);
    }
  }

  return (
    <Container {...props}>
      <Title>BRANDS</Title>

      { errorMessages && <ErrorMessage contactSupport={true} /> }

      <Body>
        <MainContent>
          <BrandsSelector
            onIndexClick={onChangeHandler}
            onFilterSelect={onFilterSelect}
            categoriesSelect={categoriesSelect}
            brandsSelect={brandsSelect}
          />

          {loading && <LoadingOverlay style={{position: "absolute", zIndex: "100"}} />}

          <BrandsCollection brands={brandsCollection} indexItem={indexItem} />
        </MainContent>

        <SideContent>
          <BrandShowcase title={dataShowCase.title} logos={dataShowCase.logos} />
        </SideContent>
      </Body>
    </Container>
  )
}

export default BrandsList
