// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../../context/UserContext';
import { styled, type ThemeProps } from './../../../theme/index'
import Tabs, { type TabProps } from './../../molecules/Tabs/Tabs'
import FAQsContent from './../../organisms/FAQsContent/FAQsContent'
import ContactTabContent from './../../organisms/ContactTabContent/ContactTabContent'
import { RequestABrandContent } from './../../organisms/RequestABrandContent/RequestABrandContent'
import { MissingATransaction } from "./MissingATransaction";
import {VideosLibrary} from "./VideosLibrary";
import client from "../../api";
import ErrorMessage from "../../ErrorMessage";

interface Props extends ThemeProps {
  tabs?: TabProps[]
  defaultTab?: number
}

const Container = styled('div', {
  width: '100%',
  maxWidth: '100%'
})

const Support: React.FC<Props> = ({ tabs = [], defaultTab=0, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [zohoData, setZohoData] = useState([]);
  const tabsList = [
    {
      label: 'FAQS',
      content: <FAQsContent  css={{ paddingLeft: '10px' }} />
    },
    {
      label: 'VIDEOS LIBRARY',
      content: <VideosLibrary />
    },
    {
      label: 'CONTACT SUPPORT',
      content: <ContactTabContent zohoData={zohoData} />
    },
    {
      label: 'MISSING A TRANSACTION',
      content: <MissingATransaction zohoData={zohoData} />
    },
    {
      label: 'REQUEST A BRAND',
      content: <RequestABrandContent zohoData={zohoData} />
    }
  ];

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length>2 && username.length>2 && csrf_token.length>2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get("/api/support/get/details?_format=json", {
        headers: { csrf_token },
        auth: { username, password }
      })
        .then(({ data }) => {
          setZohoData(data);
        })
        .catch(e => {
          console.log("error", e);
          setErrorMessages(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, username, csrf_token]);

  return (
    <Container {...props}>

      { errorMessages && <ErrorMessage contactSupport={true} /> }

      <Tabs tabs={tabsList} defaultTab={defaultTab} css={{ padding: '0 5px 10px', marginTop: '0' }} />
    </Container>
  )
}

export default Support
