import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import Text from './../../atoms/Text/Text'

const Container = styled('div', {
  display: 'block',
  maxWidth: '100%',
})

const Title = styled('h4', {
  color: '$black',
  fontFamily: '$heading',
  fontSize: '$size18',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1,
  textTransform: 'uppercase',
  marginBottom: '29px'
})

const Question = styled(Text, {
  fontFamily: '$heading',
  fontSize: '$size17',
  fontWeight: '$bold',
  letterSpacing: '0.5px',
  lineHeight: 1.529,
  textTransform: 'uppercase',
  marginBottom: '10px',
  marginTop: '30px'
})

const Paragraph = styled(Text, {
  fontSize: '$size16',
  marginBottom: '10px',
  letterSpacing: '-0.2px',
  lineHeight: 1.666
})

const OrderedList = styled('ul', {
  margin: '0 0 10px'
})

const ListElement = styled('li', {
  fontFamily: '$body',
  fontSize: '16px',
  lineHeight: 1.562,
  letterSpacing: '-0.2px'
})

const LinkVariant = styled('a', {
  color: '#7fbdb8',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
})

// const SupportMail = styled('div', {
//   marginTop: '40px'
// })

const SupportContact = styled('div', {
  textAlign: 'center'
})

const QuestionSupport = styled('h3', {
  color: '$black',
  fontFamily: '$heading',
  fontSize: '25px',
  fontWeight: '$bold',
  letterSpacing: '0.2px',
  lineHeight: 1,
  borderBottom: 'none',
  marginBottom: '5px',
  paddingTop: '20px',
  paddingBottom: '17px',
  marginTop: 0
})

const LinkContact = styled('a', {
  color: '$black',
  textDecoration: 'underline',
  fontWeight: '$bold',
  fontSize: '$size16'
})

const UnderlineText = styled('span', {
  textDecoration: 'underline'
})

const FAQsContent: React.FC<ThemeProps> = ({ ...props }) => {
  return (
    <Container {...props}>
      <Title>FREQUENTLY ASKED QUESTIONS</Title>
      <Question>WHAT IS BRANDCYCLE?</Question>
      <Paragraph>
        BrandCycle is a monetization platform that connects retail brands with lifestyle publishers.
        BrandCycle has developed a collection of exclusive tools that lets top publishers in the
        mom, family, baby, wedding, home décor and style space earn commission on content, products,
        photos and more.
      </Paragraph>

      <Question>HOW DOES BRANDCYCLE WORK?</Question>
      <Paragraph>
        With BrandCycle’s single network login, publishers can access exclusive content and build
        high performing cost-per-acquisition (CPA) campaigns, deals and offers. Publishers on
        BrandCycle work only with the retail brands they want, how they want. It’s free to join, and
        publishers make equal or greater commission working through BrandCycle compared to working
        directly with a brand or through a traditional affiliate network.
      </Paragraph>

      <Question>WHICH PUBLISHERS WORK WITH BRANDCYCLE?</Question>
      <Paragraph>
        BrandCycle works exclusively with lifecycle publishers. Lifecycle publisher is a term that
        BrandCycle uses to refer to content-driven websites that cater to the following niche
        audiences: Mom & Family, Wedding, Style, Home Décor, Health & Wellness and Green.
      </Paragraph>

      <Question>WHICH RETAILERS WORK WITH BRANDCYCLE?</Question>
      <Paragraph>
        BrandCycle works with select leading retail and consumer brands in the lifecycle verticals.
        A few representative retailers include Target, Reebok, Tiny Prints, Shutterfly and Gymboree.
      </Paragraph>

      <Question>HOW OFTEN DOES BRANDCYCLE ADD NEW PRODUCTS AND CONTENT?</Question>
      <Paragraph>
        BrandCycle works with retailers to develop exclusive campaigns for publishers on the
        BrandCycle platform, BrandCycle also curates a product feed of best-selling products and the
        latest exclusive campaigns from retail brand partners on a daily basis.
      </Paragraph>

      <Question>
        DO PUBLISHERS EARN LESS BY WORKING WITH BRANDCYCLE VERSUS DIRECTLY WORKING WITH RETAILERS?
      </Question>
      <Paragraph>
        Publishers make equal or greater commission working through BrandCycle compared to working
        directly with a brand or through a traditional affiliate network.
      </Paragraph>

      <Question>HOW DOES THE BOOKMARKLET TOOL WORK?</Question>
      <Paragraph>
        Our proprietary technology, which is not available through the traditional affiliate
        networks, allows BrandCycle to deliver content and campaigns to publishers and affiliates in
        a more streamlined, user-friendly way.
      </Paragraph>
      <Paragraph>
        Our bookmarklet tool can be dragged and dropped into a browser’s bookmarks toolbar. This
        button enables publishers to make money and create links right from a retailer&apos;s site.
        With our simple tool, BrandCycle publishers can access exclusive deals and content with a
        single click.
      </Paragraph>

      <Question>HOW CAN I DOWNLOAD THE BOOKMARKLET?</Question>
      <Paragraph>
        The bookmarklet can be downloaded with a simple drag-and-drop. BrandCycle-approved
        publishers can download our free proprietary bookmarklet tool from the <LinkVariant href="/bookmarklet">BrandCycle portal</LinkVariant>.
      </Paragraph>

      <Question>WHICH BROWSERS ARE COMPATIBLE WITH THE BOOKMARKLET?</Question>
      <Paragraph>The bookmarklet is compatible with all major browsers.</Paragraph>

      <Question>IS IT FREE TO JOIN BRANDCYCLE?</Question>
      <Paragraph>It is free for publishers to sign up and join BrandCycle.</Paragraph>

      <Question>HOW DO PUBLISHERS RECEIVE PAYMENTS?</Question>
      <Paragraph>
        When a publisher creates a BrandCycle account, he or she should navigate to the “My Account”
        page to set up payment details with our payment provider, Payoneer. Publishers will be
        prompted to set-up a Payoneer account with a separate login and password. Once an account is
        set-up, publishers can choose a desired method of payment (direct deposit or pre-paid debit
        card) and access the Payoneer account at anytime to manage payment details and view
        transaction history.
      </Paragraph>

      <Question>WHEN WILL PUBLISHERS BE PAID?</Question>
      <Paragraph>
        BrandCycle functions as a mediator connecting publishers and brands. Publishers are compensated by BrandCycle upon receipt of payment from brands. The timeline for payments from brands can fluctuate due to factors such as the brand&apos;s business nature or the standard return/refund processing time for consumer transactions. While it&apos;s typical for brand payments to occur within 30-90 days post-transaction, some brands might extend this duration, and take more time to finalize their payments to BrandCycle.
      </Paragraph>

      <Paragraph>
        Payments from BrandCycle will be credited to the payment details that a publisher enters when setting up a BrandCycle account. When a publisher&apos;s balance reaches $20, he or she can transfer to a linked account.
      </Paragraph>

      <Paragraph>
      Note: If you are using an international bank account (not US-based), and have chosen Payoneer as your payment provider, your balance will need to reach $50 USD until the funds can be transferred. If this is your situation, we&apos;d recommend choosing PayPal to receive a payout at $20.
      </Paragraph>

      <Paragraph>
        BrandCycle processes all payments to publishers received from the previous month on the 15th day of every month (or the closest business day if the 15th falls on a weekend).
      </Paragraph>

      <Question>HOW DOES BRANDCYCLE HANDLE CLICK FRAUD?</Question>
      <Paragraph>
        BrandCycle takes steps to ensure that clicks generated from your website are not of a
        fraudulent nature e.g. from BrandCycle publishers or technology used to produce false
        clicks.
      </Paragraph>

      <Question>WHY IS A TAX FORM NECESSARY FOR PAYMENT?</Question>
      <Paragraph>
        As a publisher working with BrandCycle, the IRS requires our company to report the annual
        income a publisher receives through the program via a 1099 form. Once a publisher receives
        $600 in payouts, that publisher must submit a W9 form through their Payoneer account, which
        is an industry standard for affiliate programs.
      </Paragraph>

      <Question>ARE THERE OPPORTUNITIES FOR PERSONALIZED AND CUSTOM CAMPAIGNS?</Question>
      <Paragraph>
        Yes, in addition to exclusive brand campaigns that are open to all BrandCycle publishers,
        select BrandCycle publishers have the opportunity for further engagement with retail brand
        partners on the platform. If you’re interested in custom opportunities, email{' '}
        <LinkVariant href="mailto:info@brandcycle.com">info@brandcycle.com</LinkVariant> for more
        information.
      </Paragraph>

      <Question>
        WHAT IS THE DIFFERENCE BETWEEN WORKING WITH BRANDCYCLE COMPARED TO OTHER CONTENT
        MONETIZATION PLATFORMS?
      </Question>
      <Paragraph>
        BrandCycle’s emphasis on high-quality, brand-relevant campaigns enables publishers to offer
        value to their audience while generating revenue for their website properties. By working
        with BrandCycle, publishers have the added advantage of accessing custom content and
        exclusive campaigns from our network of retailers. Using BrandCycle’s proprietary
        bookmarklet, publishers can monetize content directly from their browser, cutting down the
        hassle of logging into multiple affiliate networks and managing individual relationships
        with BrandCycle retail brand partners.
      </Paragraph>

      <Question>
        HOW DO PUBLISHERS KNOW HOW MANY CLICKS AND HOW MUCH REVENUE THEY ARE GENERATING?
      </Question>
      <Paragraph>
        BrandCycle publishers can monitor their performance using the Reports section in the
        BrandCycle portal.
      </Paragraph>

      <Question>I’M NEW TO CONTENT MONETIZATION. HOW DOES AFFILIATE MARKETING WORK?</Question>
      <Paragraph>
        Affiliate marketing is really a way to track and receive payments for transactions generated
        as a result of visitors clicking a link to another website and making a purchase. Becoming
        an affiliate publisher offers the opportunity to monetize content and leverage online
        influence.
      </Paragraph>

      <Question>HOW DO I ADD MULTIPLE USER ACCOUNTS UNDER MY MASTER ACCOUNT?</Question>
      <Paragraph>
        Publishers can now have multiple user accounts with different permission levels under one
        master account. Follow these{' '}
        <LinkVariant href="https://mcusercontent.com/7b2372ad67fad9e6a4e065b97/files/66f3a83b-5269-e22a-08fb-a31bc52a5a0e/BrandCycle_Users_Instructions.pdf">
          step-by-step instructions
        </LinkVariant>
        {' '}to set up new Users within your BrandCycle account.
      </Paragraph>

      <Question>HOW DOES OUR REFERRAL PROGRAM WORK?</Question>
      <Paragraph>
        To participate in the Program, follow these easy{' '}
        <LinkVariant href="https://mcusercontent.com/7b2372ad67fad9e6a4e065b97/files/95f2abb1-91e6-aad8-6651-6876a2b3dab6/Step_by_Step_How_Does_the_Referral_Program_Work_.pdf">
          step-by-step instructions
        </LinkVariant>
        {' '}to refer as many friends or colleagues as you want to the Platform. There is no limit to the
        number of referrals you can make. However, we do ask that you please refer only qualified
        individuals who meet BrandCycle requirements. We reserve the right to modify or amend these
        Terms, the Program, or any part of such, at any time and for any reason. We may also deny
        participation in our Program if we have any reason to believe that you are not in compliance
        with these Terms or the Program.
      </Paragraph>

      <Question>WHAT IS A QUALIFIED REFERRAL?</Question>
      <Paragraph>
        A &quot;Qualified Referral&quot; requires that all of the following conditions are met:
      </Paragraph>
      <OrderedList>
        <ListElement>
          Your Friend must be a new BrandCycle member– i.e. they have not previously worked with
          BrandCycle in the past, nor do they have an account or registration with us under any
          email address or alias. This will be determined at BrandCycle’s sole discretion.
        </ListElement>
        <ListElement>
          Your Friend must complete their first sale within thirty (30) days of being approved to
          join BrandCycle.
        </ListElement>
      </OrderedList>

      <Question>HOW LONG DOES IT TAKE TO GET MY REFERRAL BONUS?</Question>
      <Paragraph>
        <strong>REFERRAL:</strong> After you make your first sale, the $25 bonus will be added to this balance, and your earnings will accrue cumulatively until you hit the $10 payout threshold. BrandCycle pays out on the 15th of every month — if you have more than $20 in locked earnings, you will be paid on the next payout date.
      </Paragraph>
      <Paragraph>
        <strong>REFERRER:</strong> In order for you to get paid, your Referral has to make a sale. Once they do, a $25 bonus will be added to your account. If you have more than $20 in locked earnings at this point, you will be paid on the next payout date (15th of every month).
      </Paragraph>
      <Paragraph>
        Reminder: BrandCycle has a $20 minimum payout threshold in order to get paid. If you are using an international bank account (not US-based), and have chosen Payoneer as your payment provider, your balance will need to reach $50 USD until the funds can be transferred. If this is your situation, we&apos;d recommend choosing PayPal to receive a payout at $20.
      </Paragraph>

      <Question>WHAT IF MY REFERRAL DOESN’T MAKE A SALE?</Question>
      <Paragraph>
        If your Referral doesn&apos;t{' '}
        <UnderlineText>make a sale within 30 days after they were approved</UnderlineText> to join
        BrandCycle, neither of you will receive the bonus.
      </Paragraph>

      <Question>WHAT IS MY REFERRAL CODE AND WHERE CAN I FIND IT?</Question>
      <Paragraph>
        A Referral Code is a code that is used to track each person that you’ve invited to join
        BrandCycle. This code is unique to each BrandCycle publisher and can be found under the
        Refer a Friend tab on the platform.
      </Paragraph>

      <Question>
        CAN I BUILD AN AFFILIATE LINK USING THE BRAND LINKS FROM THE NEWSLETTERS AND EMAILS THAT
        BRANDCYCLE SENDS TO THE PUBLISHERS?
      </Question>
      <Paragraph>
        Yes, the links in our newsletters are clean links and contain no affiliate tracking. These
        links can be used to create your own BrandCycle affiliate link. To further clarify, if a
        publisher clicks a link from any of our newsletters, opens a browser, and then creates a
        deep link using the bookmarklet, build-a-link tool or our app, and then promotes that link
        via one of their social handles, and a consumer converts off of it, the commissions will be
        paid back to the publisher.
      </Paragraph>

      <SupportContact>
        <QuestionSupport>STILL HAVE QUESTIONS?</QuestionSupport>
        <Paragraph>
          <LinkContact href="/contact-support">Contact Support</LinkContact> and we’ll help you find an answer.
        </Paragraph>
      </SupportContact>
    </Container>
  )
}

export default FAQsContent
