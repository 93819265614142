// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'

const Icon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" fill="currentColor">
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="2"
        d="M1 6.925h10.667M1 1.385h16M1 12.465h16m-16 5.54h10.667"
      />
    </svg>
  )
}

export default Icon
