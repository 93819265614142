// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { styled, type ThemeProps } from './../../../theme/index'
import TrackingLinkForm from './../../molecules/TrackingLinkForm/TrackingLinkForm'
import Pagination from './../../molecules/Pagination/Pagination'
import AccordionSection from './../../molecules/AccordionSection/AccordionSection'
import { type Props as PropsCard } from './../../organisms/PromotionCard/PromotionCard'
import BrandHeader from './../../organisms/BrandHeader/BrandHeader'
import BrandBannerGrid, { type Data as GridProps } from './../../organisms/BrandBannerGrid/BrandBannerGrid'
import SpecialPromotionsGrid from './../../organisms/SpecialPromotionsGrid/SpecialPromotionsGrid'
import client from "../../api";
import {UserContext} from "../../../context/UserContext";
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import ErrorMessage from "../../ErrorMessage";

interface Props extends ThemeProps {
  promotions?: PropsCard[]
  brandOptions?: GridProps[]

  pagination?: {
    current: number
    total: number
    pageSize: number
  }
}

const Container = styled('div', {
  padding: 0,
  width: '100%',
  maxWidth: '100%'
})

const PaginationArea = styled('div', {
  padding: '45px 0 36px',
  div: {
    justifyContent: 'center'
  }
})

const pagination = {
  "current": 1,
  "total": 0,
  "pageSize": 2
};
const bannersPagination = {
  "current": 1,
  "total": 0,
  "pageSize": 9
};

const BrandsDetails: React.FC<Props> = ({...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;

  const [info, setInfo] = useState({});
  const [promotions, setPromotions] = useState([]);
  const [chunkPromotions, setChunkPromotions] = useState([]);

  const [bannersOptions, setBannersOptions] = useState([]);

  const { id } = useParams();
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [promotionsLoading, setPromotionsLoading] = useState(false);
  const [bannersLoading, setBannersLoading] = useState(false);
  // const navigate = useNavigate();

  // Access the user credentials
  useEffect(() => {
    setDetailsLoading(true);
    if (password.length>2 && username.length>2 && csrf_token.length>2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get(`/api/brands/${id}/get/details?_format=json`, {
        headers: { csrf_token },
        auth: { username, password }
      })
        .then((response) => {
          setInfo(response.data.info);

          setDetailsLoading(false);

          setPromotionsLoading(true);
          getBrandPromotions(); // get brand special promotions
        })
        .catch(() => {
          // console.log("error: ", e);
          setErrorMessages(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, username, csrf_token]);

  const getBanners = ():void => {
    setBannersLoading(true);
    setBannersOptions([]);

    void client.get(`/api/brands/${id}/get/banners/${bannersPagination.current-1}?_format=json`, {
      headers: { csrf_token },
      auth: { username, password }
    })
      .then((response) => {
        setBannersOptions(response.data.data);
        bannersPagination.current = response.data.pageNum;
        bannersPagination.total = response.data.pageTotal;
        bannersPagination.pageSize = response.data.pageSize

        setBannersLoading(false);
      })
      .catch(() => {
        // console.log("Error getting banners: ", e);
        setErrorMessages(true);
        setBannersLoading(false);
      });
  }

  const getBrandPromotions = ():void => {
    void client.get(`/api/brands/${id}/get/promotions?_format=json`, {
      headers: { csrf_token },
      auth: { username, password }
    })
      .then((response) => {
        // process promotions
        const chunks = [];
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i += pagination.pageSize) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands,@typescript-eslint/no-unused-vars
            chunks.push(response.data.slice(i, i + pagination.pageSize));
          }
          pagination.total = response.data.length;
        }

        setPromotions(chunks[0]);
        setChunkPromotions(chunks);

        setPromotionsLoading(false);

        getBanners();
      })
      .catch(() => {
        // console.log("Error getting promotions: ", e);
        setErrorMessages(true);
      });
  }

  const showLoader = ():void => {
    setDetailsLoading(true);
  }
  const hideLoader = ():void => {
    setDetailsLoading(false);
  }
  const handleHomepageUrlError = ():void => {
    setErrorMessages(true);
  }

  const showBannerLoader = ():void => {
    setBannersLoading(true);
  }
  const hideBannerLoader = ():void => {
    setBannersLoading(false);
  }

  return (
    <Container {...props}>

      { detailsLoading && <LoadingOverlay /> }
      { errorMessages && <ErrorMessage contactSupport={true} /> }

      <BrandHeader brandId={id} info={info} showLoader={showLoader} hideLoader={hideLoader}
                   handleHomepageUrlError={handleHomepageUrlError} />

      <TrackingLinkForm brandId={id} brandUrl={info.url}
                        enabledDeepLink={info.enableDeepLinking} deeplinkUrl={info.deeplinkUrl}
                        display="brandHomepage" />

      <section>
        {promotionsLoading && <LoadingOverlay style={{position: "inherit"}} />}

        {promotions?.length>0 ?
          <AccordionSection heading="Share Special Promotions">
            <SpecialPromotionsGrid
              promotions={promotions}
              css={{
                paddingTop: '16px'
              }}
            />
          </AccordionSection>
          : null
        }

        {promotions?.length>0 ?
          <PaginationArea>
            <Pagination
              totalCount={pagination.total}
              pageSize={pagination.pageSize}
              currentPage={pagination.current}
              setCurrentPage={(selectedPage) => {
                pagination.current = selectedPage;
                setPromotions(chunkPromotions[selectedPage-1]);
              }}
            />
          </PaginationArea>
            : null
        }
      </section>

      <section>
        {bannersLoading && <LoadingOverlay style={{position: "inherit"}} />}

        {bannersOptions?.length>0 ?
          <AccordionSection heading="Share Banners">
            <BrandBannerGrid options={bannersOptions} showLoader={showBannerLoader} hideLoader={hideBannerLoader} />
          </AccordionSection>
          : null
        }

        {bannersPagination.total>1 ?
          <PaginationArea>
            <Pagination
              totalCount={bannersPagination.total}
              pageSize={bannersPagination.pageSize}
              currentPage={bannersPagination.current}
              setCurrentPage={(selectedPage) => {
                bannersPagination.current = selectedPage;
                getBanners();
              }}
            />
          </PaginationArea>
          : null
        }
      </section>
    </Container>
  )
}

export default BrandsDetails
