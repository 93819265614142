// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useState, useEffect} from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import * as CheckboxRadix from '@radix-ui/react-checkbox'

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  name: string
  callback?: (e: any) => void
  type?: 'secondary' | 'form' | 'editForm'
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center'
})

const CheckboxRoot = styled(CheckboxRadix.Root, {
  all: 'unset',
  boxSizing: 'border-box',
  position: 'relative',
  width: '16px',
  height: '16px',
  borderRadius: '2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$blue5',
  '&:focus': { boxShadow: `0 0 0 2px $black` },
  '&[aria-checked="false"]': {
    backgroundColor: '$gray2'
  },
  variants: {
    type: {
      form: {
        backgroundColor: '#0075ff'
      },
      editForm: {
        backgroundColor: '#0075ff',
        width: '13px',
        height: '13px',
        marginTop: '1px',
        border: '1px solid $darkgray',
        '&:focus': { boxShadow: `0 0 0 0 $black` },
        '&[aria-checked="true"]': {
          border: 'none'
        },
        '&[aria-checked="false"]': {
          backgroundColor: '$clear'
        },
        '&:hover': {
          backgroundColor: '#005cc8'
        }
      }
    }
  }
})

const CheckboxIndicator = styled(CheckboxRadix.Indicator, {
  '&:after': {
    position: 'absolute',
    content: '',
    width: '6px',
    height: '9px',
    border: 'solid',
    borderColor: '$clear',
    borderWidth: '0 2px 2px 0',
    transform: 'translate(-50%, -60%) rotate(45deg)'
  },
  variants: {
    type: {
      editForm: {
        '&:after': {
          width: '5px',
          transform: 'translate(0%, 20%) rotate(43deg)',
          inset: '0',
          margin: '0 auto',
          height: '8px'
        }
      }
    }
  }
})

const Label = styled('label', {
  fontFamily: '$body',
  color: '$black',
  fontSize: '$size16',
  lineHeight: 1.6,
  paddingLeft: '4px',
  cursor: 'pointer',
  variants: {
    type: {
      secondary: {
        fontSize: '13px',
        '@bp4': {
          fontSize: '16px'
        }
      },
      form: {
        fontWeight: '$bold',
        transform: 'translateY(1px)',
        fontFamily: '$label'
      },
      editForm: {
        minHeight: '20px',
        paddingLeft: '8px',
        marginBottom: 0,
        fontFamily: '$label',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: 1.42,
        letterSpacing: '-0.4px',
        color: '$darkgray'
      }
    }
  }
})

const Checkbox: React.FC<Props> = ({ type, name, children, isAccept, readOnly=false, callback, ...props }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() =>{
    setChecked(isAccept);
  }, [isAccept])

  return (
    <Container {...props}>
      <CheckboxRoot id={name} checked={checked} onCheckedChange={(value) => {
        setChecked(value);
        callback(value);
      }} type={type as any} disabled={readOnly} >
        <CheckboxIndicator type={type as any} />
      </CheckboxRoot>
      <Label htmlFor={name} type={type as any}>
        {children}
      </Label>
    </Container>
  )
}

export default Checkbox
