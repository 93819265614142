import React, { useState } from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import Select, { type Option as SelectProps } from './../../atoms/Select/Select'
import { indexCollection } from './../../molecules/BrandSelector/indexCollection'

interface Props extends ThemeProps {
  categoriesSelect: SelectProps[]
  brandsSelect: SelectProps[]
  onIndexClick: (index: string) => void
  onFilterSelect: (key: string, value: any) => void
}

const Container = styled('div', {
  width: '100%',
  maxWidth: '100%'
})

const SelectWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '100%',
  flexDirection: 'column',
  marginRight: 0,
  marginBottom: '5px',
  marginTop: '18px',
  '@bp1': {
    flexDirection: 'column',
    marginRight: 0,
    marginTop: '2px'
  },
  '@bp3': {
    flexDirection: 'row',
    marginRight: '4px',
    marginBottom: '5px',
    marginTop: 0
  },
  '&>div': {
    maxWidth: '100%',
    margin: '15px 0 0',
    '@bp3': {
      width: '198px',
      marginRight: '20px'
    }
  }
})

const Index = styled('ul', {
  display: 'flex',
  flexWrap: 'wrap',
  padding: 0,
  margin: '20px 0 3px',
  '&>li': {
    listStyleType: 'none',
    padding: '11px 1px 0 0',
    '@bp1': {
      padding: '11px 11px 0 0'
    },
    '&:hover': {
      '&>a': {
        fontWeight: '$bold',
        color: '$black'
      }
    },
    '&>a': {
      color: '$blue3',
      textDecoration: 'none',
      fontFamily: '$heading',
      fontSize: '20px',
      lineHeight: 1,
      letterSpacing: '2.3px'
    }
  },
  '@bp3': {
    margin: 0
  }
})

const Element = styled('li', {
  listStyleType: 'none',
  padding: '5px 7px 0 0',
  lineHeight: 1.428,
  '&>span': {
    cursor: 'pointer',
    color: '$blue3',
    textDecoration: 'none',
    fontFamily: '$heading',
    fontSize: '20px',
    lineHeight: 1,
    letterSpacing: '2.5px',
    marginLeft: '10px',
    '&:hover': {
      fontWeight: '$bold',
      color: '$black'
    },
    '@bp1': {
      marginLeft: 0
    }
  }
})

const Title = styled('h2', {
  display: 'none',
  '@bp3': {
    padding: '7px 20px 15px 0',
    color: '$black',
    fontFamily: '$heading',
    fontSize: '25px',
    fontWeight: '$bold',
    letterSpacing: '1px',
    lineHeight: 1,
    textTransform: 'uppercase',
    margin: 0,
    display: 'block'
  }
})

const Header = styled('div', {
  display: 'block',
  '@bp3': {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '15px'
  }
})

export const BrandSelector: React.FC<Props> = ({
  categoriesSelect,
  brandsSelect,
  onIndexClick,
  onFilterSelect,
  ...props
}) => {
  const initialState = {
    categories: categoriesSelect[0],
    retailers: brandsSelect[0]
  }

  const [formInfo, setFormInfo] = useState(initialState)
  const changeHandler = (key: string, value: string | SelectProps): void => {
    setFormInfo({ ...formInfo, ...{ [key]: value } })
    onFilterSelect(key, value);
  }

  return (
    <Container {...props}>
      <Header>
        <Title>BRANDS</Title>
        <SelectWrapper>
          <Select
            id="categories"
            label="Role"
            options={categoriesSelect}
            value={formInfo.categories!==undefined ? formInfo.categories : categoriesSelect[0]}
            setValue={(e): void => {
              changeHandler('categories', e as SelectProps)
            }}
          />

          <Select
            id="options"
            label="Role"
            searchIcon={true}
            isSearch={true}
            options={brandsSelect}
            value={formInfo.retailers!==undefined ? formInfo.retailers : brandsSelect[0]}
            setValue={(e): void => {
              changeHandler('retailers', e as SelectProps)
            }}
          />
        </SelectWrapper>
      </Header>

      <Index>
        {indexCollection.map((letter, i) => (
          <Element key={`brand-${letter}-${i}`}>
            <span
              onClick={() => {
                onIndexClick(letter)
              }}
            >
              {letter}
            </span>
          </Element>
        ))}
      </Index>
    </Container>
  )
}
