// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useContext, useState } from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import { Button } from '../../atoms/Button/Button'
import CopyDisclaimer from './../../atoms/CopyDisclaimer/CopyDisclaimer'
import HighlightStat from './../../atoms/HighlightStat/HighlightStat'
import useCopy from "../../../hook/useCopy/useCopy";
import {UserContext} from "../../../context/UserContext";
import client from "../../api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  commission: string
  length: string
}

const Container = styled('div', {
  display: 'grid',
  maxWidth: '100%',

  '@bp2': {
    display: 'grid',
    maxWidth: '100%'
  }
})

const TrackingBox = styled('div')

const TrackingButton = styled(Button, {
  variants: {
    type: {
      action: {
        minWidth: '200px',
        maxWidth: '706px',
        fontSize: '12px',
        fontWeight: '$bold',
        letterSpacing: '0.8px',
        margin: 'auto',
        padding: '16px 0',
        '@bp2': {
          fontSize: '12px'
        },
        '@bp3': {
          fontSize: '15px'
        }
      }
    }
  }
})

const HighlightContent = styled('div', {
  justifyContent: 'space-around',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  marginTop: '20px'
})
const HighlightContentBMK = styled('div', {
  justifyContent: 'space-around',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRow: '1fr 1fr',
})

const Disclaimer = styled(CopyDisclaimer, {
  padding: '16px 8px 8px 0'
})

// isApple pop up
const Icon = styled('span', {
  marginRight: '12px'
})
const SafariPopupDiv = styled('div', { // popup
  display: 'grid',
  gridTemplateColumns: '1fr 1fr'
  }
)
const SafariInputField = styled('input', {
    // fontSize: '1rem',
    padding: '0 13px',
  }
)
const buttonCss = {
  letterSpacing: '0.8px',
  fontWeight: '$bold',
  padding: '13px 0',
  margin: '0 auto'
}
const copyDisclaimerCss = {
  margin: '19px auto',
  width: '50%',
  variants: {
    column: {
      true: {
        margin: '19px 0 5px'
      }
    }
  }
}


const HomepageTracking: React.FC<Props> = ({ brandId, commission, length, bmkFlag,
                                             handleShowLoader, handleHideLoader, handleErrorGetHomepageURL, ...props }) => {
  const { userCredentials } = useContext(UserContext);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  const [copyAction, copied] = useCopy()
  const [copyLink, useCopyLink] = React.useState(false)
  const ClickHandler = (url): void => {
    useCopyLink(true)
    copyAction(url)
  }
  const [ showSafariURL, setShowSafariURL ] = useState(false);
  const [ urlString, setUrlString ] = useState("");

  let isApple = false;
  if (navigator.userAgent.match(/Macintosh|ipad|iphone/i)!==null) {
    isApple = true;
  }

  const showLoader = ():void => {
    handleShowLoader();
  }
  const hideLoader = ():void => {
    handleHideLoader();
  }
  const handleCreateHomepageUrlError = ():void => {
    handleErrorGetHomepageURL();
  }

  const handleCreateHomepageURL = ():void => {
    showLoader();

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    void client.get(`/api/brand/${brandId}/dealLink/get?_format=json`,
      { headers: { "Content-type": "application/x-www-form-urlencoded", csrf_token }, auth: { username, password }}
    )
      .then(({ data }) => {

        if (isApple) {
          setUrlString(data);
          setShowSafariURL(true);
        }
        else { // Normal devices
          ClickHandler(data);
        }

        hideLoader();
      })
      .catch(e => {
        // console.log("error", e);
        handleCreateHomepageUrlError();
        hideLoader();
      });
  }

  const handleCopyActionSafari = ():void => {
    copyAction(urlString);
  }
  const applePopup = ():any => {
    return (
      <SafariPopupDiv className="safariPopup" >
        <SafariInputField type="text" value={urlString} disabled={true}/>
        <Button
          css={buttonCss}
          onClick={() => {
            handleCopyActionSafari();
          }}
        >
          <Icon>
            <FontAwesomeIcon icon={faLink} />
          </Icon>
          Copy link
        </Button>

        {copied && (
          <CopyDisclaimer css={copyDisclaimerCss}>
            Link Copied
          </CopyDisclaimer>
        )}

      </SafariPopupDiv>
    )
  }

  return (
    <Container {...props}>
      {
        bmkFlag!==true && (
          <>
            {
              showSafariURL && applePopup()
            }

            <TrackingBox>
              {
                !isApple &&
                  <TrackingButton type="action" onClick={handleCreateHomepageURL}>
                    {
                      !isApple && <>CREATE HOMEPAGE TRACKING URL</>
                    }
                  </TrackingButton>
              }
              {
                isApple && !showSafariURL &&
                  <Button css={buttonCss} onClick={handleCreateHomepageURL}>
                    Generate Homepage Tracking URL
                  </Button>
              }
              {copyLink && <Disclaimer>Link Copied</Disclaimer>}
            </TrackingBox>

            <HighlightContent>
              <HighlightStat label="Your Earnings:" value={commission} bmkFlag={bmkFlag} />
              <HighlightStat label="Cookie Length:" value={length !== undefined ? (length > 1 ? `${length} days` : `${length} day`) : ''}
                             css={{ marginLeft: '12px' }} bmkFlag={bmkFlag} />
            </HighlightContent>

          </>
        )
      }
      {
        bmkFlag===true && (
          <>
            <HighlightContentBMK>
              <HighlightStat label="Your Earnings:" value={commission} bmkFlag={bmkFlag} />
              <HighlightStat label="Cookie Length:" value={length !== undefined ? (length > 1 ? `${length} days` : `${length} day`) : ''} bmkFlag={bmkFlag} />
            </HighlightContentBMK>
          </>
        )
      }
    </Container>
  )
}

export default HomepageTracking
