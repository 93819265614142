import React from 'react'
import { styled, keyframes } from './../../../theme/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface Props {
  clear?: boolean
}

const Container = styled('div', {
  width: '100%',
  height: '100%',
  position: 'absolute', // 'inherit',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(255,255,255,0.6)',
  opacity: 0.9,
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  zIndex: 2
})

const spin = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' }
})

const Display = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80px',
  height: '80px',
  fontSize: '60px',
  color: '$black',
  animation: `${spin} 800ms linear infinite`,
  variants: {
    clear: {
      true: {
        color: '$clear'
      }
    }
  }
})

const LoadingOverlay: React.FC<Props> = ({ clear = false, ...props }) => {
  return (
    <Container {...props}>
      <Display clear={clear}>
        <FontAwesomeIcon icon={faSpinner} />
      </Display>
    </Container>
  )
}

export default LoadingOverlay
