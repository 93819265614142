// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'

const Icon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="2"
        d="m12.111 15.657-6.667 4.51v-4.51H1V1h20v14.657z"
      />
    </svg>
  )
}

export default Icon
