// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'

const Icon = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="square"
        strokeWidth="2"
        transform="translate(1 2)"
      >
        <circle cx="10" cy="10" r="10" />
        <path d="M10 20c2.667-2.424 4-5.758 4-10s-1.333-7.576-4-10C7.333 2.424 6 5.758 6 10s1.333 7.576 4 10ZM.5 7h19m-19 6h19" />
      </g>
    </svg>
  )
}

export default Icon
