// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'

const Icon = (): JSX.Element => {
  return (
    <svg
      id="Icon-Twitter"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24.384 19.811"
    >
      <g id="Icon-Twitter-2" data-name="Icon-Twitter">
        <path
          className="icon-path"
          id="Fill_3-1"
          data-name="Fill 3-1"
          d="M24.384,2.345a10.051,10.051,0,0,1-2.872.788A5.015,5.015,0,0,0,23.71.366,10.048,10.048,0,0,1,20.533,1.58,5,5,0,0,0,11.88,5a4.932,4.932,0,0,0,.13,1.14A14.2,14.2,0,0,1,1.7.914,5.01,5.01,0,0,0,3.247,7.59,4.963,4.963,0,0,1,.981,6.965v.062a5.006,5.006,0,0,0,4.011,4.905,4.983,4.983,0,0,1-1.317.175,5.034,5.034,0,0,1-.942-.09A5.011,5.011,0,0,0,7.4,15.493a10.039,10.039,0,0,1-6.213,2.141A10.275,10.275,0,0,1,0,17.563a14.162,14.162,0,0,0,7.666,2.248A14.137,14.137,0,0,0,21.9,5.579c0-.218,0-.433-.017-.648a10.161,10.161,0,0,0,2.5-2.589Z"
          transform="translate(0 0)"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  )
}

export default Icon
