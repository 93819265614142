import React, { useState, useEffect } from 'react'
import { styled } from './../../../theme/index'
import LoginForm from './../../molecules/LoginForm/LoginForm'
import Header from './../../molecules/Header/Header'
import { menuLinks } from './../../../theme/menuLinks'
import Footer from './../../organisms/Footer/Footer'
import HeroBanner from './../../atoms/HeroBanner/HeroBanner'

import { globalStyles } from '../../../theme/global'
globalStyles()

const Container = styled('div', {
  display: 'block',
  maxWidth: '100%'
})

const ContentForm = styled('div', {
  position: 'relative',
  zIndex: 1,
  marginTop: 0,
  padding: '16px 15px 98px',
  form: {
    backgroundColor: '$clear'
  },
  '@bp2': {
    marginTop: '-64px',
    paddingTop: 0
  }
})

const Login: React.FC = () => {
  const [isScroll, setIsScroll] = useState(false)
  const handleScroll = (): void => {
    const position = window.scrollY
    setIsScroll(position > 100)
  }

  if (window.localStorage.getItem("user_credit")!==null) {
    window.location.href = '/';
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Container>
      <Header navCollection={menuLinks} isScroll={isScroll} />
      <HeroBanner />
      <ContentForm>
        <LoginForm />
      </ContentForm>
      <Footer isLogin />
    </Container>
  )
}

export default Login
