// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useContext} from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import Heading from './../../atoms/Heading/Heading'
import AccountDetail, { type Props as AccountDetailProps } from './../../atoms/AccountDetail/AccountDetail'
import { Button } from '../../atoms/Button/Button'
import { useNavigate } from "react-router-dom";
import {UserContext} from "../../../context/UserContext";

export interface Props extends ThemeProps {
  name: string
  details: AccountDetailProps[]
}

const Container = styled('div')

const Flex = styled('div', {
  display: 'flex',
  alignItems: 'left',
  justifyContent: 'space-between',
  marginBottom: '5px',
  lineHeight: 1.428,
  color: '$darkgray',
  flexDirection: 'column-reverse',
  '@bp0': {
    alignItems: 'center',
    flexDirection: 'inherit'
  }
})

const Name = styled('div', {
  fontFamily: '$label',
  display: 'inline-block',
  fontSize: '$size16',
  marginLeft: '15px',
  lineHeight: 1.428,
  span: {
    fontWeight: '$bold'
  }
})

const Card = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0 12px',
  boxShadow: '0 3px 2px -2px #4d4d4d80',
  width: '100%',
  '@bp3': {
    boxShadow: '$shadow',
    padding: '9px 27px 48px'
  },
  '>div': {
    padding: '10px',
    flex: '0 1 100%',
    '@bp3': {
      flex: '0 1 50%'
    }
  }
})

const buttonStyles = {
  maxWidth: '147px',
  fontFamily: '$body',
  lineHeight: 1,
  marginLeft: '15px',
}

const headingStyle = {
  margin: '20px 0 18px 20px',
  padding: '0 32px',
  '@bp3': {
    margin: '30px 0 20px',
    padding: 0
  }
}

export const UserInformation: React.FC<Props> = ({ name, details }) => {
  const navigate = useNavigate();
  const { userCredentials } = useContext(UserContext);
  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { username } = userCredentials;

  return (
    <Container>
      <Heading type="pageTitle" css={headingStyle}>
        My Account
      </Heading>
      <div>{username}</div>

      <Flex>
        <Name>
          {/* Username: */}
          <span> {name}</span>
        </Name>
        <Button type="action" css={buttonStyles} onClick={() => {navigate("/user/edit");}} >
          EDIT
        </Button>
      </Flex>

      <Card>
        {details.map((data, i) => (
          <AccountDetail key={`user-${data.label}-${i}`} label={data.label} value={data.value} />
        ))}
      </Card>
    </Container>
  )
}
