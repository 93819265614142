import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import { FacebookIcon, InstagramIcon, TwitterIcon } from './../../../icons/index'
// import { facebookUrl, instagramUrl, twitterUrl } from './../../../theme/options'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

interface Props extends ThemeProps {
  type?: 'navbar' | 'home' | 'admin'
  fontAwesomeIcon?: boolean
  isScroll?: boolean
}

const Container = styled('div', {
  display: 'flex'
})

const SocialLink = styled('a', {
  cursor: 'pointer',
  lineHeight: 1.437,
  fontWeight: '$bold',
  transition: '0.3s ease-in font-size',
  transitionDelay: '100ms',
  variants: {
    type: {
      navbar: {
        color: '$blue4',
        fontSize: '20px',
        '&:hover': {
          color: '#3AB0A5',
          textDecoration: 'underline'
        },
        div: {
          marginTop: '5px',
          marginBottom: '7px',
          padding: '0 10px'
        }
      },
      home: {
        fontSize: '23px',
        color: '$white',
        background: '$black',
        div: {
          padding: '0 9px'
        },
        '&:hover': {
          color: '#3AB0A5',
          textDecoration: 'underline'
        }
      },
      admin: {
        color: '#9499AF',
        fontSize: '18px',
        fontWeight: 'none',
        '&:hover': {
          color: '#9499AF'
        },
        div: {
          padding: 0,
          margin: '0 8px'
        }
      }
    }
  }
})

const facebookUrl = 'https://www.facebook.com/BrandCycleInc/';
const twitterUrl = 'https://twitter.com/brandcycle';
const instagramUrl = 'https://www.instagram.com/brandcycle/';

const SocialItems: React.FC<Props> = ({
  fontAwesomeIcon = false,
  type = 'home',
  isScroll = false,
  ...props
}) => {
  const size = isScroll ? { fontSize: '16px' } : {}

  return (
    <Container {...props}>
      <SocialLink href={facebookUrl} type={type} css={size} target="_blank">
        <div>
          {fontAwesomeIcon && type !== 'admin' ? (
            <InstagramIcon />
          ) : (
            <FontAwesomeIcon icon={faFacebookF} />
          )}
        </div>
      </SocialLink>
      <SocialLink href={twitterUrl} type={type} css={size} target="_blank">
        <div>
          {fontAwesomeIcon && type !== 'admin' ? (
            <FacebookIcon />
          ) : (
            <FontAwesomeIcon icon={faTwitter} />
          )}
        </div>
      </SocialLink>
      <SocialLink href={instagramUrl} type={type} css={size} target="_blank">
        <div>
          {fontAwesomeIcon && type !== 'admin' ? (
            <TwitterIcon />
          ) : (
            <FontAwesomeIcon icon={faInstagram} />
          )}
        </div>
      </SocialLink>
    </Container>
  )
}

export default SocialItems
