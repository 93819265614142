// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'
import { styled } from './../../../theme/index'

interface Props {
  clear?: boolean
  showImage: string
  handleCloseClick: any
}

const Container = styled('div', {
  width: '100%',
  height: '100%',
  position: 'absolute', // 'inherit',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0,0,0,0.9)',
  // opacity: 0.9,
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  zIndex: 2
})
const BckGrndImage = styled('div', {
  backgroundColor: 'rgba(255,255,255,1)',
  width: '32%',
  display: 'flex',
  justifyContent: 'center'
})

const ImageOverlay: React.FC<Props> = ({ showImage, handleCloseClick }) => {

  return (
    <Container>
      <BckGrndImage>
        <img src={showImage} width="200px" />
      </BckGrndImage>

      <button className="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
              style={{position:'fixed',top:'60px',right:'60px'}}
              onClick={handleCloseClick}
              role="button" aria-disabled="false" title="close">
        <span className="ui-button-icon-primary ui-icon ui-icon-closethick"></span>
        <span className="ui-button-text">Close Image</span>
      </button>
    </Container>
  )
}

export default ImageOverlay;
