// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import axios from 'axios';

const ENV = process.env.REACT_APP_ENV;
let baseURL="";
if (ENV==="production") {
  baseURL = process.env.REACT_APP_PROD_DOMAIN;
}
else if (ENV==="stage") {
  baseURL = process.env.REACT_APP_STAGE_DOMAIN;
}
else if (ENV==="local") {
  baseURL = process.env.REACT_APP_LOCAL_DOMAIN;
}

const client = axios.create({
  withCredentials: true,
  baseURL
});

export default client;
