// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {createContext, useEffect, useState} from 'react';

// Set initial values for user credentials
const initialUserCredentials = {
  password: '',
  username: '',
  csrf_token: '',
  logout_token: '',
  user: ''
};
// Create the UserContext
export const UserContext = createContext(initialUserCredentials);


export const UserProvider: React.FC = ({ children }) => {
  const [userCredentials, setUserCredentials] = useState(initialUserCredentials);

  const updateUserCredentials = (newCredentials):void => {
    setUserCredentials((prevCredentials) => ({
      ...prevCredentials,
      ...newCredentials,
    }));
  };

  useEffect(() => {
    if (window.localStorage.getItem("user_credit")!==null) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const {password, username, csrf_token, logout_token, user} = JSON.parse(window.localStorage.getItem("user_credit"));
      const newCredentials = {
        password,
        username,
        csrf_token,
        logout_token,
        user
      };

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      updateUserCredentials(newCredentials);
    }
  }, []);

  return (
    <UserContext.Provider value={{ userCredentials, updateUserCredentials }}>
      {children}
    </UserContext.Provider>
  );
};