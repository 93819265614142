// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect, useRef, useContext } from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import Text from './../../atoms/Text/Text'
import Input from './../../atoms/Input/Input'
import { PaymentButton } from '../../atoms/PaymentButton/PaymentButton'
import { Button } from '../../atoms/Button/Button'
import {UserContext} from "../../../context/UserContext";
import client from "../../api";
import isEmail from 'validator/lib/isEmail';
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";

const Container = styled('div', {
  alignItems: 'center',
  padding: '10px 24px 33px 24px',
  '@bp3': {
    boxShadow: '$shadow'
  }
})

const Head = styled(Text, {
  fontFamily: '$quote',
  fontSize: '20px',
  color: '$black',
  fontWeight: '$regular',
  textTransform: 'capitalize',
  letterSpacing: '0.7px',
  lineHeight: 1.4,
  paddingBottom: 0,
  margin: '10px 0 10px 0',
  paddingTop: '12px',
  '@bp2': {
    lineHeight: 0.7
  },
  '@bp3': {
    margin: '0 0 24px',
    padding: '20px 0 17px'
  }
})

const Paragraph = styled(Text, {
  color: '$darkgray',
  fontSize: '$size16',
  fontWeight: '$light',
  fontFamily: '$label',
  marginBottom: '5px',
  letterSpacing: '0.3px',
  lineHeight: 1.4
})

const Title = styled(Text, {
  color: '$darkgray',
  fontSize: '$size16',
  fontWeight: '$bold',
  fontFamily: '$label',
  marginBottom: '5px'
})

const OptionPayment = styled('div', {
  paddingTop: '25px'
})

const PaymentOption = {
  button: {
    marginBottom: '10px'
  },
  '@bp2': {
    padding: '5px 0 16px'
  }
}

const EmailInput = {
  maxWidth: '100%',
  width: '360px',
  margin: 0,
  input: {
    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075)',
    border: '1px solid #9FA1A8',
    lineHeight: 1.428,
    fontSize: '$size16',
    height: '42px'
  },
  span: {
    display: 'initial'
  }
}

const SignUp = styled('a', {
  color: '$black',
  fontFamily: '$body',
  fontWeight: '$normal'
})

const ButtonStyle = {
  width: 'auto',
  padding: '6px 34px 6px 35px'
}

const styleParagraph = {
  color: '$black',
  fontFamily: '$body',
  fontSize: '13.92px',
  margin: '25px 0',
  lineHeight: 1.42,
  letterSpacing: 0
}

const Success = styled('div', {
  fontFamily: '$label',
  color: '#325e1c',
  border: '1px solid #ebccd1',
  borderColor: '#c9e1bd',
  backgroundColor: '#f3faef',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16'
});
const Error = styled('div', {
  fontFamily: '$label',
  color: '#a94442',
  border: '1px solid #ebccd1',
  backgroundColor: '#f2dede',
  borderRadius: '2px',
  padding: '15px 30px',
  textAlign: 'center',
  fontSize: '$size16'
})
const errors = {
  emailError: 'You must enter a valid email.'
}

const PaymentForm: React.FC<ThemeProps> = ({...props}) => {
  const { userCredentials } = useContext(UserContext);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;

  const [loading, setLoading] = useState(true);
  const [errorMessages, setErrorMessages] = useState('');
  const [successMessages, setSuccessMessages] = useState('');

  const [selectedPayment, setSelectedPayment] = useState('');
  const [userPayment, setUserPayment] = useState('Not Selected');
  const [paypalMail, setPaypalMail] = useState("");
  const [registerUrl, setRegisterUrl] = useState("https://myaccount.payoneer.com/?Action=payments");
  const [payoneerRegistered, setPayoneerRegistered] = useState(false);
  const [affiliateID, setAffiliateID] = useState("");


  const inputPaypalEmail = useRef(null);

  useEffect(() => {
    if (password.length > 2 && username.length > 2 && csrf_token.length > 2) {
      setLoading(true);

      getUserPaymentMethod();

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const getUserPaymentMethod = async ():void => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    void client.get("/api/user/paymentMethod/get?_format=json", {
      headers: { csrf_token },
      auth: { username, password }
    })
      .then(({data}) => {
        setSelectedPayment(data.method);
        if (data.method!=="") {
          setUserPayment(data.method);
        }
        setPaypalMail(data.payMail);
        setPayoneerRegistered(data.payoneerRegistered);
        if (data.payoneerUrl!=="") {
          setRegisterUrl(data.payoneerUrl);
        }
        setAffiliateID(data.affId);
      })
      .catch(e => {
        // console.log("Error while getting payment Method", e);
        setLoading(false);
        setErrorMessages("Error while getting Payment Method. If persist, please contact support.");
      });
  }

  // clicked on one of the btn and changed the method
  const handleOnClick = (value: string): void => {
    setSelectedPayment(value);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handlePaypalUpdate = () => {
    setLoading(true);

    let newData = {};
    let errFlag = true;
    setSuccessMessages('');
    setErrorMessages('');

    if (selectedPayment==="paypal" || value==="paypal") { // paypal
      if (isEmail(inputPaypalEmail.current.defaultValue)===true) {
        errFlag = false;
        newData = {
          "method" : selectedPayment,
          "payemail": inputPaypalEmail.current.defaultValue
        }
      }
      else {
        setErrorMessages(errors.emailError);
        setLoading(false);
      }
    }

    if (!errFlag) {
      setUserPayment("paypal");
      updateMethod("paypal", newData);

      setLoading(false);
    }
  }

  const handlePayoneerUpdate = ():void => {
    setLoading(true);

    const newData = {
      "method" : 'payoneer',
    };

    setUserPayment("payoneer");
    updateMethod("payoneer", newData);

    setLoading(false);
  }

  const updateMethod = (method, newData):void => {
    setSuccessMessages('');
    setErrorMessages('');

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    void client.post("/api/user/payment/update?_format=json", newData, {
      headers: { "Content-type": "application/x-www-form-urlencoded", csrf_token },
      auth: { username, password }
    })
      .then(({ data }) => {
        // console.log("user/payment/update", data);
        setSuccessMessages("Payment Method updated successfully.");
        setTimeout(function() {
          setSuccessMessages('')
        }, 5000);

        if (method==="payoneer") {
          setPayoneerRegistered(data.isRegistered);
          if (data.url!==undefined) {
            setRegisterUrl(data.url);
          }
        }
      })
      .catch(e => {
        console.log("error", e);
        setErrorMessages('Something went wrong while updating the payment method. Please try again later2.');
        // setTimeout(function() {
        //   setErrorMessages('')
        // }, 5000);
      });
  }

  const handleCreatePayoneerAccount = ():void => {
    window.open(registerUrl, '_blank').focus();

    // update the user DB data
    const newData = {
      "method" : 'payoneer',
    };

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    void client.post("/api/user/payment/update?_format=json", newData, {
      headers: { "Content-type": "application/x-www-form-urlencoded", csrf_token },
      auth: { username, password }
    })
      .then(({ data }) => {
        // console.log("user/payment/update", data);
        setSuccessMessages("Payment Method updated successfully.");
        setTimeout(function() {
          setSuccessMessages('')
        }, 5000);

        // check status and update

      })
      .catch(e => {
        console.log("error", e);
        setErrorMessages('Something went wrong while updating the payment method. Please try again later2.');
        // setTimeout(function() {
        //   setErrorMessages('')
        // }, 5000);
      });
  }

  const showActionButton = ():Element => {
    if (selectedPayment==="payoneer") {
      if (userPayment!=="payoneer") { // not saved
        if (!payoneerRegistered) { // && not registered
          return (
            <>
              <div>If you wish to make Payoneer your primary payment provider, please click the button below to create an account with Payoneer and link to Brandcycle.</div>
              <br/>
              <Button type="action" css={ButtonStyle} onClick={handleCreatePayoneerAccount} >
                CREATE PAYONEER ACCOUNT
                <i className="fa fa-external-link"></i>
              </Button>
            </>
          );
        }
        else { // && registered
          return (
            <>
              <div>You already have a Payoneer account linked to Brandcycle. To start receiving your funds via Payoneer, just click the button below.</div>
              <br/>
              <Button type="action" css={ButtonStyle} onClick={handlePayoneerUpdate}>
                SET AS PREFERRED METHOD
              </Button>
            </>
          );
        }
      }
      else if (userPayment==="payoneer") { // saved
        if (!payoneerRegistered) { // && not registered
          return (
            <>
              <div>If you wish to make Payoneer your primary payment provider, please click the button below to create an account with Payoneer and link to Brandcycle.</div>
              <br/>
              <Button type="action" css={ButtonStyle} onClick={handleCreatePayoneerAccount} >
                CREATE PAYONEER ACCOUNT
                <i className="fa fa-external-link"></i>
              </Button>
            </>
            // <div>
            //   You have chosen Payoneer as your payment solution for receiving funds from BrandCycle.<br/>
            //   To manage your account and view your payment history, please
            //   click <a href={registerUrl} target='_blank' rel="noreferrer">here</a>.
            // </div>
          );
        }
        else { // && registered
          return (
            <div>
              You have chosen Payoneer as your payment solution for receiving funds from BrandCycle.<br/>
              To manage your account and view your payment history, please
              click <a href={registerUrl} target='_blank' rel="noreferrer">here</a>.
            </div>
          );
        }
      }
    }
  }

  return (
    <Container {...props}>
      {loading && <LoadingOverlay style={{position: "absolute", zIndex: "100"}} />}

      {errorMessages !== '' ? <Error>{errorMessages}</Error> : ''}
      {successMessages !== '' ? <Success>{successMessages}</Success> : ''}

      <Head as="h3">Payment Method: {userPayment}</Head>
      <Paragraph>
        BrandCycle now offers two payment providers, Payoneer and PayPal. If you wish to switch from
        one to the other, you must edit your selection below by the first of the month. Any changes
        made after the first of the month will not apply to the current month&apos;s payments but will be
        noted for future payment processing.
      </Paragraph>
      <Title>Update your preferred method:</Title>

      <PaymentButton css={PaymentOption} handler={handleOnClick} type={selectedPayment} />

      <OptionPayment>
        {selectedPayment === 'paypal' &&
          (
            <>
              <Paragraph css={{ marginBottom: '22px' }}>
                You have chosen Paypal as your payment solution for receiving funds from BrandCycle.
              </Paragraph>
              <Title css={{ marginBottom: '8px' }}>PayPal Email Address</Title>
              <Paragraph css={{ fontSize: '14px', marginBottom: '10px' }}>
                If you wish to make PayPal your primary payment provider, please ensure your email
                address below is the same email address you used to register your PayPal account.
              </Paragraph>

              <Input type="email" placeholder="email@domain.com" css={EmailInput}
                     inputRef={inputPaypalEmail} value={paypalMail!==undefined ? paypalMail : ""} />
              <Paragraph css={styleParagraph}>
                Don&apos;t have a PayPal account? <SignUp href="http://www.paypal.com/" target="_blank">Sign up</SignUp>
              </Paragraph>
            </>
          )
        }
      </OptionPayment>

      {
        userPayment==="" && selectedPayment==="" && null
      }
      {
        affiliateID===0 &&
        <div>No internal ID set, please contact Brandcycle Support.</div>
      }
      {
        selectedPayment==='paypal' &&
        <Button type="action" css={ButtonStyle} onClick={handlePaypalUpdate}>
          { userPayment!=="paypal" ? "SUBMIT" : "UPDATE" }
        </Button>
      }
      {
        showActionButton() // selected 'payoneer'
      }
    </Container>
  )
}
export default PaymentForm
