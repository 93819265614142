import React from 'react'
import { styled, type ThemeProps } from './../../../theme/index'
import { type MenuItem, siteUrl } from './../../../theme/menuLinks'
import { opacity, animation } from './../../molecules/Header/config'
import Link from './../../atoms/Link/Link'
import Logo from './../../atoms/Logo/Logo'
import logoImage from './../../../images/logo.png'

export interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  navCollection: MenuItem[]
  isScroll?: boolean
}

const SiteLogo = styled(Logo, {
  ...animation,
  ...opacity
})

const Container = styled('div', {
  display: 'none',
  width: '100%',
  textAlign: 'center',
  ...animation,
  transitionProperties: 'padding',
  '@bp3': {
    display: 'block'
  },
  variants: {
    isScroll: {
      true: {
        padding: '20px 0 13px'
      },
      false: {
        padding: '20px 0 13px'
      }
    }
  }
})

const Wrapper = styled('div', {
  width: '100%',
  height: '100%',
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ...animation,
  transitionProperties: 'max-width min-height',
  '@bp3': {
    maxWidth: '900px'
  },
  '@bp5': {
    maxWidth: '1080px'
  }
})

const NavList = styled('nav', {
  transform: 'translateY(-1px)',
  paddingBottom: '1px',
  flex: '1 1 auto',
  display: 'flex',
  justifyContent: 'flex-end',
  ...animation,
  transitionProperties: 'transform',
  a: {
    ...animation,
    transitionProperty: 'font'
  },
  variants: {
    isScroll: {
      true: {
        transform: 'translateY(1.5px)',
        a: {
          fontSize: '14px'
        }
      }
    }
  }
})

const LogoWrapper = styled('a', {
  ...animation,
  transitionProperties: 'width',
  variants: {
    isScroll: {
      true: {
        width: '77px'
      },
      false: {
        '@bp3': {
          width: '144px'
        }
      }
    }
  }
})

const HeaderBar: React.FC<Props> = ({ navCollection = [], isScroll = false, ...props }) => {
  return (
    <Container isScroll={isScroll} {...props}>
      <Wrapper>
        <LogoWrapper isScroll={isScroll} href={siteUrl}>
          <SiteLogo aspectRatio={0.61} src={logoImage} />
        </LogoWrapper>
        <NavList isScroll={isScroll}>
          {navCollection.map((element, index) => {
            if (element?.show !== 'all') {
              return null
            }

            return (
              <Link
                key={`${element.label} ${index}`}
                type="secondary"
                href={element.src}
                css={element?.css}
                style={{
                  marginTop: '16px'
                }}
              >
                {element.label}
              </Link>
            )
          })}
        </NavList>
      </Wrapper>
    </Container>
  )
}

export default HeaderBar
