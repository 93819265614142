// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {
  createContext,
  type ReactNode,
  type ReducerWithoutAction,
  useContext,
  useEffect,
  useReducer
} from 'react'
import { noop } from 'lodash'

interface PopupContextState {
  link: string
  code: string
  show: boolean
}

interface PopupContextType {
  state: PopupContextState
  dispatch: ReducerWithoutAction<any> | typeof noop
}

const ShowPopupContext = createContext<PopupContextType>({
  state: { link: '', code: '', show: false },
  dispatch: noop
})


const demoData = {
  link: '',
  code: '',
  show: false
}

interface PopupProviderProps {
  children: ReactNode
  forceShow?: boolean
  code: string
}

export const PopupProvider: React.FC<PopupProviderProps> = ({ children, forceShow = false, code="" }) => {
  const [state, dispatch] = useReducer(dataReducer, { ...demoData, show: forceShow })

  // // TODO: temporary solution for setting the user data.
  useEffect(() => {
    dispatch({ type: 'UPDATE', payload: {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      'link': 'https://www.brandcycle.com/apply-now/?pubref=BRAND'+code,
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      'code': 'BRAND'+code,
      show: forceShow
    } })
  }, [code, forceShow])

  return (
    <ShowPopupContext.Provider value={{ state, dispatch }}>{children}</ShowPopupContext.Provider>
  )
}

export function usePopup(): PopupContextType {
  return useContext(ShowPopupContext)
}

function dataReducer(data: PopupContextState, action): PopupContextState {
  switch (action.type) {
    case 'TOGGLE_POPUP': {
      return {
        ...data,
        show: !data.show
      }
    }
    case 'UPDATE': {
      return {
        ...data,
        ...action.payload
      }
    }
    default: {
      return data
    }
  }
}
