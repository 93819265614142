import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'

import { styled, type ThemeProps } from './../../../theme/index'

interface Props extends ThemeProps, React.ComponentPropsWithoutRef<any> {
  callback?: () => void
  isOpen?: boolean
  type?: 'home' | 'admin'
}

const Container = styled('svg', {
  opacity: 1,
  width: '40px',
  height: '32px',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8
  }
})

const Bar = styled(animated.rect, {
  fill: '#595F7B',
  display: 'block',
  variants: {
    type: {
      home: {
        fill: '#595F7B',
        height: '6px'
      },
      admin: {
        fill: '$clear',
        height: '6px'
      }
    }
  }
})

const AnimatedToggle: React.FC<Props> = ({ callback, isOpen , type = 'home', ...props }) => {
  const [isActive, setIsActive] = useState(isOpen ?? false)

  const clickHandler = (): void => {
    callback?.()
    setIsActive(!isActive)
  }

  const condition: boolean = isActive

  const state: string = condition ? 'open' : 'close'

  const first = useSpring({
    transform: condition
      ? 'translate(5px, 32px) rotate(-45deg)'
      : 'translate(2px, 7px) rotate(0deg)'
  })
  const second = useSpring({
    transform: condition
      ? 'translate(10px, 4px) rotate(45deg)'
      : 'translate(2px, 19px) rotate(0deg)'
  })
  const third = useSpring({
    transform: condition
      ? 'translate(5px, 32px) rotate(-45deg)'
      : 'translate(2px, 31px) rotate(0deg)'
  })

  return (
    <Container
      className={state}
      onClick={clickHandler}
      width="40"
      height="32"
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Bar type={type} width="40" height="4" rx="2" style={first} />
      <Bar type={type} width="40" height="4" rx="2" style={second} />
      <Bar type={type} width="40" height="4" rx="2" style={third} />
    </Container>
  )
}

export default AnimatedToggle
