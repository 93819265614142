import React from 'react'
import { styled } from './../../../theme/index'
import NumberData from './../../atoms/NumberData/NumberData'

export interface reportData {
  label: string
  value: string
}

interface Props {
  report: reportData[]
}

const Card = styled('div', {
  display: 'block',
  minHeight: '20rem',
  padding: '20px 9px',
  width: '100%',
  '@bp3': {
    boxShadow: '$shadow'
  }
})

const Container = styled('div', {
  width: '100%',
  marginBottom: '15px',
  '@bp2': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  div: {
    paddingTop: '15px',
    width: '100%',
    '&:nth-child(odd)': {
      borderRight: '1px solid $gray3'
    },
    '@bp2': {
      padding: '46px 0 47px'
    },
    '@bp3': {
      padding: '20px 0'
    },
    '@bp5': {
      padding: '33px 0'
    }
  }
})

const DeepLinkReport: React.FC<Props> = ({ report }) => {

  return (
    <Card>
      <Container>
        {report.map((data, i) => (
          <NumberData key={`report-${data.label}-${i}`} label={data.label} value={data.value} />
        ))}
      </Container>
    </Card>
  )
}

export default DeepLinkReport
