// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {useState, useContext, useEffect} from 'react'
import { styled } from './../../../theme/index'
import BlogList from './../../molecules/BlogList/BlogList'
import { type Props as BlogSnippetProps } from './../../molecules/BlogSnippet/BlogSnippet'
import BlogSlider from './../../molecules/BlogSlider/BlogSlider'
import Pagination from './../../molecules/Pagination/Pagination'
import Heading from "../../atoms/Heading/Heading";
import client from "../../api";
import {UserContext} from "../../../context/UserContext";
import LoadingOverlay from "../../atoms/LoadingOverlay/LoadingOverlay";
import ErrorMessage from "../../ErrorMessage";

interface Props {
  postList?: BlogSnippetProps[]
  featuredList?: BlogSnippetProps[]
  pagination?: {
    current: number
    total: number
    pageSize: number
  }
}

const Title = styled(Heading, {
  color: '$darkgray',
  fontFamily: '"Josefin Sans",sans-serif !important',
  fontSize: '25px',
  fontWeight: 'bold',
  letterSpacing: '1px',
  lineHeight: '25px',
  textTransform: 'uppercase',
  padding: '34px 36px 0',
  variants: {
    responsive: {
      true: {
        fontSize: '20px',
        '@bp4': {
          fontSize: '25px'
        }
      }
    }
  }
})

const Container = styled('div', {
  marginTop: '15px'
})

const PaneTitle = styled('h2', {
  fontFamily: '$heading',
  fontSize: '19px',
  fontWeight: '$bold',
  letterSpacing: '1px',
  lineHeight: 1,
  color: '$black2',
  margin: '11px 0 18px',
  padding: '25px 0 17px',
  textAlign: 'center'
})

const Top = styled('div', {
  marginBottom: '27px'
})

const Wrapper = styled('div', {
  marginTop: '16px'
})

const List = styled('div', {
  padding: '53px 0 61px',
  div: {
    justifyContent: 'center'
  }
})


const initPagination = {
  "current": 1,
  "total": 50,
  "pageSize": 12
};

const Blog: React.FC<Props> = ({ ...props }) => {
  const { userCredentials } = useContext(UserContext);
  const [errorMessages, setErrorMessages] = useState(false);
  const [postList, setPostList] = useState([]);
  const [currentDisplayChunk, setCurrentDisplayChunk] = useState([]);
  const [featuredList, setFeaturedList] = useState([]);
  const [pagination] = useState(initPagination)
  const [loading, setLoading] = useState(false);

  // Access the user credentials
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { password, username, csrf_token } = userCredentials;
  useEffect(() => {
    if (password.length>2 && username.length>2 && csrf_token.length>2) {
      setLoading(true);

      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      void client.get("/api/blog/get?_format=json", {
        headers: { csrf_token },
        auth: { username, password }
      })
        .then(({ data }) => {
          // process Blogs
          const blogsArray = data.blogs;
          const chunks = [];
          pagination.total = blogsArray.length;
          for (let i = 0; i < blogsArray.length; i += pagination.pageSize) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands,@typescript-eslint/no-unused-vars
            chunks.push(blogsArray.slice(i, i + pagination.pageSize));
          }
          setPostList(chunks[0]);
          setCurrentDisplayChunk(chunks);
          setFeaturedList(data.featuredBlogs);

          setLoading(false);
        })
        .catch(() => {
          // console.log("error", e);
          setErrorMessages(true);
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  return (
    <Container {...props}>
      <Title>Blog</Title>

      {loading && <LoadingOverlay style={{position: "absolute", zIndex: "100"}} />}
      { errorMessages && <ErrorMessage contactSupport={true} /> }

      {!loading &&
        <Top>
          <BlogSlider collection={featuredList} />
        </Top> }

      {!loading &&
        <Wrapper>
          <PaneTitle>RECENT POSTS</PaneTitle>
          <BlogList collection={postList} />
        </Wrapper>
      }

      {!loading &&
        <List>
          <Pagination
            totalCount={pagination.total}
            pageSize={pagination.pageSize}
            currentPage={pagination.current}
            setCurrentPage={(selectedPage) => {
              pagination.current = selectedPage;
              setPostList(currentDisplayChunk[selectedPage]);
            }}
          />
        </List>
      }
    </Container>
  )
}

export default Blog
