// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'
import { styled } from './../../../theme/index'

interface Data {
  label: string
  value: string
}

export interface Props {
  options: Data[]
  view?: boolean
  offSet?: boolean
}

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  '@bp3': {
    gridTemplateColumns: '23% 77%'
  },
  variants: {
    offSet: {
      true: {
        span: {
          marginLeft: '14px'
        }
      }
    },
    view: {
      true: {
        '@bp1': {
          paddingTop: '7px',
          paddingLeft: '20px',
          gridTemplateColumns: '29% 70%'
        },
        '@bp2': {
          paddingTop: '5px'
        },
        '@bp3': {
          paddingTop: 0,
          paddingLeft: 0,
          gridTemplateColumns: '29% 77%',
          margin: 0
        },
        '@bp4': {
          paddingTop: 0,
          paddingLeft: 0,
          gridTemplateColumns: '31% 77%'
        },
        '@bp5': {
          paddingTop: 0,
          paddingLeft: 0,
          gridTemplateColumns: '17% 77%'
        }
      }
    }
  }
})

const Label = styled('span', {
  color: '$black',
  fontFamily: '$body',
  fontSize: '$size16',
  fontWeight: 'bold',
  lineHeight: 1.5,
  marginBottom: '10px',
  variants: {
    view: {
      true: {
        marginBottom: '21px'
      }
    }
  }
})

const Value = styled(Label, {
  fontWeight: '400'
})

const PromotionDetail: React.FC<Props> = ({ offSet, view = false, options }) => {
  return (
    <Container view={view} offSet={offSet}>
      {options.map((data, i) => (
        <React.Fragment key={`userDetail-${data.label}-${i}`}>
          <Label view={view}>{data.label}</Label>
          <Value view={view}>{data.value}</Value>
        </React.Fragment>
      ))}
    </Container>
  )
}

export default PromotionDetail
